import React from 'react';
import PropTypes from 'prop-types';
import FormTask from '../form/FormTask';
import withRoute, {WithRouteProps} from '../HOC/withRoute';

const ModalAddTask = ({modalPayload: {selectedMilestoneId}, params: {id}}) => (<div>
    <h2>Add task</h2>
    <FormTask projectId={id} milestoneId={selectedMilestoneId}/>
</div>);

ModalAddTask.propTypes = {
    modalPayload: PropTypes.shape({
        selectedMilestoneId: PropTypes.number
    }),
    ...WithRouteProps
};

export default withRoute(ModalAddTask);
