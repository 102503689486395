import { FormFieldDataTypes, FormFieldTypes } from '../components/form/FormField';
import moment from 'moment/moment';

const getAllTaskColumns = (algorithms, resources, validators, milestones, inlineEditTask, isEditing) => [
    { name:'', defaultColumn: true },
    {
        name:'Task',
        defaultColumn: true,
        editable: {
            type: FormFieldTypes.TEXT,
            edit: (data, value) => inlineEditTask(data, value, 'milestone_task_name'),
            isEditing: data => isEditing(data, 'milestone_task_name')
        }
    },
    {
        name:'Milestone',
        defaultColumn: true,
        editable: {
            type: FormFieldTypes.SELECT,
            options: milestones.map(m => ({
                label: m.name,
                value: m.id
            })),
            valueKey: 'milestoneId',
            edit: (data, value) => inlineEditTask(data, value, 'milestone_id'),
            isEditing: data => isEditing(data, 'milestone_id'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {
        name: 'Algorithm',
        defaultColumn: true
    },
    {
        name: 'Resource',
        defaultColumn: true
    },
    {
        name: 'Validator',
        defaultColumn: true
    },
    {
        name: 'Deputy Validator',
        defaultColumn: true
    },
    {
        name: 'Sold Revenue',
        prefix: '$',
        align: 'right',
        editable: {
            type: FormFieldTypes.NUMBER,
            edit: (data, value) => inlineEditTask(data, value, 'sold_revenue'),
            isEditing: data => isEditing(data, 'sold_revenue'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {
        name: 'Sold Hours',
        defaultColumn: true,
        align: 'right',
        editable: {
            type: FormFieldTypes.NUMBER,
            edit: (data, value) => inlineEditTask(data, value, 'sold_compute_hours'),
            isEditing: data => isEditing(data, 'sold_compute_hours'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {
        name: 'Proj Hours',
        defaultColumn: true,
        align: 'right',
        editable: {
            type: FormFieldTypes.NUMBER,
            edit: (data, value) => inlineEditTask(data, value, 'proj_compute_hours'),
            isEditing: data => isEditing(data, 'proj_compute_hours'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {
        name: 'Sold Geo',
        align: 'right',
        editable: {
            type: FormFieldTypes.NUMBER,
            edit: (data, value) => inlineEditTask(data, value, 'sold_geo_months'),
            isEditing: data => isEditing(data, 'sold_geo_months'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {
        name: 'Proj Geo',
        align: 'right',
        editable: {
            type: FormFieldTypes.NUMBER,
            edit: (data, value) => inlineEditTask(data, value, 'proj_geo_months'),
            isEditing: data => isEditing(data, 'proj_geo_months'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    { name: 'PoC', suffix: '%', align: 'right', transform: Math.ceil, defaultColumn: true },
    {
        name: 'Start Date',
        defaultColumn: true,
        editable: {
            defaultEditable: true,
            edit: (data, value) =>
                inlineEditTask(
                    data,
                    moment(value).format('YYYY-MM-DD'),
                    'proj_start_date'
                ),
            isEditing: data => isEditing(data, 'proj_start_date'),
            type: FormFieldTypes.DATE
        }
    },
    {
        name: 'End Date',
        defaultColumn: true,
        editable: {
            defaultEditable: true,
            edit: (data, value) =>
                inlineEditTask(
                    data,
                    moment(value).format('YYYY-MM-DD'),
                    'proj_end_date'
                ),
            isEditing: data => isEditing(data, 'proj_end_date'),
            type: FormFieldTypes.DATE
        }
    },
    {
        name: 'Include in projections',
        defaultColumn: true,
        editable: {
            type: FormFieldTypes.CHECKBOX,
            edit: (data, value) => inlineEditTask(data, value, 'include_in_projections'),
            isEditing: data => isEditing(data, 'include_in_projections'),
            dataType: FormFieldDataTypes.BOOL
        }
    }
];

const defaultUserTaskColumns = (algorithms, resources, validators, milestones, inlineEditTask, isEditing) =>
    getAllTaskColumns(algorithms, resources, validators, milestones, inlineEditTask, isEditing)
        .filter(o => o.defaultColumn)
        .map(column => {
            column.editable && column.editable.defaultEditable || delete column.editable;
            return column;
        });

export const getTaskColumns = (isModeratorOrAdministrator, algorithms, resources, validators, milestones,
    inlineEditTask, isEditing) =>
    isModeratorOrAdministrator
        ? getAllTaskColumns(algorithms, resources, milestones, validators, inlineEditTask, isEditing)
        : defaultUserTaskColumns(algorithms, resources, milestones, validators, inlineEditTask, isEditing);

const defaultUserTaskRows = [
    '',
    'name',
    'milestone',
    'algorithm',
    'resource',
    'validator',
    'deputyValidator',
    'soldComputeHours',
    'projectedComputeHours',
    'percentCompleted',
    'projStartDate',
    'projEndDate',
    'includeInProjections'
];

const taskRows = [
    '',
    'name',
    'milestone',
    'algorithm',
    'resource',
    'validator',
    'deputyValidator',
    'soldRevenue',
    'soldComputeHours',
    'projectedComputeHours',
    'soldGeoMonths',
    'projectedGeoMonths',
    'percentCompleted',
    'projStartDate',
    'projEndDate',
    'includeInProjections'
];

export const getTaskRows = isModeratorOrAdministrator =>
    isModeratorOrAdministrator
        ? taskRows
        : defaultUserTaskRows;
