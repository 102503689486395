import React from 'react';
import PropTypes from 'prop-types';
import FormField from './FormField';
import Error from '../../views/error/Error';

function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.fields = [];
        this._onSubmit = this._onSubmit.bind(this);
        this.registerField = this.registerField.bind(this);
        this.unregisterField = this.unregisterField.bind(this);
    }

    registerField(field) {
        this.fields = [ ...this.fields, field ];
    }

    unregisterField(field) {
        const fields = [...this.fields];
        fields.splice(this.fields.indexOf(field), 1);
        this.fields = fields;
    }

    render() {
        const errorMessage = this.props.message ? <Error error={'Error: ' + this.props.message}/> : '';
        if (this.props.errors) {
            this.fields.forEach(field => field.setError(this.props.errors[field.props.name] || null));
        }

        return <form onSubmit={ this._onSubmit }>
            { errorMessage }
            { this.content() }
        </form>;
    }

    content() {
        return this.props.fields.map(f => f ?
            React.isValidElement(f) ? f : <FormField key={JSON.stringify(f)} {...f} register={this.registerField} unregister={this.unregisterField} />
            : null);
    }

    _onSubmit(e) {
        const isValid = this.validateFields(this.fields);

        let formValues = this.getValues();
        //console.log('Form values=' + JSON.stringify(formValues));

        /* MJF 20220722: The block below should not be in here. Only generic form logic belongs in this component.
         *               Needs to be refactored.
         */

        //1.0.16 - Cray compute check
        if (formValues.compute_resource_id === 1) {

            //Validate all required fields are available
            let allValuesAvailable = true;
            for (let i = 0, iLength = this.fields.length; i < iLength; i++) {
                const val = this.fields[i].getValue();
                if ((this.fields[i].props.name === 'proj_start_date' ||
                    this.fields[i].props.name === 'proj_end_date' ||
                    this.fields[i].props.name === 'proj_compute_hours') && (typeof val === 'undefined' || val === '' || val === null)) {
                    this.fields[i].setError('This field is required');
                    allValuesAvailable = false;
                }
            }

            if (allValuesAvailable === true) {
                let startDate = new Date(formValues.proj_start_date);
                let endDate = new Date(formValues.proj_end_date);

                let difference_in_time = endDate.getTime() - startDate.getTime();

                let duration = difference_in_time / (1000 * 3600 * 24) + 1;

                let usage_per_day = formValues.proj_compute_hours / duration;
                const CRAY_CAPACITY = 9740160;

                const MIN_DURATION_HOURS = formValues.proj_compute_hours * 1.0 / CRAY_CAPACITY;
                const MIN_DURATION_DAYS = Math.ceil(MIN_DURATION_HOURS / 24);

                //console.log('Form values=' + formValues.compute_resource_id + ' duration= ' + duration + " usage_per_day=" + usage_per_day + " CRAY_CAPACITY=" + CRAY_CAPACITY);

                if (usage_per_day >= CRAY_CAPACITY) {
                    for (let i = 0, iLength = this.fields.length; i < iLength; i++) {
                        if (this.fields[i].props.name === 'compute_resource_id') {
                            this.fields[i].setError('Projected usage of [' + numberWithCommas(usage_per_day) + "] node hours per day exceeds the Cray's capacity [" + numberWithCommas(CRAY_CAPACITY) + '] node hours per day.  ' +
                                'Adjust project start/end date to fit CRAY capacity. The minimum duration at full Cray capacity is [' + numberWithCommas(MIN_DURATION_DAYS) + '] days. ');
                        }
                    }

                    e.preventDefault();
                    return;
                }
            } else {
                e.preventDefault();
                return;
            }
        }

        if (!isValid) {
            e.preventDefault();
            return;
        }
        if (typeof this.props.onSubmit === 'function')
            this.props.onSubmit(e, this.getValues());

    }

    getValues() {
        return this.fields.reduce((acc, f) => {
            let val = f.getValue();
            if (f.props.name === 'description' || ( val !== '' && val !== null)) acc[f.props.name] = f.getValue();
            //if (val !== '' && val !== null) acc[f.props.name] = f.getValue();
            return acc;
        }, {});
    }

    validateFields(fields) {

        let isValid = true;

        for (let i = 0, iLength = fields.length; i < iLength; i++) {
            if (fields[i].props.validation) {
                if (!this.validateField(fields[i]) || !this.validateMaxValue(fields[i]) ||
                    !this.validateMinValue(fields[i]))

                    isValid = false;
            }
        }

        return isValid;

    }

    validateField(field) {
        const val = field.getValue();
        if (field.props.validation.required && (typeof val === 'undefined' || val === '' || val === null) ) {
            field.setError('This field is required');

            if (typeof this.onError === 'function')
                this.onError('empty');
            return false;
        }

        if (field.props.validation.validate && typeof field.props.validation.validate === 'function') {
            const error = field.props.validation.validate(field);
            if (error) {
                field.setError(error);
                return false;
            }
        }


        field.setError(null);

        return true;

    }

    validateMinValue(field) {
        if (typeof field.props.validation.minValue !== 'undefined') {
            if (field.getValue() < field.props.validation.minValue) {
                field.setError(`${field.props.name} must be greater than or equal to ${field.props.validation.minValue}`);

                if (typeof this.onError === 'function')
                    this.onError('empty');
                return false;
            } else {
                field.setError(null);
                return true;
            }
        }

        field.setError(null);
        return true;
    }

    validateMaxValue(field) {
        if (field.props.validation.maxValue) {
            let val = field.getValue();
            let currentValue = field.props.validation.currentValue;

            const params = window.location.search.split('&');
            const modelPair = params.find(v => v.indexOf('modal') > -1).split('=');
            let modelValue = modelPair.length > 1 ? modelPair[1] : 0;

            if (typeof currentValue === 'undefined' || currentValue === '' || currentValue === null) {
                //Retry with queryParams in case of 'Edit percent'
                const pair = params.find(v => v.indexOf('milestoneTaskPocTotal') > -1).split('=');
                currentValue = pair.length > 1 ? pair[1] : 0;
            }

            let maxAllowedValue = field.props.validation.maxValue - currentValue;
            if(modelValue === 'edit-percents') {
                maxAllowedValue =  field.props.validation.maxValue - currentValue + field.props.value;
            }

            if ((val > maxAllowedValue)) {
                if(currentValue === 'undefined') {
                    field.setError('Field maximum value=' + field.props.validation.maxValue + ' Current total='
                        + field.props.validation.currentValue + ', therefore available= '
                        + (field.props.validation.maxValue - currentValue));
                } else {
                    field.setError('Field maximum value=' + field.props.validation.maxValue + ' Current total='
                        + currentValue + ', therefore available= '
                        + maxAllowedValue);
                }

                if (typeof this.onError === 'function')
                    this.onError('empty');
                return false;
            } else {

                field.setError(null);
                return true;
            }
        }

        field.setError(null);

        return true;
    }

}

Form.propTypes = {
    fields: PropTypes.array,
    errors: PropTypes.object,
    message: PropTypes.string,
    onSubmit: PropTypes.func
};


export default Form;
