import React from 'react';
import FormModelBuilding from '../form/FormModelBuilding';
import {useSelector} from 'react-redux';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalModelBuilding = () => {
    const {project} = useSelector(projectSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Model Building Stage for {project.name}</h2>
            <FormModelBuilding />
        </div>
    );
};

export default ModalModelBuilding;
