import PropTypes from 'prop-types';
import React from 'react';
import AcquisitionRows from './AcquisitionRows';

const Acquisitions = props  => (
    <AcquisitionRows {...props}/>
);

Acquisitions.propTypes = {
    list: PropTypes.array
};

export default Acquisitions;
