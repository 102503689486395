import React from 'react';
import {useSelector} from 'react-redux';

import { displayCardsSelector } from '../../../redux/config/configSelectors';
import Cards from '../Cards';
import MilestoneRows from './MilestoneRows';
import PropTypes from 'prop-types';

const Milestones = props  => {
    const {displayCards} = useSelector(displayCardsSelector);
    const {list, selectedMilestoneId, onClick} = props;

    return (
        displayCards ?
            <Cards list={list} selectedId={selectedMilestoneId} onClick={onClick} type="milestone"/>
            : <MilestoneRows {...props}/>
    );
};

Milestones.propTypes = {
    selectedMilestoneId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    list: PropTypes.array,
    onClick: PropTypes.func
};

export default Milestones;
