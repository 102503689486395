import React from 'react';
import {
    isLoadedSelector,
    lastAddedTaskSelector, milestonesSelectorFromRouteId,
    projectSelectorFromRouteId,
    tasksSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import { useSelector } from 'react-redux';
import ProjectDetail from './ProjectDetail';
import Error from '../../views/error/Error';
import withFetchOnLoad from '../../components/HOC/withFetchOnLoad';
import {fetchSingle as fetchProject } from '../../redux/projects/projectsActions';
import {PROJECT_DETAILS} from '../../routes';
import {routeSelector} from '../../redux/route/routeSelectors';
import PropTypes from 'prop-types';


const ProjectDetailContainer = ({route}) => {
    const {project} = useSelector(projectSelectorFromRouteId);
    const {tasks} = useSelector(tasksSelectorFromRouteId);
    const {lastAddedTask} = useSelector(lastAddedTaskSelector);
    const {milestones} = useSelector(milestonesSelectorFromRouteId);
    const {isLoaded} = useSelector(isLoadedSelector);

    return isLoaded && !project.id ? <Error error="Project not found" /> :
        <ProjectDetail project={project} route={route} tasks={tasks} lastAddedTask={lastAddedTask}
            milestones={milestones}/>;
};

ProjectDetailContainer.propTypes = {
    route: PropTypes.object
};

export default withFetchOnLoad(
    ({
        route = useSelector(routeSelector),
        configRoute = useSelector(routeSelector).configRoute
    }) => ({
        id: route.params.id,
        fetch: () => fetchProject({id: route.params.id}),
        fetchCondition: route.params.id && configRoute === PROJECT_DETAILS,
        fetchArgs: [route.params.id]
    })
)(ProjectDetailContainer);
