import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldDataTypes, FormFieldTypes, validateDecimals, validateInteger } from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector,
    projectSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import { closeModal } from '../../redux/route/routeActions';
import { stagesSelector } from '../../redux/lookups/lookupsSelectors';
import { saveStageData } from '../../redux/projects/projectsActions';
import { setCheckedFieldState, setCheckedGroupExclusiveState, isExclusiveGroupChecked } from '../../helpers/formState';

const { SUBMIT, CHECKBOX, NUMBER, DIVIDER } = FormFieldTypes;

const valueReducer = (result, item) => {
    result[item.field_name] = item.values[0].value;
    return result;
};

const FormImaging = () => {
    const { errors } = useSelector(errorsSelector);
    const { message } = useSelector(errorMessageSelector);

    const {
        project: { id: projectId, stages: projectStages = [] }
    } = useSelector(projectSelectorFromRouteId);
    const { stages } = useSelector(stagesSelector);

    const thisStage = stages.find(
        s => s.description === 'Imaging'
    ); 
    const projectStageData = projectStages.find(
        s => s.stage_id === thisStage.id
    );
    const values = projectStageData
        ? projectStageData.fields.reduce(valueReducer, {})
        : {};

    const dispatch = useDispatch();
    const closeMe = () => dispatch(closeModal());
    const saveMe = data => dispatch(saveStageData(data, closeMe));

    const [ swim, setSwim ] = useState(values.swim);
    const [ wem, setWem ] = useState(values.wem);
    const [ qWem, setQWem ] = useState(values.q_wem);
    const [ rtm, setRtm ] = useState(values.rtm);
    const [ qRtm, setQRtm ] = useState(values.q_rtm);
    const [ ultima, setUltima ] = useState(values.ultima);
    const [ lsRtm, setLsRtm ] = useState(values.least_sq_mig);

    const [ state, setState ] = useState({
        swim_max_freq: values.swim_max_freq,
        wem_max_freq: values.wem_max_freq,
        rtm_max_freq: values.rtm_max_freq,
        time_inline: values.time_inline,
        time_x_line: values.time_x_line,
        depth_inline: values.depth_inline,
        depth_x_line: values.depth_x_line,
        ls_rtm_max_freq: values.ls_rtm_max_freq,
        ultima_max_freq: values.ultima_max_freq
    });

    const exclusiveGroups = {
        wem: [
            {name: 'wem', getter: wem, setter: setWem},
            {name: 'q_wem', getter: qWem, setter: setQWem}
        ],
        rtm: [
            {name: 'rtm', getter: rtm, setter: setRtm},
            {name: 'q_rtm', getter: qRtm, setter: setQRtm}
        ]
    };

    /**
     * MJF: Not sure if this is needed now. I suspect not.
     * @param e
     */
    const handleInputChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updatePayload = (fields, item, name, label) => {
        fields.push({
            field_name: name,
            values: [
                {
                    value: item,
                    ...(label && { label: label })
                }
            ]
        });
    };

    const {
        postm,
        fast_kpstm,
        kpstm,
        q_kpstm,
        beam,
        fast_kpsdm,
        kpsdm,
        ls_kpsdm,
        q_kpsdm
    } = values;

    const onSubmit = (e, data) => {
        e.preventDefault();
        const payload = {
            fields: Object.entries(data)
                .filter(e => e[1] === true)
                .map(([ key, value ]) => ({
                    field_name: key,
                    values: [{ value: value }]
                }))
        };

        if (data.depth_inline) updatePayload(payload.fields, data.depth_inline, 'depth_inline');
        if (data.depth_x_line) updatePayload(payload.fields, data.depth_x_line, 'depth_x_line');
        if (data.time_inline) updatePayload(payload.fields, data.time_inline, 'time_inline');
        if (data.time_x_line) updatePayload(payload.fields, data.time_x_line, 'time_x_line');

        if (data.swim_max_freq && swim)
            updatePayload(payload.fields, data.swim_max_freq, 'swim_max_freq');
        if (data.wem_max_freq && isExclusiveGroupChecked(exclusiveGroups.wem))
            updatePayload(payload.fields, data.wem_max_freq, 'wem_max_freq');
        if (data.rtm_max_freq && isExclusiveGroupChecked(exclusiveGroups.rtm))
            updatePayload(payload.fields, data.rtm_max_freq, 'rtm_max_freq');
        if (data.ls_rtm_max_freq && lsRtm)
            updatePayload(payload.fields, data.ls_rtm_max_freq, 'ls_rtm_max_freq');
        if (data.ultima_max_freq && ultima)
            updatePayload(payload.fields, data.ultima_max_freq, 'ultima_max_freq');
        
        saveMe({ projectId, stageId: thisStage.id, ...payload });
    };


    const fields = [
        {
            type: DIVIDER,
            name: 'divider',
            id: 'divider',
            value: 'Time Imaging',
            size: 'full condensed'
        },
        {
            type: CHECKBOX,
            name: 'postm',
            id: 'postm',
            label: 'Post stack time migration',
            size: 'half condensed',
            checked: postm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'fast_kpstm',
            id: 'fastKpstm',
            label: 'Fast track KPSTM',
            size: 'half condensed',
            checked: fast_kpstm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'kpstm',
            id: 'kpstm',
            label: 'KPSTM',
            size: 'half condensed',
            checked: kpstm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'q_kpstm',
            id: 'qKpstm',
            label: 'Q-KPSTM',
            size: 'half condensed',
            checked: q_kpstm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: NUMBER,
            name: 'time_x_line',
            id: 'timeXLine',
            label: 'Output time xline spacing (m)',
            value: state.time_x_line,
            dataType: FormFieldDataTypes.NUMBER,
            onChange: handleInputChange,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'time_inline',
            id: 'timeInline',
            label: 'Output time inline spacing (m)',
            value: state.time_inline,
            dataType: FormFieldDataTypes.NUMBER,
            onChange: handleInputChange,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: DIVIDER,
            name: 'divider',
            id: 'divider',
            value: 'Depth Imaging',
            className: 'size-full'
        },
        {
            type: CHECKBOX,
            name: 'beam',
            id: 'beam',
            label: 'Beam (as a product)',
            size: 'half condensed',
            checked: beam,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'fast_kpsdm',
            id: 'fastKpsdm',
            label: 'Fast track KPSDM',
            size: 'half condensed',
            checked: fast_kpsdm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'kpsdm',
            id: 'kpsdm',
            label: 'KPSDM',
            size: 'third condensed',
            checked: kpsdm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'ls_kpsdm',
            id: 'lsKpsdm',
            label: 'LS-KPSDM',
            size: 'third condensed',
            checked: ls_kpsdm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'q_kpsdm',
            id: 'qKpsdm',
            label: 'Q-KPSDM',
            size: 'third condensed',
            checked: q_kpsdm,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: NUMBER,
            name: 'depth_x_line',
            id: 'depthXLine',
            label: 'Output depth xline spacing (m)',
            value: state.depth_x_line,
            dataType: FormFieldDataTypes.NUMBER,
            onChange: handleInputChange,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'depth_inline',
            id: 'depthInline',
            label: 'Output depth inline spacing (m)',
            value: state.depth_inline,
            dataType: FormFieldDataTypes.NUMBER,
            onChange: handleInputChange,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'swim',
            id: 'swim',
            label: 'SWIM',
            size: 'half condensed',
            checked: swim,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedFieldState(setSwim)
        },
        {
            type: NUMBER,
            name: 'swim_max_freq',
            id: 'swimMaxFreq',
            label: 'SWIM maximum frequency (Hz)',
            size: 'half',
            value: state.swim_max_freq,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: handleInputChange,
            disabled: !swim,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'wem',
            id: 'wem',
            label: 'WEM',
            size: 'quarter condensed',
            checked: wem,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedGroupExclusiveState(exclusiveGroups.wem)
        },
        {
            type: CHECKBOX,
            name: 'q_wem',
            id: 'qWem',
            label: 'Q-WEM',
            size: 'quarter condensed',
            checked: qWem,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedGroupExclusiveState(exclusiveGroups.wem)
        },
        {
            type: NUMBER,
            name: 'wem_max_freq',
            id: 'wemMaxFreq',
            label: 'WEM maximum frequency (Hz)',
            size: 'half',
            value: state.wem_max_freq,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: handleInputChange,
            disabled: !isExclusiveGroupChecked(exclusiveGroups.wem),
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'rtm',
            id: 'rtm',
            label: 'RTM',
            size: 'quarter condensed',
            checked: rtm,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedGroupExclusiveState(exclusiveGroups.rtm)
        },
        {
            type: CHECKBOX,
            name: 'q_rtm',
            id: 'qRtm',
            label: 'Q-RTM',
            size: 'quarter condensed',
            checked: qRtm,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedGroupExclusiveState(exclusiveGroups.rtm)
        },
        {
            type: NUMBER,
            name: 'rtm_max_freq',
            id: 'rtmMaxFreq',
            label: 'RTM maximum frequency (Hz)',
            size: 'half',
            value: state.rtm_max_freq,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: handleInputChange,
            disabled: !isExclusiveGroupChecked(exclusiveGroups.rtm),
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'least_sq_mig',
            id: 'leastSqMig',
            label: 'LS-RTM',
            size: 'half condensed',
            checked: lsRtm,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedFieldState(setLsRtm)
        },
        {
            type: NUMBER,
            name: 'ls_rtm_max_freq',
            id: 'lsRtmMaxFreq',
            label: 'LS-RTM maximum frequency (Hz)',
            size: 'half',
            value: state.ls_rtm_max_freq,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: handleInputChange,
            disabled: !lsRtm,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'ultima',
            id: 'ultima',
            label: 'Ultima',
            size: 'half condensed',
            checked: ultima,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedFieldState(setUltima)
        },
        {
            type: NUMBER,
            name: 'ultima_max_freq',
            id: 'ultimaMaxFreq',
            label: 'Ultima maximum frequency (Hz)',
            size: 'half',
            value: state.ultima_max_freq,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: handleInputChange,
            disabled: !ultima,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: SUBMIT,
            value: 'Edit Stage'
        }
    ];
    
    return (
        <Form
            fields={fields}
            onSubmit={onSubmit}
            errors={errors}
            message={message}
        />
    );
};

export default FormImaging;
