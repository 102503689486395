import _ from 'lodash';

import {
    ADD_MILESTONE,
    ADD_PERCENTS,
    ADD_PROJECT,
    ADD_TASK,
    DELETE_MILESTONE,
    DELETE_PERCENTS,
    DELETE_PROJECT,
    DELETE_TASK,
    EDIT_MILESTONE,
    EDIT_PERCENTS,
    EDIT_PROJECT,
    EDIT_TASK,
    CLONE_TASK,
    FETCH,
    INLINE_EDIT_PROJECT,
    INLINE_EDIT_MILESTONE,
    INLINE_EDIT_TASK,
    SET_FILTER,
    CANCEL_INLINE_EDITS,
    VALIDATE_INLINE_EDITS,
    INLINE_EDIT_PERCENTS,
    FETCH_SINGLE,
    SAVE_STAGE_DATA,
    STORE_LINKED_ACQUISITION,
    STORE_UNLINKED_ACQUISITION
} from './projectsActions';
import { asyncActionSuccess, asyncActionError } from '../../helpers/asyncAction';
import {mapProject, mapProjects} from './map';
import {addProjectToState, deleteProjectInState, editProjectInState} from './reducer/projects';
import {addMilestoneToState, deleteMilestoneInState, editMilestoneInState} from './reducer/milestones';
import {addTaskToState, deleteTaskInState, editTaskInState, cloneTaskToState} from './reducer/tasks';
import {addPercentsToState, deletePercentsInState, editPercentsInState} from './reducer/percents';
import update from 'immutability-helper';
import {editProjectStageInState} from './reducer/stages';

const initialState = {
    list: [],
    filter: '',
    isLoaded: false,
    lastAddedTask: null,
    inlineEdits: {
        projects: {},
        milestones: {},
        tasks: {},
        percents: {}
    }
};

const addLinkedAcquisitionInState = (state, {projectId, acquisition}) => {
    const thisProject = state.list.find(p => p.id === projectId);
    thisProject &&
        !thisProject.acquisitions.find(a => a.id === acquisition.id) &&
        thisProject.acquisitions.push(acquisition);
    return state;
};

const removeLinkedAcquisitionInState = (state, {projectId, acquisitionId}) => {
    const thisProject = state.list.find(p => p.id === projectId);
    thisProject && _.remove(thisProject.acquisitions, a => a.id === acquisitionId);
    return state;
};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case asyncActionSuccess(SAVE_STAGE_DATA):
            return editProjectStageInState(state, action.body);

        case asyncActionSuccess(ADD_PROJECT):
            return addProjectToState(state, action.response);

        case asyncActionSuccess(EDIT_PROJECT):
            return editProjectInState(state, action.body);

        case asyncActionSuccess(DELETE_PROJECT):
            return deleteProjectInState(state, action.body);

        case asyncActionSuccess(ADD_MILESTONE):
            return addMilestoneToState(state, action.response);

        case asyncActionSuccess(EDIT_MILESTONE):
            return editMilestoneInState(state, action.body);

        case asyncActionSuccess(DELETE_MILESTONE):
            return deleteMilestoneInState(state, action.body);

        case asyncActionSuccess(ADD_TASK):
            return addTaskToState(state, action.response);

        case asyncActionSuccess(EDIT_TASK):
            return editTaskInState(state, action.body);

        case asyncActionSuccess(CLONE_TASK):
            return cloneTaskToState(state, action.response);

        case asyncActionSuccess(DELETE_TASK):
            return deleteTaskInState(state, action.body);

        case asyncActionSuccess(ADD_PERCENTS):
            return addPercentsToState(state, action.response);

        case asyncActionSuccess(EDIT_PERCENTS):
            return editPercentsInState(state, action.body);

        case asyncActionSuccess(DELETE_PERCENTS):
            return deletePercentsInState(state, action.body);
            
        case FETCH:
            return {
                ...state,
                isLoaded: false,
                projects: []
            };

        case FETCH_SINGLE:
            return {
                ...state,
                isLoaded: false,
                project: {}
            };

        case STORE_LINKED_ACQUISITION:
            return addLinkedAcquisitionInState(state, action);

        case STORE_UNLINKED_ACQUISITION:
            return removeLinkedAcquisitionInState(state, action);

        case asyncActionSuccess(FETCH):
            return {
                ...state,
                isLoaded: true,
                list: mapProjects(action.response)
            };

        case asyncActionSuccess(FETCH_SINGLE): {
            const project = mapProject(action.response);
            return {
                ...state,
                isLoaded: true,
                list: [project],
                project: project
            };
        }

        case asyncActionError(FETCH_SINGLE):
            return {
                ...state,
                project: false,
                isLoaded: true
            };

        case asyncActionError(SAVE_STAGE_DATA):
        case asyncActionError(ADD_PROJECT):
        case asyncActionError(EDIT_PROJECT):
        case asyncActionError(DELETE_PROJECT):
        case asyncActionError(ADD_MILESTONE):
        case asyncActionError(EDIT_MILESTONE):
        case asyncActionError(DELETE_MILESTONE):
        case asyncActionError(ADD_TASK):
        case asyncActionError(EDIT_TASK):
        case asyncActionError(DELETE_TASK):
        case asyncActionError(CLONE_TASK): {
            const response = typeof action.response === 'object' && action.response.errors ?
                action.response :
                {
                    errors: null,
                    message: action.response?.message ? action.response.message : 'An unhandled server error occurred.'
                };
            return {
                ...state,
                ...response
            };
        }

        case SET_FILTER:
            return {
                ...state,
                filter: action.filter
            };

        case INLINE_EDIT_PROJECT:
            return update(state, {
                inlineEdits: {
                    projects: {
                        [action.data.id]: {
                            $set: {
                                ...state.inlineEdits.projects[action.data.id],
                                [action.key]: action.value
                            }
                        }
                    }
                }
            });

        case INLINE_EDIT_MILESTONE:
            return update(state, {
                inlineEdits: {
                    milestones: {
                        [action.data.id]: {
                            $set: {
                                ...state.inlineEdits.milestones[action.data.id],
                                [action.key]: action.value
                            }
                        }
                    }
                }
            });

        case INLINE_EDIT_TASK:
            return update(state, {
                inlineEdits: {
                    tasks: {
                        [action.data.id]: {
                            $set: {
                                ...state.inlineEdits.tasks[action.data.id],
                                [action.key]: action.value
                            }
                        }
                    }
                }
            });

        case INLINE_EDIT_PERCENTS:
            return update(state, {
                inlineEdits: {
                    percents: {
                        [action.data.id]: {
                            $set: {
                                ...state.inlineEdits.percents[action.data.id],
                                [action.key]: action.value
                            }
                        }
                    }
                }
            });

        case asyncActionSuccess(VALIDATE_INLINE_EDITS):
        case CANCEL_INLINE_EDITS:
            return update(state, {
                inlineEdits: {
                    $set: initialState.inlineEdits
                }
            });

        default:
            return {
                ...state,
                errors: null,
                message: null
            };
    }
};
