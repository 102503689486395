import '../../../scss/components/header/_header.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';

import LogoutIcon from '../../../img/logout.svg';

import StatelessDisplayPicker from './DisplayPicker';
import HeaderNavigation from './HeaderNavigation';

import { displaySelector } from '../../redux/config/configSelectors';
import { setDisplay } from '../../redux/config/configActions';
import { DISPLAY_ROWS, DISPLAY_CARDS } from '../../redux/config/constants';
import { logout } from '../../redux/user/userActions';
import { headerLinksSelector } from '../../redux/lookups/lookupsSelectors';
import {isModeratorOrAdministratorSelector} from '../../redux/user/userSelectors';
import {acquisitionSummaryLink} from '../../routes';
import Link from '../router/Link';


const DisplayPicker = connect(
    displaySelector,
    dispatch => ({
        onClickRows: () => dispatch(setDisplay(DISPLAY_ROWS)),
        onClickGrid: () => dispatch(setDisplay(DISPLAY_CARDS))
    })
)(StatelessDisplayPicker);

const Header = ({ links, logout }) => {
    const {isModeratorOrAdministrator} = useSelector(isModeratorOrAdministratorSelector);

    return (
        <header id="header">
            <HeaderNavigation links={links}/>
            {
                isModeratorOrAdministrator ?
                    <Link
                        className="manage-acquisitions-button"
                        activeClassName="manage-acquisitions-button is-active"
                        activeIfChildRoute={ true }
                        href={acquisitionSummaryLink()}>
                        Surveys
                    </Link> : null
            }
            <DisplayPicker/>
            <button className="help-button" title="Documentation Link" onClick={() => {
                window.open('https://pgsgeo.atlassian.net/wiki/spaces/ISWD/pages/222560494/Iris', '_blank');
            }}>
                ?
            </button>
            <button className="logout-button" title="Logout" onClick={logout}>
                <LogoutIcon/>
            </button>
            <button className="kilde-form-button" title="HPC Service Desk" onClick={() => {
                window.open('https://pgsgeo.atlassian.net/servicedesk/customer/portal/8/group/35', '_blank');
            }}>
            </button>
        </header>
    );
};

Header.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string
    })),
    logout: PropTypes.func
};

export default connect(
    headerLinksSelector,
    dispatch => ({
        logout: () => dispatch(logout(dispatch))
    })
)(Header);
