export const ROUTE = 'ROUTE';
export const SET = `${ROUTE}/SET`;
export const SET_ROUTES = `${ROUTE}/SET_ROUTES`;
export const CLOSE_MODAL = `${ROUTE}/CLOSE_MODAL`;
export const OPEN_MODAL = `${ROUTE}/OPEN_MODAL`;
export const SET_FILTERS = `${ROUTE}/SET_FILTERS`;

export const set = (route, silent = false) => ({
    type: SET,
    route,
    silent
});

export const setRoutes = (routes, baseUrl, current) => ({
    type: SET_ROUTES,
    routes,
    baseUrl,
    current
});


export const closeModal = () => {
    document.body.style.overflow = 'visible';
    return({
        type: CLOSE_MODAL
    });
};

export const openModal = (modal, modalPayload, extraParams) => {
    document.body.style.overflow = 'hidden';
    return({
        type: OPEN_MODAL,
        modal,
        modalPayload,
        extraParams
    });
};



export const setFilters = filters => ({
    type: SET_FILTERS,
    filters
});
