import '../../../scss/components/form/_form-field.scss';
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const getDateFormatFromLocale = () => {
    const xmas2000=new Date('2000/12/25');
    let str=xmas2000.toLocaleDateString();
    str=str.replace('25','DD');
    str=str.replace('12','MM');
    str=str.replace('2000','YYYY');
    return str;
};

const dateFormat = getDateFormatFromLocale();

export const FormFieldTypes = {
    CHECKBOX: 'checkbox',
    DATE: 'date',
    EMAIL: 'email',
    HIDDEN: 'hidden',
    NUMBER: 'number',
    PASSWORD: 'password',
    RADIO: 'checkbox',
    SELECT: 'select',
    SUBMIT: 'submit',
    TEL: 'tel',
    TEXT: 'text',
    TEXTAREA: 'textarea',
    DIVIDER: 'div'
};


export const FormFieldDataTypes = {
    BOOL: 'bool',
    NUMBER: 'number',
    INTEGER: 'integer'
};


export const ensureDateIsLocale = cell => cell instanceof Date
    ? cell.toLocaleDateString()
    : !isNaN(cell)
        ? cell.toLocaleString('en-US')
        : cell;

const isRadioOrCheckbox = type =>
    type === FormFieldTypes.RADIO ||
    type === FormFieldTypes.CHECKBOX;

export const validateInteger = field =>
    field.getValue() !== null && !Number.isInteger(field.getValue()) && `${field.props.name} must be an integer`;

export const validateNumber = field =>
    field.getValue() !== null && Number.isNaN(field.getValue()) && `${field.props.name} must be a number`;

export const validateDecimals = (minPlaces, maxPlaces) => field => {
    const result = validateNumber(field);
    if (result) return result;

    const value = field.getValue();
    if (value === null)
        return;

    const parts = `${value}`.split('.', 2);
    if (minPlaces && parts.length < 2 || parts.length === 2 && parts[1].length < minPlaces)
        return `${field.props.name} must have at least ${minPlaces} decimal places`;

    if (maxPlaces && parts.length === 2 && parts[1].length > maxPlaces)
        return `${field.props.name} must have at most ${maxPlaces} decimal places`;
};

const FormFieldLabel = ({
    id,
    label,
    type,
    labelAsDefault,
    value
}) =>
    label && (type !== FormFieldTypes.SELECT || !labelAsDefault || value)
        ? <label htmlFor={id}>
            {label}
        </label>
        : null;

class FormField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isFocused: false,
            value: props.defaultValue || props.value
        };

        if (this.props.type === FormFieldTypes.CHECKBOX)
            this.state.checked = this.props.checked;

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.getValue = this.getValue.bind(this);
        this.el = React.createRef();
    }

    componentDidMount() {
        if (typeof this.props.register === 'function' && this.props.type !== FormFieldTypes.SUBMIT)
            this.props.register(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.value !== prevProps.value)
            this.setState({
                value: this.props.value
            });
    }

    componentWillUnmount() {
        if (typeof this.props.unregister === 'function' && this.props.type !== FormFieldTypes.SUBMIT)
            this.props.unregister(this);
    }

    setError(message) {
        this.setState({
            error: message
        });
    }

    getClassName() {
        let className = `form-field is-${this.props.type} `;
        if (this.state.isFocused) className += 'is-focused ';
        if (this.state.value || this.state.value !== '') className += 'has-value ';
        if (this.props.size) {
            const tokens = this.props.size.split(/\s+/);
            tokens.forEach(t => {
                switch (t) {
                    case 'quarter':
                        className += 'size-quarter ';
                        break;
                    case '3quarters':
                        className += 'size-3quarters ';
                        break;
                    case 'third':
                        className += 'size-third ';
                        break;
                    case '2thirds':
                        className += 'size-2thirds ';
                        break;
                    case 'half':
                        className += 'size-half ';
                        break;
                    case 'full':
                        className += 'size-full ';
                        break;
                    case 'short':
                        className += 'height-short ';
                        break;
                    case 'condensed':
                        className += 'padding-condensed ';
                        break;
                    case 'border':
                        className += 'border-bottom';
                        break;
                }
            });
        }

        if (this.props.break)
            className+= 'break ';

        if(this.state.error)
            className += 'has-error ';

        if (this.props.className)
            className += `${this.props.className} `;

        return className;
    }

    render() {
        const _isRadioOrCheckbox = isRadioOrCheckbox(this.props.type);

        if ( this.props.type === FormFieldTypes.HIDDEN)
            return this.renderFormField(this.props);

        return <div className={this.getClassName()}>
            {!_isRadioOrCheckbox && <FormFieldLabel {...this.props} value={this.state.value} />}
            {this.renderFormField(this.props)}
            {_isRadioOrCheckbox && <FormFieldLabel {...this.props} value={this.state.value} />}
            {this.state.error && <div className="error">{this.state.error}</div>}
        </div>;
    }

    renderFormField({
        autoFocus,
        label,
        labelAsDefault,
        defaultValue,
        disabled,
        id,
        name,
        placeholder,
        readOnly,
        className,
        type,
        onInput,
        onKeyUp,
        options
    }) {
        const props = {
            autoFocus,
            defaultValue,
            disabled,
            id,
            name,
            onInput,
            onKeyUp,
            placeholder,
            readOnly,
            className,
            value: this.state.value,
            onChange: this.onChange,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            ref: this.el
        };

        switch (type) {
            case FormFieldTypes.CHECKBOX:
            case FormFieldTypes.RADIO:
                return <input {...props} type={type} checked={this.state.checked}/>;

            case FormFieldTypes.SELECT:
                return <select {...props}>
                    {labelAsDefault ?
                        <option value="">{label}</option>
                        : null}
                    {
                        options.map(({label, value}, i) =>
                            <option key={i} value={value}>
                                {label}
                            </option>
                        )
                    }
                </select>;
            case FormFieldTypes.TEXTAREA:
                return <textarea {...props} value={this.state.value || ''} />;
            case FormFieldTypes.DATE:
                return <DatePicker dateFormat={dateFormat} ref={this.el}
                    selected={this.state.value ? moment(this.state.value) : undefined}
                    onChange={this.onDateChange} {...(this.props.dateOptions || {})} />;
            case FormFieldTypes.DIVIDER:
                return <div {...props}><h4>{this.state.value || 'TEST'}</h4> </div>;
            case FormFieldTypes.NUMBER:
            case FormFieldTypes.TEL:
            case FormFieldTypes.TEXT:
            case FormFieldTypes.SUBMIT:
            default:
                return <input {...props} type={type}/>;
        }
    }

    onChange(e) {
        let error = null;
        const value = e.target.value;
    
        this.setState({
            value,
            error,
            checked: this.props.type === FormFieldTypes.CHECKBOX ? !this.state.checked : this.state.checked
        });
    
        if (typeof this.props.onChange === 'function') this.props.onChange(e, this.getValue());
    }

    onDateChange(momentDate) {
        const value = momentDate.toDate();
        this.setState({
            error: false,
            value
        });
        if (typeof this.props.onChange === 'function') this.props.onChange(momentDate, value.toString());
    }

    onFocus(e) {
        this.setState({
            isFocused: true
        });
        if (typeof this.props.onFocus === 'function') this.props.onFocus(e, this.getValue());
    }

    onBlur(e) {
        this.setState({
            isFocused: false
        });
        if (typeof this.props.onBlur === 'function') this.props.onBlur(e, this.getValue());
    }

    getValue() {
        let value;
        switch(this.props.type) {
            case FormFieldTypes.DATE:
                value = this.el.current.input.value ? moment(this.el.current.input.value, dateFormat)
                    .format('YYYY-MM-DD') : null;
                break;
            case FormFieldTypes.CHECKBOX:
                value = !!this.el.current.checked;
                break;
            default:
                value = this.el.current.value;
                break;

        }

        switch(this.props.dataType) {
            case FormFieldDataTypes.NUMBER:
                if (value === '') return null;
                value = parseFloat(value);
                break;
            case FormFieldDataTypes.INTEGER: {
                if (value === '') return null;
                const numberValue = Number(value);
                value = parseInt(value, 10);
                if (value !== numberValue) value = NaN;
                break;
            }
            case FormFieldDataTypes.BOOL:
                value = !!value;
                break;
        }

        return value;
    }

}

const validationShape = {
    required: PropTypes.bool
};

export const FormFieldShape = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    register: PropTypes.func,
    type: PropTypes.oneOf(
        Object.keys(FormFieldTypes).map(key => FormFieldTypes[key])
    ).isRequired,
    dataType: PropTypes.oneOf(
        Object.keys(FormFieldDataTypes).map(key => FormFieldDataTypes[key])
    ),
    unregister: PropTypes.func,
    validation: PropTypes.shape(validationShape),
    value: PropTypes.any,
    labelAsDefault: PropTypes.bool,
    dateOptions: PropTypes.any,
    defaultValue: PropTypes.any,
    size: PropTypes.any,
    break: PropTypes.any
};

FormFieldLabel.propTypes = FormFieldShape;
FormField.propTypes = FormFieldShape;


export default FormField;
