import React from 'react';
import FormProject from '../form/FormProject';
import { useSelector } from 'react-redux';
import { projectSelectorFromId } from '../../redux/projects/projectsSelectors';
import {routeSelector} from '../../redux/route/routeSelectors';

const ModalEditProject = () => {
    const route = useSelector(routeSelector);
    const {project} = useSelector(projectSelectorFromId(route.queryParams.project));
    return (
        <div>
            <h2>Edit project</h2>
            <FormProject values={project}/>
        </div>
    );
};

export default ModalEditProject;
