{
  "en": {
    "project": {
      "add": "Add project",
      "summaryTitle": "Project summary"
    },
    "acquisition": {
      "add": "Add survey",
      "summaryTitle": "Survey summary"
    },
    "filter": "Filter",
    "welcomeMessage": "Welcome to the IRIS project management interface. Please sign in to access projects.",
    "loginError": "Authentication has failed",
    "backendUnavailable": "The IRIS backend is not available. Please contact the system administrator.",
    "accessDenied": "Access Denied"
  }
}
