import React from 'react';
import FormAcquisition from '../form/FormAcquisition';

const ModalAddAcquisition = () => (
    <div>
        <h2>Create Survey</h2>
        <FormAcquisition/>
    </div>
);

export default ModalAddAcquisition;
