import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldTypes, FormFieldDataTypes, validateInteger } from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector,
    projectSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import { closeModal } from '../../redux/route/routeActions';
import { stagesSelector } from '../../redux/lookups/lookupsSelectors';
import { saveStageData } from '../../redux/projects/projectsActions';
import { setCheckedFieldState, setValueFieldState, setSelectFieldState } from '../../helpers/formState';

const { SUBMIT, CHECKBOX, SELECT, NUMBER } = FormFieldTypes;

const valueReducer = (result, item) => {
    result[item.field_name] = item.values[0].value;
    return result;
};

const FormModelBuilding = () => {
    const { errors } = useSelector(errorsSelector);
    const { message } = useSelector(errorMessageSelector);

    const {
        project: { id: projectId, stages: projectStages = [] }
    } = useSelector(projectSelectorFromRouteId);
    const { stages } = useSelector(stagesSelector);

    const thisStage = stages.find(
        s => s.description === 'Model Building'
    ); 
    const projectStageData = projectStages.find(
        s => s.stage_id === thisStage.id
    );
    const values = projectStageData
        ? projectStageData.fields.reduce(valueReducer, {})
        : {};

    const dispatch = useDispatch();
    const closeMe = () => dispatch(closeModal());
    const saveMe = data => dispatch(saveStageData(data, closeMe));

    const initialQ = projectStageData?.fields.find(
        field => field.field_name === 'q'
    );

    const initialVelocityType = projectStageData?.fields.find(
        field => field.field_name === 'velocity_type'
    );

    const getQLabel = type =>
        initialQ ? initialQ.values[0][type] : 'Unassign';
    
    const getVelocityLabel = type => initialVelocityType ? initialVelocityType.values[0][type] : 'Unassign';

    const [ selectedQ, setSelectedQ ] = useState({
        value: getQLabel('value'),
        label: getQLabel('label')
    });

    const [ fwi, setFwi ] = useState(values.fwi);
    const [ ultima, setUltima ] = useState(values.ultima);
    const [ fwiMaxFreq, setFwiMaxFreq ] = useState(values.fwi_max_freq);
    const [ ultimaMaxFreq, setUltimaMaxFreq ] = useState(values.ultima_max_freq);

    const [ selectedVelocity, setSelectedVelocity ] = useState({
        value: getVelocityLabel('value'),
        label: getVelocityLabel('label') 
    });

    const {
        refraction_tom,
        reflection_tom,
        fault_constr_tom,
        hyper_model,
        model_uncertainty,
        complt_wavefld_img,
        orthorhombic,
        salt,
        volcanics,
        gas,
        carbonates
    } = values;

    const onSubmit = (e, data) => {
        /**
         * A note here: We would like the onSubmit function not to depend on component state or closures. There is a
         * data input parameter that is populated by the Form component. Let's use that. This has the added benefit of
         * type coercion.
         */
        e.preventDefault();
        const {q, velocity_type, fwi, fwi_max_freq, ultima, ultima_max_freq} = data;
        const payload = {
            fields: Object.entries(data)
                .filter(e => e[1] === true)
                .map(([ key, value ]) => ({
                    field_name: key,
                    values: [{ value: value }]
                }))
        };

        if (q !== 'Unassign') {
            payload.fields.push({
                field_name: 'q',
                values: [
                    {
                        value: true,
                        label: q
                    }
                ]
            });
        }

        if (velocity_type !== 'Unassign') {
            payload.fields.push({
                field_name: 'velocity_type',
                values: [
                    {
                        value: true,
                        label: velocity_type
                    }
                ]
            });
        }

        if (fwi_max_freq && fwi) {
            payload.fields.push({
                field_name: 'fwi_max_freq',
                values: [
                    {
                        value: fwi_max_freq
                    }
                ]
            });
        }
        
        if (ultima && ultima_max_freq) {
            payload.fields.push({
                field_name: 'ultima_max_freq',
                values: [
                    {
                        value: ultima_max_freq
                    }
                ]
            });
        }
        
        saveMe({ projectId, stageId: thisStage.id, ...payload });
    };


    const fields = [
        {
            type: CHECKBOX,
            name: 'refraction_tom',
            id: 'refracTom',
            label: 'Refraction Tomography',
            size: 'full condensed',
            checked: refraction_tom,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'reflection_tom',
            id: 'reflecTom',
            label: 'Reflection Tomography',
            size: 'full condensed',
            checked: reflection_tom,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'fault_constr_tom',
            id: 'faultTom',
            label: 'Fault Constrained Tomography',
            size: 'full condensed',
            checked: fault_constr_tom,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'hyper_model',
            id: 'hypMod',
            label: 'hyperModel',
            size: 'full condensed',
            checked: hyper_model,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'model_uncertainty',
            id: 'modUnc',
            label: 'Model Uncertainty',
            size: 'full condensed',
            checked: model_uncertainty,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'fwi',
            id: 'fullWave',
            label: 'Full Wave Form Inversion',
            size: 'half condensed',
            checked: fwi,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedFieldState(setFwi)
        },
        {
            type: NUMBER,
            name: 'fwi_max_freq',
            id: 'fwiMaximumFrequency',
            label: 'FWI maximum frequency (Hz)',
            value: fwiMaxFreq,
            dataType: FormFieldDataTypes.INTEGER,
            disabled: !fwi,
            onChange: setValueFieldState(setFwiMaxFreq),
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'complt_wavefld_img',
            id: 'compWave',
            label: 'Complete Wave Field Imaging',
            size: 'full condensed',
            checked: complt_wavefld_img,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'ultima',
            id: 'ultima',
            label: 'Ultima',
            size: 'half condensed',
            checked: ultima,
            dataType: FormFieldDataTypes.BOOL,
            onChange: setCheckedFieldState(setUltima)
        },
        {
            type: NUMBER,
            name: 'ultima_max_freq',
            id: 'ultimaMaximumFrequency',
            label: 'Ultima maximum frequency (Hz)',
            value: ultimaMaxFreq,
            dataType: FormFieldDataTypes.INTEGER,
            disabled: !ultima,
            onChange: setValueFieldState(setUltimaMaxFreq),
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: CHECKBOX,
            name: 'orthorhombic',
            id: 'orthorhombic',
            label: 'Orthorhombic',
            size: 'full condensed',
            checked: orthorhombic,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: SELECT,
            name: 'q',
            id: 'q',
            label: 'Q',
            value: selectedQ.label,
            options: [
                { label: 'Unassign', value: null },
                ...[ 'Constant', 'Tomography' ].map(q => ({
                    label: q,
                    value: q
                }))
            ],
            onChange: setSelectFieldState(setSelectedQ)
        },
        {
            type: SELECT,
            name: 'velocity_type',
            id: 'velocityType',
            label: 'Velocity Type',
            value: selectedVelocity.label,
            options: [
                { label: 'Unassign', value: null },
                ...[ 'ISO', 'VTI', 'TTI' ].map(velType => ({
                    label: velType,
                    value: velType
                }))
            ],
            onChange: setSelectFieldState(setSelectedVelocity)
        },
        {
            type: CHECKBOX,
            name: 'salt',
            id: 'salt',
            label: 'Salt',
            size: 'half condensed',
            break: true,
            checked: salt,
            dataType: FormFieldDataTypes.BOOL

        },
        {
            type: CHECKBOX,
            name: 'volcanics',
            id: 'volcanics',
            label: 'Volcanics',
            size: 'half condensed',
            checked: volcanics,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'gas',
            id: 'gas',
            label: 'Gas',
            size: 'half condensed',
            checked: gas,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'carbonates',
            id: 'carbonates',
            label: 'Carbonates',
            size: 'half condensed',
            checked: carbonates,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: SUBMIT,
            value: 'Edit stage'
        }
    ];

    return (
        <Form
            fields={fields}
            onSubmit={onSubmit}
            errors={errors}
            message={message}
        />
    );
};

export default FormModelBuilding;
