import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFieldTypes } from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector,
    projectSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import { closeModal } from '../../redux/route/routeActions';
import { stagesSelector } from '../../redux/lookups/lookupsSelectors';
import { saveStageData } from '../../redux/projects/projectsActions';

const { SUBMIT, SELECT } = FormFieldTypes;

const FormRegularization = () => {
    const dispatch = useDispatch();
    const closeMe = () => dispatch(closeModal());
    const saveMe = data => dispatch(saveStageData(data, closeMe));
    const { errors } = useSelector(errorsSelector);
    const { message } = useSelector(errorMessageSelector);
    const {
        project: { id: projectId, stages: projectStages = [] }
    } = useSelector(projectSelectorFromRouteId);
    const { stages } = useSelector(stagesSelector);

    const thisStage = stages.find(
        stage => stage.description === 'Regularization'
    );
    const projectStageData = projectStages.find(
        stage => stage.stage_id === thisStage.id
    );
    const initialDimension = projectStageData?.fields.find(
        field => field.field_name === 'dimension'
    );

    const getLabel = type =>
        initialDimension ? initialDimension.values[0][type] : 'Unassign';

    const [ selectedDimension, setSelectedDimension ] = useState({
        value: getLabel('value'),
        label: getLabel('label')
    });

    const handleDimensionChange = e => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        setSelectedDimension({
            value: selectedOption.value,
            label: selectedOption.text
        });
    };

    const onSubmit = (e, data) => {
        e.preventDefault();
        const fieldsPayload = Object.entries(data)
            .filter(entry => entry[1] === true)
            .map(([key]) => ({
                field_name: key,
                values: [{ value: true, label: key }]
            }));

        if (selectedDimension.value !== 'Unassign') {
            fieldsPayload.push({
                field_name: 'dimension',
                values: [
                    {
                        value: true,
                        label: selectedDimension.label
                    }
                ]
            });
        }

        const payload = { fields: fieldsPayload };
        saveMe({ projectId, stageId: thisStage.id, ...payload });
    };

    const fields = [
        {
            type: SELECT,
            name: 'dimension',
            id: 'dimension',
            label: 'Regularization',
            value: selectedDimension.label,
            size: 'full',
            options: [
                { label: 'Unassign', value: null },
                ...[ '3D', '4D', '5D' ].map(dimension => ({
                    label: dimension,
                    value: dimension
                }))
            ],
            onChange: handleDimensionChange
        },
        {
            type: SUBMIT,
            value: 'Edit stage'
        }
    ];

    return (
        <Form
            fields={fields}
            onSubmit={onSubmit}
            errors={errors}
            message={message}
        />
    );
};

export default FormRegularization;
