import '../../../scss/components/_side-menu.scss';
import React from 'react';
import PropTypes from 'prop-types';
import RightArrowIcon from '../../../img/right-arrow.svg';

class SideMenu extends React.Component {

    constructor(p) {
        super(p);
        this.state = {
            open: false
        };
    }

    render() {
        return <div className={`side-menu ${ this.state.open ? 'is-open' : '' }`}>
            <button onClick={() => this.toggle()}> <RightArrowIcon /></button>
            <div className="side-menu-content" hidden={!this.state.open}>
                { this.props.children }
            </div>
        </div>;
    }

    toggle() {
        this.setState({ open : !this.state.open });
    }
}

SideMenu.propTypes = {
    children: PropTypes.node
};

export default SideMenu;
