import React from 'react';
import Card from './Card';
import PropTypes from 'prop-types';


const ProjectCard = ({id, name, description, soldRevenue, projectPercentCompleted, supervisor, surveySize, onClick}) => {
    const myOnClick = () => onClick(id);

    return (
        <Card title={name} description={description} soldRevenue={soldRevenue}
            percentCompleted={projectPercentCompleted} supervisor={supervisor} surveySize={surveySize}
            onClick={myOnClick} />
    );
};

ProjectCard.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    soldRevenue: PropTypes.number,
    surveySize: PropTypes.number,
    supervisor: PropTypes.string,
    projectPercentCompleted: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    onClick: PropTypes.func
};

export default ProjectCard;
