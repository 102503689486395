import React from 'react';
import {FormFieldDataTypes, FormFieldTypes} from './FormField';
import Form from './Form';
import withRoute from '../HOC/withRoute';
import {useDispatch, useSelector} from 'react-redux';
import { addPercentsAndCloseModal, editPercentsAndCloseModal } from '../../redux/common';
import {errorMessageSelector, errorsSelector} from '../../redux/projects/projectsSelectors';
import PropTypes from 'prop-types';

const { DATE, HIDDEN, NUMBER, SUBMIT, TEXTAREA } = FormFieldTypes;

const FormPercents = ({values = {}, params: {id}, milestoneTaskId, milestoneTaskPocTotal}) => {
    const dispatch = useDispatch();

    const addPercents = data => dispatch(addPercentsAndCloseModal(data));
    const editPercents = data => dispatch(editPercentsAndCloseModal(data));
    const onSubmit = (e, data) => {
        e.preventDefault();
        values.percent ? editPercents(data) : addPercents(data);
    };

    const {errors} = useSelector(errorsSelector);
    const {errorMessage} = useSelector(errorMessageSelector);

    const fields = [
        {
            type: HIDDEN,
            name: 'id',
            id: 'percent-id',
            value: values.id,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: HIDDEN,
            name: 'project_id',
            id: 'percent-project-id',
            value: id,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: HIDDEN,
            name: 'milestone_task_id',
            id: 'percent-milestone-task-id',
            value: milestoneTaskId,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'percent',
            id: 'percent-percent',
            label: 'Percent',
            validation: {
                required: true,
                maxValue: 100,
                currentValue: milestoneTaskPocTotal
            },
            value: values.percent,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: TEXTAREA,
            name: 'description',
            id: 'percent-milestone-description',
            label: 'Description',
            size: 'full',
            validation: {
                required: true
            },
            value: values.description,
            break: true
        },
        {
            type: DATE,
            name: 'completion_date',
            id: 'percent-completion-date',
            label: 'Completion date',
            validation: {
                required: true
            },
            dateOptions: {
                popperPlacement: 'top'
            },
            value: values.completionDate,
            break: true
        },
        {
            type: SUBMIT,
            value: typeof values.percent !== 'undefined' ? 'Save PoC' : 'Create PoC'
        }
    ];

    return (
        <Form fields={fields} errors={errors} message={errorMessage} onSubmit={onSubmit}/>
    );
};

FormPercents.propTypes = {
    values: PropTypes.object,
    params: PropTypes.shape({
        id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
    }),
    milestoneTaskId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    milestoneTaskPocTotal: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

export default withRoute(FormPercents);
