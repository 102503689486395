import React from 'react';
import PropTypes from 'prop-types';
import Table from '../Table';
import AcquisitionMenu from '../contextMenu/AcquisitionMenu';
import {useDispatch, useSelector} from 'react-redux';
import {getAcquisitionColumns, getAcquisitionRows} from '../../helpers/acquisitionRows';
import {openModal} from '../../redux/route/routeActions';
import {MODALS} from '../modal/Modal';
import {isModeratorOrAdministratorSelector} from '../../redux/user/userSelectors';

const contextMenu = ({id}, computedRows, {columns, rows}) =>
    <AcquisitionMenu acquisition_id={ id } { ...{
        computedRows,
        columns: columns.slice(1, columns.length),
        rows: rows.slice(1, rows.length)
    } } />;

contextMenu.propTypes = {
    id: PropTypes.number
};

const AcquisitionRows = props => {
    const dispatch = useDispatch();

    const mappedProps = (({
        addRow,
        list,
        noCog,
        isModeratorOrAdministrator,
        selectedId
    }) => ({
        columns: getAcquisitionColumns(),
        contextMenu: !noCog && isModeratorOrAdministrator
            ? (data, computedRows, props) =>
                contextMenu(data, computedRows, props)
            : null,
        data: list.map(({
            id,
            start_date,
            end_date,
            pgs_acquisition,
            pgs_acquisition_code,
            ...rest
        }) => ({
            ...rest,
            start_date: start_date ? start_date : '[none]',
            end_date: end_date ? end_date : '[none]',
            pgs_acquisition_code: pgs_acquisition ? pgs_acquisition_code : '[not PGS]',
            id
        })),    
        rows: getAcquisitionRows(),
        defaultSortedRow: 'end_date',
        addRow: addRow ? addRow : () => dispatch(openModal(MODALS.ADD_ACQUISITION)),
        className: 'acquisition-table',
        rowLink: ({id}) => () => dispatch(openModal(MODALS.EDIT_ACQUISITION, null, {id: id})),
        selectedRow: ({id}) => id === selectedId
    })
    )({
        ...props,
        ...useSelector(isModeratorOrAdministratorSelector),
        addRow: () => dispatch(openModal(MODALS.ADD_ACQUISITION))
    });

    return (
        <Table {...mappedProps}/>
    );
};

AcquisitionRows.propTypes = {
    list: PropTypes.array
};


export default AcquisitionRows;
