import React from 'react';
import {useSelector} from 'react-redux';

import { displayCardsSelector } from '../../../redux/config/configSelectors';
import Cards from '../Cards';
import TaskRows from './TaskRows';
import PropTypes from 'prop-types';

const Tasks = props  => {
    const {displayCards} = useSelector(displayCardsSelector);
    const {selectedTaskId, list, onClick} = props;

    return (
        displayCards ?
            <Cards list={list} selectedId={selectedTaskId} onClick={onClick} type="task"/>
            : <TaskRows {...props}/>
    );
};

Tasks.propTypes = {
    selectedTaskId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    list: PropTypes.array,
    onClick: PropTypes.func
};

export default Tasks;
