import '../../../scss/components/projects/_validate-inline-edits.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validateInlineEdits, cancelInlineEdits } from '../../redux/projects/projectsActions';
import CheckedIcon from '../../../img/checked.svg';
import CancelIcon from '../../../img/cancel.svg';

const ValidateInlineEdits = ({ validateInlineEdits, cancelInlineEdits }) =>
    <div className="validate-inline-editing">
        <button onClick={ validateInlineEdits }>
            <CheckedIcon />
        </button>
        <button onClick={ cancelInlineEdits }>
            <CancelIcon />
        </button>
    </div>;

ValidateInlineEdits.propTypes = {
    validateInlineEdits: PropTypes.func,
    cancelInlineEdits: PropTypes.func
};

export default connect(
    state => ({ state }),
    dispatch => ({
        validateInlineEdits: state => dispatch(validateInlineEdits(state)),
        cancelInlineEdits: () => dispatch(cancelInlineEdits())
    }),
    ({ state }, { validateInlineEdits, cancelInlineEdits }) => ({
        validateInlineEdits: () => validateInlineEdits(state),
        cancelInlineEdits
    })
)(ValidateInlineEdits);
