import {
    SET_DISPLAY,
    SET_LOCALE
} from './configActions';
import wording from './wording';
import { DISPLAY_ROWS } from './constants';

const initialState = {
    locale: 'en_int',
    display: DISPLAY_ROWS,
    wording: wording.en
};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
                wording: wording[action.locale.split('_')[0]]
            };
        case SET_DISPLAY:
            return {
                ...state,
                display: action.display
            };
        default:
            return state;
    }
};
