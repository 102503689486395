export const stringToUTCDate = string =>
    new Date(
        string.endsWith('Z')
            ? string
            : string+'Z'
    );

export const stringToAbsoluteDate = string =>
    new Date(
        string.split('T')[0].replace(/-/g, '/')
    );
