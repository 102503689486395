import {FormFieldDataTypes, FormFieldTypes} from '../components/form/FormField';

const smallProjectColumns = [
    { name: 'Project', hasLink: true },
    { name: 'description' } //,
    //{ name: 'Project Type' }
];

const defaultUserProjectColumns = [
    { name: '' },
    { name: 'Project', hasLink: true },
    { name: 'Description', ellipsis: 45 },
    { name: 'Supervisor' },
    { name: 'PoC', suffix: '%', align: 'right', transform: Math.ceil },
    { name: 'Start Date' },
    { name: 'End Date' },
    { name: 'Include in projections' }
];

const projectColumns = (users, inlineEditProject, isEditing) => [
    { name: '' },
    { name: 'Project', hasLink: true },
    {
        name: 'Description',
        ellipsis: 45,
        editable: {
            type: FormFieldTypes.TEXTAREA,
            edit: (data, value) => inlineEditProject(data, value, 'description'),
            isEditing: data => isEditing(data, 'description')
        }
    },
    { name: 'Sold Revenue', prefix: '$', align: 'right' },
    { name: 'Compute Diff', align: 'right', transform: Math.ceil },
    { name: 'Compute Var', suffix: '%', align: 'right', transform: Math.ceil },
    { name: 'Geo Diff', align: 'right', transform: Math.ceil },
    { name: 'Geo Var', suffix: '%', align: 'right', transform: Math.ceil },
    {
        name: 'Supervisor',
        editable: {
            type: FormFieldTypes.SELECT,
            options: users.map(u => ({
                label: u.description,
                value: u.id
            })),
            valueKey: 'supervisorId',
            edit: (data, value) => inlineEditProject(data, parseInt(value), 'supervisor_id'),
            isEditing: data => isEditing(data, 'supervisor_id')
        }
    },
    { name: 'PoC', suffix: '%', align: 'right', transform: Math.ceil },
    { name: 'Start Date' },
    { name: 'End Date' },
    {
        name: 'Include in projections',
        editable: {
            type: FormFieldTypes.CHECKBOX,
            edit: (data, value) => inlineEditProject(data, value, 'include_in_projections'),
            isEditing: data => isEditing(data, 'include_in_projections'),
            dataType: FormFieldDataTypes.BOOL
        }
    }
];

export const getProjectColumns = (small, isModeratorOrAdministrator, isProjectSummary, users, inlineEditProject, isEditing) =>
    small
        ? smallProjectColumns
        : isModeratorOrAdministrator
            ? projectColumns(users, inlineEditProject, isEditing)
            : defaultUserProjectColumns;

//const smallProjectRows = ['name', 'description', 'projectType'];
const smallProjectRows = [ 'name', 'description' ];

const defaultUserProjectRows = [
    '',
    'name',
    'description',
    'supervisor',
    'projectPercentCompleted',
    'projectStartDate',
    'projectEndDate',
    'includeInProjections'
];

const projectRows = [
    '',
    'name',
    'description',
    'projectSoldRevenue',
    'projectComputeHoursDiff',
    'projectComputeHoursVar',
    'projectGeoMonthsDiff',
    'projectGeoMonthsVar',
    'supervisor',
    'projectPercentCompleted',
    'projectStartDate',
    'projectEndDate',
    'includeInProjections'
];

export const getProjectRows = (small, isModeratorOrAdministrator) =>
    small
        ? smallProjectRows
        : isModeratorOrAdministrator
            ? projectRows
            : defaultUserProjectRows;
