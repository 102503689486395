import React from 'react';
import FormAcquisition from '../form/FormAcquisition';
import { useSelector } from 'react-redux';
import {acquisitionSelectorFromRouteId} from '../../redux/acquisitions/acquisitionsSelectors';

const ModalEditAcquisition = () => {
    const {acquisition} = useSelector(acquisitionSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Survey</h2>
            {acquisition ? <FormAcquisition storedData={acquisition} /> : <p>Loading data...</p>}
        </div>
    );
};

export default ModalEditAcquisition;
