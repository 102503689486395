import PropTypes from 'prop-types';

export const projectShape = PropTypes.shape({
    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    description: PropTypes.string,
    computeAmount: PropTypes.number,
    computeCost: PropTypes.number,
    geoAmount: PropTypes.number,
    geoCost: PropTypes.number,
    projectType: PropTypes.string,
    supervisor: PropTypes.string,
    percentCompleted: PropTypes.number,
    surveySize: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
});
