import '../../../scss/components/base/_loader.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({
    wording: {
        loading = 'Loading...'
    } = {}
}) =>
    <div className="loader">
        <span className="visually-hidden">{ loading }</span>
    </div>;

Loader.propTypes = {
    wording: PropTypes.shape({
        loading: PropTypes.string
    })
};

export default Loader;
