import {
    applyMiddleware,
    legacy_createStore as createStore,
    combineReducers,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from '../config';

import acquisitionsReducer from './acquisitions/acquisitionsReducer';
import { ACQUISITIONS } from './acquisitions/acquisitionsActions';
import configReducer from './config/configReducer';
import { CONFIG } from './config/configActions';
import lookupsReducer from './lookups/lookupsReducer';
import { LOOKUPS } from './lookups/lookupsActions';
import routeReducer from './route/routeReducer';
import { ROUTE } from './route/routeActions';
import projectsReducer from './projects/projectsReducer';
import { PROJECTS } from './projects/projectsActions';
import userReducer from './user/userReducer';
import { USER } from './user/userActions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
    [ACQUISITIONS]: acquisitionsReducer,
    [CONFIG]: configReducer,
    [LOOKUPS]: lookupsReducer,
    [PROJECTS]: projectsReducer,
    [ROUTE]: routeReducer,
    [USER]: userReducer
});

const dateDehydrator = (key, value) => value instanceof Date ? value.toISOString() : value;
const dateRehydrator = (key, value) =>
    (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/))
        ? new Date(value)
        : value;

export const encode = toDehydrate => JSON.stringify(toDehydrate, dateDehydrator);
export const decode = toRehydrate => JSON.parse(toRehydrate, dateRehydrator);

const persistConfig = {
    key: 'root',
    transforms: [createTransform(encode, decode)],
    timeout: config.SESSION_TIMEOUT,
    whitelist: [USER],
    storage: storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

const storeCreator = (initialState = {}) => createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);

export const store = storeCreator();
export const persistor = persistStore(store);

