import '../../../scss/views/_error.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../components/base/ErrorMessage';

const Error = ({
    error
}) =>
    <div id="view-error">
        <ErrorMessage error={error} />
    </div>;

Error.propTypes = {
    error: PropTypes.string
};

export default Error;
