import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

import Table from '../../Table';
import { MODALS } from '../../modal/Modal';
import { openModal } from '../../../redux/route/routeActions';

import PercentsMenu from '../../contextMenu/PercentsMenu';
import { isModeratorOrAdministratorSelector } from '../../../redux/user/userSelectors';

const getCurrentPoc = task => {
    let totalPoc = 0;
    if (typeof task !== 'undefined') {
        if (typeof task.pctOfCmps !== 'undefined') {
            totalPoc = task.pctOfCmps.reduce((totalPoc, percentValue) =>
                totalPoc + percentValue.percent,
            0
            );
        }
    }

    return totalPoc;
};

const contextMenu = ({ id, taskId, task }, computedRows, {columns, rows}) =>
    <PercentsMenu taskId={taskId} percents={id} id={id} task={task} milestoneTaskPocTotal={getCurrentPoc(task)} { ...{
        computedRows,
        columns: columns.slice(1, columns.length),
        rows: rows.slice(1, rows.length)
    } } />;

contextMenu.propTypes = {
    id: PropTypes.number,
    taskId: PropTypes.number,
    task: PropTypes.any
};

const PercentRows = props => {
    const dispatch = useDispatch();

    const mappedProps = (({
        task = {},
        addRow,
        isModeratorOrAdministrator
    }) => ({
        columns: [
            { name:'Completion date' },
            { name: 'Percent', suffix: '%', align: 'right', transform: Math.ceil },
            { name: 'Description', ellipsis: 45  }
        ],
        data: task.pctOfCmps,
        rows: [
            'completionDate',
            'percent',
            'description'
        ],
        addRow,
        contextMenu: isModeratorOrAdministrator ? contextMenu: null,
        className: 'percent-rows',
        milestoneTaskPocTotal: getCurrentPoc(task)
    })
    )({
        ...props,
        ...useSelector(isModeratorOrAdministratorSelector),
        addRow: (({ task }) =>
            () => dispatch(openModal(MODALS.ADD_PERCENTS, null, { task: task.id, taskPocTotal: getCurrentPoc(task) }))
        )(props)
    });

    return (
        <Table {...mappedProps}/>
    );
};

export default PercentRows;




