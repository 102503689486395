import React from 'react';
import Card from '../Card';
import PropTypes from 'prop-types';

const PercentCard = ({description, percent, completionDate}) => {
    const title = new Date(completionDate).toLocaleDateString();

    return (
        <Card title={title} description={description} percentCompleted={percent} />
    );
};

PercentCard.propTypes = {
    description: PropTypes.string,
    percent: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    completionDate: PropTypes.string
};

export default PercentCard;
