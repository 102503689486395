import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import { displayCardsSelector } from '../../redux/config/configSelectors';
import Cards from './Cards';
import ProjectRows from './ProjectRows';
import { projectDetailLink } from '../../routes';
import { centerSelector } from '../../redux/common';
import { set } from '../../redux/route/routeActions';
import PropTypes from 'prop-types';

const Projects = props  => {
    const dispatch = useDispatch();
    const {displayCards} = useSelector(displayCardsSelector);
    const {center} = useSelector(centerSelector);
    const {list} = props;

    const redirect = route => dispatch(set(route));
    const onClick = id => redirect(projectDetailLink(center, id));

    return (
        displayCards ?
            <Cards list={list} onClick={onClick} type="project"/>
            : <ProjectRows {...props}/>
    );
};

Projects.propTypes = {
    list: PropTypes.array
};

export default Projects;
