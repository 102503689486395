import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {closeModal} from '../../redux/route/routeActions';
import withRoute from '../HOC/withRoute';
import {deleteProject, deleteMilestone, deletePercents, deleteTask} from '../../redux/projects/projectsActions';
import Button from '../base/Button';
import {deleteAcquisition} from '../../redux/acquisitions/acquisitionsActions';

const ModalConfirmDeletion = ({ queryParams: {
    acquisition_id,
    project_id,
    milestone_id,
    task_id,
    percents_id
}}) => {
    const dispatch = useDispatch();
    const closeModalD = () => dispatch(closeModal());
    const confirm = () => {
        if (acquisition_id) dispatch(deleteAcquisition(acquisition_id));
        if (project_id) dispatch(deleteProject(project_id));
        if (milestone_id) dispatch(deleteMilestone(milestone_id));
        if (task_id) dispatch(deleteTask(task_id));
        if (percents_id) dispatch(deletePercents(percents_id));
        return dispatch(closeModalD());
    };

    return (
        <div>
            <h2>Confirm deletion</h2>

            <Button onClick={confirm}>Confirm</Button>
            <Button className="is-white" onClick={closeModalD}>Cancel</Button>
        </div>
    );
};

ModalConfirmDeletion.propTypes = {
    queryParams: PropTypes.shape({
        acquisition_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        project_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        milestone_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        task_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        percents_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    })
};

export default withRoute(ModalConfirmDeletion);
