export const CONFIG = 'CONFIG';
export const SET_DISPLAY = `${CONFIG}/SET_DISPLAY`;
export const SET_LOCALE = `${CONFIG}/SET_LOCALE`;

export const setDisplay = display => ({
    type: SET_DISPLAY,
    display
});

export const setLocale = locale => ({
    type: SET_LOCALE,
    locale
});
