import '../../../scss/components/_context-menu.scss';
import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import withClickOutside from '../HOC/withClickOutside';
import CogIcon from '../../../img/cog.svg';
import offset from '../../helpers/offset';
import AccordionMenu from './AccordianMenu';

const ContextMenu = props => {
    const [ isOpen, setOpen ] = useState(false);
    const button = useRef();

    props.onClickOutside(() => setTimeout(() => setOpen(false), 200));

    const toggleMenu = toggled => setOpen(typeof toggled !== 'undefined' ? toggled : !isOpen);

    const getListStyle = () => {
        const { left, top } = offset(button.current);
        return {
            left: Math.max(0, Math.min(window.innerWidth - 160, left)),
            top: top + button.current.offsetHeight
        };
    };

    const renderList = () => isOpen ? ReactDOM.createPortal(
        <ul style={getListStyle()} className="context-menu-content" ref={props.clickOutsideRef}>
            {props.buttons.map(({ IconComponent, onClick, label, children }, i) =>
                children?.length > 0 ? 
                    (<AccordionMenu 
                        accordionButtons={children} 
                        accordianTitle={label} 
                        toggleMenu={toggleMenu} 
                        key={`accordionMenu-${i}`} 
                        isContextMenuOpen={isOpen} // Pass isOpen as a prop
                    />
                    ) : 
                    (
                        <li key={i}>
                            <button onClick={() => { onClick(); toggleMenu(false); }}>
                                <IconComponent /> <span>{label}</span>
                            </button>
                        </li>
                    )                   
            )}                    
        </ul>,
        document.getElementsByTagName('body')[0]
    ) : null;
    
    return (
        <div className={`context-menu ${props.className || ''}`}>
            <button ref={button} onClick={() => !isOpen ? toggleMenu(true) : null}>
                <CogIcon />
            </button>
            {renderList()}
        </div>
    );
};

ContextMenu.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            IconComponent: PropTypes.func,
            label: PropTypes.string,
            onClick: PropTypes.func,
            children: PropTypes.array
        })
    ),
    className: PropTypes.string,
    onClickOutside: PropTypes.func,
    clickOutsideRef: PropTypes.func
};

export default withClickOutside(ContextMenu);
