export const LOGIN = '/app';
export const PROJECT_SUMMARY = '/app/:center';
export const PROJECT_DETAILS = '/app/:center/project/:id';

export const ACQUISITIONS_SUMMARY = '/app/-acq/';

const uriWithParams = (uri, params) =>
    uri.replace(/:([a-zA-Z]+)/g, (full, g1) => params[g1]);

export const projectSummaryLink = center =>
    uriWithParams(PROJECT_SUMMARY, { center });

export const projectDetailLink = (center, id) =>
    uriWithParams(PROJECT_DETAILS, { center, id });

export const acquisitionSummaryLink = () =>
    uriWithParams(ACQUISITIONS_SUMMARY);
