import React from 'react';
import Card from '../Card';
import PropTypes from 'prop-types';

const MilestoneCard = ({id, name, description, soldRevenue, milestonePercentCompleted, supervisor, surveySize, onClick}) => {
    const myOnClick = () => onClick(id);

    return (
        <Card title={name} description={description} soldRevenue={soldRevenue}
            percentCompleted={milestonePercentCompleted} supervisor={supervisor} surveySize={surveySize}
            onClick={myOnClick} />
    );
};

MilestoneCard.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    soldRevenue: PropTypes.number,
    surveySize: PropTypes.number,
    supervisor: PropTypes.string,
    milestonePercentCompleted: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    onClick: PropTypes.func
};

export default MilestoneCard;
