import {FormFieldDataTypes, FormFieldTypes} from '../components/form/FormField';

const defaultUserMilestoneColumns = [
    {name: 'Project ID', hidden: true},
    {name: 'Milestone'},
    {name: 'Description', ellipsis: 45},
    {name: 'Supervisor'},
    {name: 'PoC', suffix: '%', align: 'right'},
    {name: 'Start date'},
    {name: 'End date'},
    {name: 'Include in projections'}
];

const milestoneColumns = (users, inlineEditMilestone, isEditing) => [
    {name: 'Project ID', hidden: true},
    {name: 'Milestone'},
    {
        name: 'Description',
        ellipsis: 45,
        editable: {
            type: FormFieldTypes.TEXTAREA,
            edit: (data, value) => inlineEditMilestone(data, value, 'description'),
            isEditing: data => isEditing(data, 'description')
        }
    },
    {name: 'Sold Revenue', prefix: '$', align: 'right'},
    {name: 'Compute Diff', align: 'right', transform: Math.ceil},
    {name: 'Compute Var', suffix: '%', align: 'right'},
    {name: 'Geo Diff', align: 'right', transform: Math.ceil},
    {name: 'Geo Var', suffix: '%', align: 'right'},
    {
        name: 'Supervisor',
        editable: {
            type: FormFieldTypes.SELECT,
            options: users.map(u => ({
                label: u.description,
                value: u.id
            })),
            valueKey: 'supervisorId',
            edit: (data, value) => inlineEditMilestone(data, value, 'supervisor_id'),
            isEditing: data => isEditing(data, 'supervisor_id'),
            dataType: FormFieldDataTypes.NUMBER
        }
    },
    {name: 'PoC', suffix: '%', align: 'right', transform: Math.ceil},
    {name: 'Start Date'},
    {name: 'End Date'},
    {
        name: 'Include in projections',
        valueKey: 'includeInProjections',
        editable: {
            type: FormFieldTypes.CHECKBOX,
            edit: (data, value) => inlineEditMilestone(data, value, 'include_in_projections'),
            isEditing: data => isEditing(data, 'include_in_projections'),
            dataType: FormFieldDataTypes.BOOL
        }
    }
];

export const getMilestoneColumns = (isModeratorOrAdministrator, users, inlineEditMilestone, isEditing) =>
    isModeratorOrAdministrator
        ? milestoneColumns(users, inlineEditMilestone, isEditing)
        : defaultUserMilestoneColumns;

const defaultUserMilestoneRows = [
    'projectId',
    'name',
    'description',
    'supervisor',
    'milestonePercentCompleted',
    'milestoneStartDate',
    'milestoneEndDate',
    'includeInProjections'
];

const milestoneRows = [
    'projectId',
    'name',
    'description',
    'milestoneSoldRevenue',
    'milestoneComputeHoursDiff',
    'milestoneComputeHoursVar',
    'milestoneGeoMonthsDiff',
    'milestoneGeoMonthsVar',
    'supervisor',
    'milestonePercentCompleted',
    'milestoneStartDate',
    'milestoneEndDate',
    'includeInProjections'
];

export const getMilestoneRows = isModeratorOrAdministrator =>
    isModeratorOrAdministrator
        ? milestoneRows
        : defaultUserMilestoneRows;
