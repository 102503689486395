import React from 'react';
import { useDispatch } from 'react-redux';

import ContextMenu from './ContextMenu';

import PencilIcon from '../../../img/pencil.svg';
import { openModal } from '../../redux/route/routeActions';
import { MODALS } from '../modal/Modal';
import TrashIcon from '../../../img/trash.svg';
import PropTypes from 'prop-types';

const TaskMenu = ({task}) => {
    const dispatch = useDispatch();
    const editTask = () => dispatch(openModal(MODALS.EDIT_TASK, null,{ task }));
    const deleteTask = () => dispatch(openModal(MODALS.CONFIRM_DELETION, null, { task_id: task }));
    const cloneTask = () => dispatch(openModal(MODALS.CLONE_TASK, null, {task} ));
    
    const buttons = [
        {
            IconComponent: PencilIcon,
            label: 'Edit',
            onClick: editTask
        },
        {
            IconComponent: TrashIcon,
            label: 'Delete',
            onClick: deleteTask
        },
        {
            IconComponent: PencilIcon,
            label: 'Clone',
            onClick: cloneTask
        }
    ];
    
    return (
        <ContextMenu buttons={buttons} className="task-menu"/>
    );
};

TaskMenu.propTypes = {
    task: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

export default TaskMenu;
