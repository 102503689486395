import React from 'react';
import withWording, {WithWordingProps} from '../../components/HOC/withWording';
import AcquisitionsSummary from './AcquisitionsSummary';
import withFetchOnLoad from '../../components/HOC/withFetchOnLoad';
import {useDispatch, useSelector} from 'react-redux';
import {filtersSelector, routeSelector} from '../../redux/route/routeSelectors';
import {fetch as fetchAcquisitions} from '../../redux/acquisitions/acquisitionsActions';
import {ACQUISITIONS_SUMMARY} from '../../routes';
import {acquisitionsSelector} from '../../redux/acquisitions/acquisitionsSelectors';
import {openModal} from '../../redux/route/routeActions';
import {MODALS} from '../../components/modal/Modal';
import filter from '../../helpers/filter';
import {isModeratorOrAdministratorSelector} from '../../redux/user/userSelectors';
import Error from '../error/Error';

const AcquisitionsSummaryContainer = ({wording}) => {
    const dispatch = useDispatch();
    const {isModeratorOrAdministrator} = useSelector(isModeratorOrAdministratorSelector);
    const {filters} = useSelector(filtersSelector);
    const {acquisitions} = useSelector(acquisitionsSelector);
    const acquisitionsFiltered = filter(acquisitions, [], filters);

    const addAcquisition = () => dispatch(openModal(MODALS.ADD_ACQUISITION));

    return (
        isModeratorOrAdministrator ? <AcquisitionsSummary addAcquisition={addAcquisition}
            acquisitionWording={wording.acquisition}
            acquisitions={acquisitionsFiltered}/>
            : <Error error={wording.accessDenied}/>
    );
};

AcquisitionsSummaryContainer.propTypes = {
    ...WithWordingProps
};

export default withWording('acquisition', 'accessDenied')(withFetchOnLoad(
    ({
        configRoute = useSelector(routeSelector).configRoute
    }) => ({
        fetch: () => fetchAcquisitions(),
        fetchCondition: configRoute === ACQUISITIONS_SUMMARY,
        fetchArgs: []
    })
)(AcquisitionsSummaryContainer));
