import asyncAction from '../../helpers/asyncAction';
import config from '../../config';

export const ACQUISITIONS = 'ACQUISITIONS';

export const EDIT_ACQUISITION = `${ACQUISITIONS}/EDIT_ACQUISITION`;
export const ADD_ACQUISITION = `${ACQUISITIONS}/ADD_ACQUISITION`;
export const DELETE_ACQUISITION = `${ACQUISITIONS}/DELETE_ACQUISITION`;
export const FETCH = `${ACQUISITIONS}/FETCH`;
export const FETCH_SINGLE = `${ACQUISITIONS}/FETCH_SINGLE`;
export const LINK_ACQUISITION = `${ACQUISITIONS}/LINK_ACQUISITION`;
export const UNLINK_ACQUISITION = `${ACQUISITIONS}/UNLINK_ACQUISITION`;

export const addAcquisition = ({...data}, cb) => asyncAction(ADD_ACQUISITION, {
    method: 'POST',
    url: config.WEBSERVICES.ACQUISITION
})({...data}, cb);

export const editAcquisition = ({id, ...data}, cb) => asyncAction(EDIT_ACQUISITION, {
    method: 'PUT',
    url: config.WEBSERVICES.ACQUISITION + id
})({...data, id}, cb);

export const deleteAcquisition = (id, cb) => asyncAction(DELETE_ACQUISITION, {
    method: 'DELETE',
    url: config.WEBSERVICES.ACQUISITION + id
})(id, cb);

export const linkAcquisitionToProject = ({projectId, acquisitionId}, cb) => asyncAction(LINK_ACQUISITION, {
    method: 'PUT',
    url: config.WEBSERVICES.ACQUISITION + `${acquisitionId}/assignProject/${projectId}`
})({projectId, acquisitionId}, cb);

export const unlinkAcquisitionFromProject = ({projectId, acquisitionId}, cb) => asyncAction(UNLINK_ACQUISITION, {
    method: 'DELETE',
    url: config.WEBSERVICES.ACQUISITION + `${acquisitionId}/assignProject/${projectId}`
})({projectId, acquisitionId}, cb);

export const fetch = asyncAction(FETCH, {
    url: config.WEBSERVICES.ACQUISITION_COMPLEX
});

export const fetchSingle = ({
    id,
    ...data
} = {}, cb) => asyncAction(FETCH_SINGLE, {
    url: config.WEBSERVICES.ACQUISITION + id
})({id, ...data}, cb);
