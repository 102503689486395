import { compose, find, pick, property } from 'lodash/fp';
import {ACQUISITIONS} from './acquisitionsActions';
import {routeAndStateSelector} from '../route/routeSelectors';

export const stateSelector = property(ACQUISITIONS);

export const indexFromAcquisitionId = acquisitionId => state => {
    acquisitionId = parseInt(acquisitionId);
    for (let i = 0, iLength = state.list.length; i < iLength; i++) {
        if (state.list[i].id === parseInt(acquisitionId)) return i;
    }
};

export const acquisitionsSelector = compose(
    acquisitions => ({ acquisitions }),
    property('list'),
    stateSelector
);

export const acquisitionSelectorFromRouteId = compose(
    ({ acquisition = {} }) => ({ acquisition: acquisition }),
    ({ state, route }) => {
        let obj = acquisitionSelectorFromId(route.params.id || route.queryParams.id)(state);
        if (typeof(obj.acquisition) === 'undefined') obj = {'acquisition': {id: 0}};
        return obj;
    },
    routeAndStateSelector
);

export const acquisitionSelectorFromId = _id => compose(
    acquisition => ({ acquisition: acquisition }),
    find(({ id }) => id === parseInt(_id)),
    property('list'),
    stateSelector
);

export const isLoadedSelector = compose(
    ({ isLoaded }) => ({ isLoaded }),
    stateSelector
);

/**
 * FIXME: Here as well as other places like projects and user, we should consolidate into a new Redux key called errors
 */
export const errorsSelector = compose(
    pick('errors'),
    stateSelector
);

export const errorMessageSelector = compose(
    pick('message'),
    stateSelector
);

