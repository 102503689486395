import update from 'immutability-helper';
import { mapAddedMilestone, mapMilestone } from '../map';
import { indexFromProjectId, indexesFromMilestoneId } from '../projectsSelectors';


export const addMilestoneToState = (state, response) =>
    update(state, {
        list: {
            [indexFromProjectId(response.project_id)(state)]: {
                milestones: {
                    $push: [mapAddedMilestone(response)]
                }
            }
        }
    });

export const editMilestoneInState = (state, body) => {

    const {
        projectIndex,
        milestoneIndex
    } = indexesFromMilestoneId(body.id)(state);

    const milestone = state.list[projectIndex].milestones[milestoneIndex];
    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        $set: mapMilestone(body, milestone)
                    }
                }
            }
        }
    });

};

export const deleteMilestoneInState = (state, body) => {
    const {
        projectIndex,
        milestoneIndex
    } = indexesFromMilestoneId(body.id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    $splice: [[ milestoneIndex, 1 ]]
                }
            }
        }
    });
};
