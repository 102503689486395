import React from 'react';
import { wordingKeysSelector } from '../../redux/config/configSelectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const withWording = (...keys) => WrappedComponent => {
    const ComponentWrappedWithWording = props => {
        const {wording} = useSelector(wordingKeysSelector(keys));
        return (
            <WrappedComponent {...props} wording={wording}/>
        );
    };

    return ComponentWrappedWithWording;
};

export const WithWordingProps = {
    wording: PropTypes.object
};

export default withWording;
