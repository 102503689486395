import update from 'immutability-helper';
import {mapProject} from '../map';
import { indexFromProjectId } from '../projectsSelectors';

export const addProjectToState = (state, response) =>
    update(state, {
        list: {
            $push: [mapProject(response)]
        }
    });

export const editProjectInState = (state, body) => {

    const projectIndex = indexFromProjectId(body.id)(state);
    const project = state.list[projectIndex];
    return update(state, {
        list: {
            [projectIndex]: {
                $set: {
                    ...mapProject(body, project),
                    milestones: project.milestones
                }
            }
        }
    });
};

export const deleteProjectInState = (state, body) =>
    update(state, {
        list: {
            $splice: [[ indexFromProjectId(body.id)(state), 1 ]]
        }
    });
