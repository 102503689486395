
const filterBySupervisor = (filterValue, projects, users) => {
    const matchUsers = users.filter(
        ({ description }) =>
            description.toLowerCase().startsWith(filterValue.toLowerCase())
    );
    return matchUsers.reduce((acc, { id }) =>
        acc.concat(
            projects.filter(({ supervisorId }) =>
                supervisorId === id)
        ), []);

};

const defaultFilter = (filter, filterValue, projects) =>
    projects.filter(project => {
        if (Object.prototype.hasOwnProperty.call(project, filter)) {
            if (!project[filter].toLowerCase().startsWith(filterValue.toLowerCase())) return false;
        } else {
            return false;
        }
        return true;
    });

const applyFilter = (filter, filterValue, projects, users) => {
    switch(filter) {
        case 'supervisor':
            return filterBySupervisor(filterValue, projects, users);
        default:
            if (filter && filterValue) return defaultFilter(filter, filterValue, projects);
            return projects;
    }
};

export default (projects, users, filters = []) =>
    Object.keys(filters)
        .reduce((acc, filter) =>
            applyFilter(filter, filters[filter], acc, users)
        , [...projects]);
