import {
    FETCH_LOOKUPS, FETCH_VALIDATORS, FETCH_COMPUTE_RESOURCE
} from './lookupsActions';
import { asyncActionSuccess } from '../../helpers/asyncAction';

const initialState = {
};

export default (state = initialState, action = {}) => {
    switch(action.type) {
        case asyncActionSuccess(FETCH_LOOKUPS):
            return {
                ...state,
                ...action.response
            };
        case asyncActionSuccess(FETCH_VALIDATORS):
            return {
                ...state,
                milestone_task_validator: action.response
            };
        case asyncActionSuccess(FETCH_COMPUTE_RESOURCE):
            return {
                ...state,
                compute_resource: action.response
            };
        default:
            return state;
    }
};
