import firstNotUndefined from '../../helpers/firstNotUndefined';
import {stringToAbsoluteDate} from '../../helpers/dates';

export const mapPctOfCmps = ({
    id,
    milestone_task_id,
    description,
    percent,
    completion_date
}) =>
    ({
        id,
        taskId: milestone_task_id,
        description,
        percent,
        completionDate: completion_date && completion_date.split('T')[0] || ''
    });

export const mapTask = ({
    algorithm_id,
    algorithm,
    description,
    proj_end_date,
    sold_end_date,
    milestone_task_name,
    proj_start_date,
    sold_start_date,
    compute_resource_id,
    compute_resource,
    validator_id,
    validator,
    deputy_validator_id,
    deputy_validator,
    proj_compute_hours,
    proj_compute_rate,
    proj_geo_months,
    proj_geo_rate,
    sold_compute_hours,
    sold_compute_rate,
    sold_geo_months,
    sold_geo_rate,
    sold_revenue,
    pctOfCmps = [],
    position,
    position_id,
    id,
    include_in_projections
},
task = {}
) => ({
    algorithmId: firstNotUndefined(algorithm_id, task.algorithmId),
    algorithm: firstNotUndefined(algorithm, task.algorithm),
    description: firstNotUndefined(description, task.description),
    projEndDate: proj_end_date ? stringToAbsoluteDate(proj_end_date) : task.projEndDate,
    soldEndDate: sold_end_date ? stringToAbsoluteDate(sold_end_date) : task.soldEndDate,
    projStartDate: proj_start_date ? stringToAbsoluteDate(proj_start_date) : task.projStartDate,
    soldStartDate: sold_start_date ? stringToAbsoluteDate(sold_start_date) : task.soldStartDate,
    name: firstNotUndefined(milestone_task_name, task.name),
    projectedComputeHours: firstNotUndefined(proj_compute_hours, task.projectedComputeHours),
    projectedComputeRate: firstNotUndefined(proj_compute_rate, task.projectedComputeRate),
    projectedGeoMonths: firstNotUndefined(proj_geo_months, task.projectedGeoMonths),
    projectedGeoRate: firstNotUndefined(proj_geo_rate, task.projectedGeoRate),
    soldComputeHours: firstNotUndefined(sold_compute_hours, task.soldComputeHours),
    soldComputeRate: firstNotUndefined(sold_compute_rate, task.soldComputeRate),
    soldGeoMonths: firstNotUndefined(sold_geo_months, task.soldGeoMonths),
    soldGeoRate: firstNotUndefined(sold_geo_rate, task.soldGeoRate),
    soldRevenue: firstNotUndefined(sold_revenue, task.soldRevenue),
    computeResourceId: firstNotUndefined(compute_resource_id, task.computeResourceId),
    computeResource: firstNotUndefined(compute_resource, task.computeResource),
    validatorId: firstNotUndefined(validator_id, task.validatorId),
    validator: firstNotUndefined(validator, task.validator),
    deputyValidatorId: firstNotUndefined(deputy_validator_id, task.deputyValidatorId),
    deputyValidator: firstNotUndefined(deputy_validator, task.deputyValidator),
    pctOfCmps: pctOfCmps ? pctOfCmps.map(mapPctOfCmps) : task.computeResourceId || [],
    position: firstNotUndefined(position, task.position),
    positionId: firstNotUndefined(position_id, task.positionId),
    id: firstNotUndefined(id, task.id),
    includeInProjections: firstNotUndefined(include_in_projections, task.includeInProjections)
});

export const mapTasks = (tasks = []) => tasks.map(mapTask);

export const mapMilestone = ({
    id,
    project_id,
    milestone_name,
    description,
    notes,
    milestoneTasks,
    supervisor_id,
    site_id,
    datasets_during,
    datasets_after,
    include_in_projections
},
milestone = {}
) => ({
    id,
    projectId: firstNotUndefined(project_id, milestone.projectId),
    name: firstNotUndefined(milestone_name, milestone.name),
    notes: firstNotUndefined(notes, milestone.notes),
    description: firstNotUndefined(description, milestone.description),
    tasks: milestoneTasks ? mapTasks(milestoneTasks) : milestone.tasks || [],
    supervisorId: firstNotUndefined(supervisor_id, milestone.supervisorId),
    siteId: firstNotUndefined(site_id, milestone.siteId),
    datasetsDuring: firstNotUndefined(datasets_during, milestone.datasetsDuring),
    datasetsAfter: firstNotUndefined(datasets_after, milestone.datasetsAfter),
    includeInProjections: firstNotUndefined(include_in_projections, milestone.includeInProjections)
});

export const mapMilestones = (milestones = []) => milestones.map(mapMilestone);

export const mapProject = ({
    active,
    area_id,
    bid_id,
    center_id,
    description,
    id,
    survey_size,
    milestones = [],
    stages = [],
    project_name,
    project_type_id,
    supervisor_id,
    unit_type_id,
    input_size,
    azimuths,
    survey_type_id,
    project_group_id,
    client_id,
    acquisition_type_id,
    include_in_projections,
    acquisitions = []
},
project = {}
) => ({
    active: firstNotUndefined(active, project.active),
    areaId: firstNotUndefined(area_id, project.areaId),
    bidId: firstNotUndefined(bid_id, project.bidId),
    centerId: firstNotUndefined(center_id, project.centerId),
    id: firstNotUndefined(id, project.id),
    milestones: milestones ? mapMilestones(milestones) : project.milestones,
    stages: stages ? stages : project.stages,
    name: firstNotUndefined(project_name, project.name),
    description: firstNotUndefined(description, project.description),
    projectTypeId: firstNotUndefined(project_type_id, project.projectTypeId),
    supervisorId: firstNotUndefined(supervisor_id, project.supervisorId),
    surveySize: firstNotUndefined(survey_size, project.surveySize),
    unitTypeId: firstNotUndefined(unit_type_id, project.unitTypeId),
    inputSize: firstNotUndefined(input_size, project.inputSize),
    azimuths: firstNotUndefined(azimuths, project.azimuths),
    surveyTypeId: firstNotUndefined(survey_type_id, project.surveyTypeId),
    projectGroupId: firstNotUndefined(project_group_id, project.projectGroupId),
    clientId: firstNotUndefined(client_id, project.clientId),
    acquisitionTypeId: firstNotUndefined(acquisition_type_id, project.acquisitionTypeId),
    includeInProjections: firstNotUndefined(include_in_projections, project.includeInProjections),
    acquisitions: acquisitions ? acquisitions : project.acquisitions
});

export const mapProjects = (projects = []) => projects.map(mapProject);

export const mapAddedMilestone = ({
    description,
    id,
    milestone_name,
    notes,
    supervisor_id,
    site_id,
    datasets_during,
    datasets_after,
    include_in_projections
}) => ({
    id,
    name: milestone_name,
    description,
    tasks: [],
    notes,
    supervisorId: supervisor_id,
    siteId: site_id,
    percentCompleted: 0,
    datasetsDuring: datasets_during,
    datasetsAfter: datasets_after,
    includeInProjections: include_in_projections
});
