{
  "name": "iris-frontend",
  "version": "1.2.0",
  "description": "IRIS front-end React/Redux Web Application",
  "engines": {
    "node": ">= 18"
  },
  "targets": {
    "default": {
      "engines": {
        "browsers": ">= 50%"
      }
    }
  },
  "scripts": {
    "start": "NODE_ENV=prod parcel src/index.html -p 5050 --no-cache",
    "start:local": "NODE_ENV=localhost-8888 parcel src/index.html",
    "start:nonprod": "NODE_ENV=nonprod parcel src/index.html",
    "start:dev": "NODE_ENV=dev parcel src/index.html",
    "lint": "eslint src --ext .jsx,.js  --color",
    "build": "parcel build src/index.html --dist-dir dist && yarn injectConfig",
    "test": "jest",
    "injectConfig": "sed -i -e 's@<body>@<body><script src='/config.js'></script>@g' dist/index.html"
  },
  "author": "Jérémie Dubuis",
  "license": "UNLICENSED",
  "private": true,
  "devDependencies": {
    "@babel/core": "^7.7.7",
    "@babel/plugin-proposal-throw-expressions": "^7.23.3",
    "@babel/preset-env": "^7.7.7",
    "@babel/preset-react": "^7.7.4",
    "@parcel/core": "^2.9.3",
    "@parcel/transformer-sass": "^2.9.3",
    "babel-eslint": "^8.2.5",
    "babel-jest": "^29.7.0",
    "babel-plugin-inline-react-svg": "^1.1.0",
    "buffer": "^5.5.0||^6.0.0",
    "cssnano": "^4.1.10",
    "dotenv": "^6.0.0",
    "enzyme": "^3.11.0",
    "enzyme-adapter-react-16": "^1.15.6",
    "eslint": "^8.24.0",
    "eslint-config-ideal": "^1.0.0",
    "eslint-plugin-import": "^2.13.0",
    "eslint-plugin-react": "^7.10.0",
    "immutability-helper": "^2.9.1",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "moment": "^2.24.0",
    "node-sass": "^9.0.0",
    "parcel": "^2.9.3",
    "querystring-es3": "^0.2.1",
    "react": "^16.8.0",
    "react-datepicker": "^1.8.0",
    "react-dom": "^16.12.0",
    "react-redux": "^8.0.4",
    "react-test-renderer": "16.12.0",
    "redux": "^4.0.5",
    "redux-thunk": "^2.3.0",
    "sass": "1.48.0",
    "typescript": "^5.3.3"
  },
  "dependencies": {
    "@appbaseio/reactivesearch": "^4.0.0-rc.32",
    "@azure/msal-browser": "^3.6.0",
    "@azure/msal-react": "^2.0.8",
    "@reduxjs/toolkit": "^1.9.5",
    "antd": "^5.14.1",
    "lodash": "^4.17.21",
    "prop-types": "^15.7.2",
    "react-idle-timer": "<5.7",
    "react-select": "^2.4.4",
    "react-vis": "^1.11.7",
    "recharts": "^1.5.0",
    "redux-persist": "^6.0.0"
  },
  "alias": {
    "process": false
  }
}
