import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormFieldDataTypes, FormFieldTypes, validateDecimals, validateInteger } from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector} from '../../redux/acquisitions/acquisitionsSelectors';
import {
    marineStreamerTypeSelector,
    acquisitionCategorySelector,
    towingMethodSelector,
    acquisitionComponentsSelector,
    streamerLengthSelector,
    sampleIntervalSelector,
    surveyTypesSelector,
    unitTypesSelector,
    acquisitionContractorSelector

} from '../../redux/lookups/lookupsSelectors';
import { closeModal } from '../../redux/route/routeActions';

import {addAcquisition, editAcquisition, fetch as fetchAcquisitions} from '../../redux/acquisitions/acquisitionsActions';
import {isModeratorOrAdministratorSelector} from '../../redux/user/userSelectors';
import Error from '../../views/error/Error';
import withWording, {WithWordingProps} from '../HOC/withWording';
import Loader from '../base/Loader';

const { SUBMIT, CHECKBOX, SELECT, NUMBER, TEXT, DATE, DIVIDER } = FormFieldTypes;

const marineOnlyNames = [
    'vessel_name',
    'number_sources',
    'source_volume',
    'source_separation',
    'shot_point_interval',
    'shot_point_increment',
    'record_length',
    'number_cables',
    'nominal_vessel_speed',
    'gun_depth',
    'fanning_percentage',
    'shot_points',
    'shallow_water_points',
    'medium_water_points',
    'deep_water_points',
    'cable_separation',
    'cable_length',
    'cable_depth',
    'marine_streamer_type_id',
    'towing_method_id',
    'streamer_length_id',
    'group_interval'
];

const FormAcquisition = ({storedData, wording}) => {
    const { errors } = useSelector(errorsSelector);
    const { message } = useSelector(errorMessageSelector);
    const {isModeratorOrAdministrator} = useSelector(isModeratorOrAdministratorSelector);
    const { marineStreamerTypes } = useSelector(marineStreamerTypeSelector);
    const {acquisitionCategories} = useSelector(acquisitionCategorySelector);
    const {towingMethods} = useSelector(towingMethodSelector);
    const {acquisitionComponents} = useSelector(acquisitionComponentsSelector);
    const {streamerLengths} = useSelector(streamerLengthSelector);
    const {sampleIntervals} = useSelector(sampleIntervalSelector);
    const {surveyTypes} = useSelector(surveyTypesSelector);
    const {unitTypes} = useSelector(unitTypesSelector);
    const {acquisitionContractors} = useSelector(acquisitionContractorSelector);

    const dispatch = useDispatch();
    const closeMe = () => dispatch(fetchAcquisitions(null, d => d(closeModal()) ));

    const updateAcquisition = data => dispatch(editAcquisition(data, closeMe));
    const createAcquisition = data => dispatch(addAcquisition(data, closeMe));

    const [ submitted, setSubmitted ] = useState(false);
    const [ formData, setFormData ] = useState(null);
    const showLoading = submitted && !errors && !message;
    const values = formData ? formData : storedData ? storedData : {};
    const [id] = useState(values.id);
    const [ acquisitionCategoryId, setAcquisitionCategoryId ] = useState(values.acquisition_category_id);
    const [ pgsAcquisition, setPgsAcquisition ] = useState(values.pgs_acquisition);
    const [ surveyTypeId, setSurveyTypeId ] = useState(values.survey_type_id);
    const [ surveySize, setSurveySize ] = useState(values.survey_size);

    const dynamicFieldValueGetter = {};
    const dynamicFieldValueSetter = {};
    [ 'pgs_acquisition_code', ...marineOnlyNames ].forEach(n => {
        [ dynamicFieldValueGetter[n], dynamicFieldValueSetter[n] ] = useState(values[n]);
    });

    const updateAcquisitionCategory = e => {
        setAcquisitionCategoryId(e.target.value);
        marineOnlyNames.forEach(n => dynamicFieldValueSetter[n](e.target.value === 1 ? values[n] : null));
    };
    const handlePgsAcquisitionChange = e => {
        setPgsAcquisition(e.target.checked);
    };

    const {
        bin_size_inline,
        bin_size_xline,
        cdp_fold,
        sample_interval_id,
        min_water_depth,
        max_water_depth,
        marine_streamer_type_id,
        acquisition_components_id,
        water_depth_mode,
        vessel_name,
        pgs_acquisition,
        survey_type_id,
        survey_size,
        unit_type_id,
        start_date,
        end_date,
        acquisition_country,
        acquisition_project_name,
        acquisition_project_area,
        acquisition_contractor_id
    } = values;

    const onSubmit = (e, data) => {
        e.preventDefault();
        setFormData({...data, id: values.id});
        setSubmitted(true);
        
        const payload = Object.entries(data).reduce((acc, [ key, value ]) => {
            acc[key] = value !== undefined && value !== null && value !== '' ? value : null;
            return acc;
        }, {});
        
        if (id) {
            const empties = fields.filter(f => !Object.hasOwn(payload, f.name));
            empties.forEach(e => payload[e.name] = null);
            updateAcquisition({ id: id, ...payload });
        } else {
            createAcquisition(payload);
        }
    };
    
    const notMarineAcquisition = parseInt(acquisitionCategoryId ? acquisitionCategoryId : 0) !== 1;

    const shotPercents = dynamicFieldValueGetter['shot_points'] !== null &&
    parseInt(dynamicFieldValueGetter['shot_points']) > 0 ?
        {
            'shallow_water_points': parseInt(100 * dynamicFieldValueGetter['shallow_water_points'] / dynamicFieldValueGetter['shot_points']),
            'medium_water_points': parseInt(100 * dynamicFieldValueGetter['medium_water_points'] / dynamicFieldValueGetter['shot_points']),
            'deep_water_points': parseInt(100 * dynamicFieldValueGetter['deep_water_points'] / dynamicFieldValueGetter['shot_points'])
        } : {};

    const adjustShotPointPercentages = e => {
        if (e.target.value < 0) {
            // This is not working
            e.target.value = 0;
            dynamicFieldValueSetter[e.target.name](0);
        }
        const total = e.target.form['shot_points'];
        const shallow = e.target.form['shallow_water_points'];
        const medium = e.target.form['medium_water_points'];
        const deep = e.target.form['deep_water_points'];

        [ shallow, medium, deep ].forEach(input => {
            const percent = Math.max(0, parseInt(100 * parseInt(input.value) / parseInt(total.value) ));
            document.getElementById(`${input.id}-display`).textContent = `${percent}%`;
        });
    };

    const fields = [
        {
            type: CHECKBOX,
            name: 'pgs_acquisition',
            id: 'pgsAcquisition',
            label: 'PGS Acquisition',
            checked: pgs_acquisition,
            dataType: FormFieldDataTypes.BOOL,
            onChange: handlePgsAcquisitionChange,
            validation: {
                required: true
            }
        },
        {
            type: TEXT,
            name: 'pgs_acquisition_code',
            id: 'pgsAcquisitionCode',
            label: 'PGS Acquisition Code',
            value: dynamicFieldValueGetter['pgs_acquisition_code'],
            validation: {
                required: pgsAcquisition,
                maxLength: 20
            },
            disabled: !pgsAcquisition
        },
        {
            type: DATE,
            name: 'start_date',
            id: 'startDate',
            label: 'Start Date',
            value: start_date,
            dateOptions: {
                popperPlacement: 'top'
            }
        },
        {
            type: DATE,
            name: 'end_date',
            id: 'endDate',
            label: 'End Date',
            value: end_date,
            dateOptions: {
                popperPlacement: 'top'
            }
        },
        {
            type: TEXT,
            name: 'acquisition_project_name',
            id: 'acquisitionProjectName',
            label: 'Survey Project Name',
            value: acquisition_project_name,
            validation: {
                required: true,
                maxLength: 512
            },
            size: '2thirds',
            break: true
        },
        {
            type: TEXT,
            name: 'acquisition_country',
            id: 'acquisitionCountry',
            label: 'Survey Country',
            value: acquisition_country,
            validation: {
                required: true,
                maxLength: 300
            },
            size: 'third'
        },
        {
            type: TEXT,
            name: 'acquisition_project_area',
            id: 'acquisitionProjectArea',
            label: 'Survey Area',
            value: acquisition_project_area,
            validation: {
                required: true,
                maxLength: 512
            },
            size: '2thirds',
            break: true
        },
        {
            type: SELECT,
            name: 'acquisition_category_id',
            id: 'acquisitionCategoryId',
            label: 'Acquisition Category',
            value: acquisitionCategoryId,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: updateAcquisitionCategory,
            options: [
                { label: '', value: null },
                ...acquisitionCategories.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ],
            validation: {
                required: true
            },
            size: 'third'
        },
        {
            type: NUMBER,
            name: 'bin_size_inline',
            id: 'binSizeInline',
            label: 'Bin size inline spacing',
            value: bin_size_inline,
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'bin_size_xline',
            id: 'binSizeXLine',
            label: 'Bin size xline spacing',
            value: bin_size_xline,
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'cdp_fold',
            id: 'cdpFold',
            label: 'CDP Fold',
            value: cdp_fold,
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: SELECT,
            name: 'sample_interval_id',
            id: 'sampleIntervalId',
            label: 'Sample Interval',
            value: sample_interval_id,
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...sampleIntervals.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },
        {
            type: NUMBER,
            name: 'water_depth_mode',
            id: 'waterDepthMode',
            label: 'Water Depth Mode',
            value: water_depth_mode,
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'min_water_depth',
            id: 'minWaterDepth',
            label: 'Minimum Water Depth',
            value: min_water_depth,
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'max_water_depth',
            id: 'maxWaterDepth',
            label: 'Maximum Water Depth',
            value: max_water_depth,
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: SELECT,
            name: 'acquisition_components_id',
            id: 'acquisitionComponentsId',
            label: 'Acquisition Components',
            value: acquisition_components_id,
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...acquisitionComponents.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },
        {
            type: SELECT,
            name: 'acquisition_contractor_id',
            id: 'acquisitionContractorId',
            label: 'Acquisition Contractor',
            value: acquisition_contractor_id,
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...acquisitionContractors.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ],
            break: true
        },
        {
            type: SELECT,
            name: 'survey_type_id',
            id: 'surveyTypeId',
            label: 'Survey Type',
            value: survey_type_id,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: e => setSurveyTypeId(e.target.options[e.target.selectedIndex].value),
            options: [
                { label: '', value: null },
                ...surveyTypes.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },
        {
            type: NUMBER,
            name: 'survey_size',
            id: 'survey_size',
            label: 'Survey Size',
            value: survey_size,
            dataType: FormFieldDataTypes.INTEGER,
            onChange: e => setSurveySize(e.target.value),
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: SELECT,
            name: 'unit_type_id',
            id: 'unitTypeId',
            label: 'Survey Size Units',
            value: unit_type_id,
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...unitTypes.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ].filter(o => {
                switch (parseInt(surveyTypeId)) {
                    case 1:
                        return o.value === 1;
                    case 2:
                    case 3:
                    case 4:
                        return o.value === 2;
                    default:
                        return o;
                }
            }),
            validation: {
                required: surveySize > 0
            }
        },
        {
            type: DIVIDER,
            name: 'divider',
            id: 'divider',
            value: 'Marine Streamer detail',
            size: 'full border'
        },
        {
            type: SELECT,
            name: 'marine_streamer_type_id',
            id: 'marineStreamerTypeId',
            label: 'Marine Streamer Type',
            value: marine_streamer_type_id,
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...marineStreamerTypes.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },
        {
            type: SELECT,
            name: 'towing_method_id',
            id: 'towingMethodId',
            label: 'Towing Method',
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...towingMethods.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },
        {
            type: SELECT,
            name: 'streamer_length_id',
            id: 'streamerLengthId',
            label: 'Streamer Length',
            dataType: FormFieldDataTypes.INTEGER,
            options: [
                { label: '', value: null },
                ...streamerLengths.map(type => ({
                    label: type.description,
                    value: type.id
                }))
            ]
        },

        {
            type: TEXT,
            name: 'vessel_name',
            id: 'vesselName',
            label: 'Vessel Name',
            value: vessel_name,
            validation: {
                required: false,
                maxLength: 50
            }
        },
        {
            type: NUMBER,
            name: 'number_sources',
            id: 'numberSources',
            label: 'Number of Sources',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'source_separation',
            id: 'sourceSeparation',
            label: 'Source Separation',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'source_volume',
            id: 'sourceVolume',
            label: 'Source Volume',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'gun_depth',
            id: 'gunDepth',
            label: 'Gun Depth',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'number_cables',
            id: 'numberCables',
            label: 'Number of Cables',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'cable_separation',
            id: 'cableSeparation',
            label: 'Cable Separation',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'cable_length',
            id: 'cableLength',
            label: 'Cable Length',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'cable_depth',
            id: 'cableDepth',
            label: 'Cable Depth',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'shot_point_increment',
            id: 'shotPointIncrement',
            label: 'Shot Point Increment',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'shot_point_interval',
            id: 'shotPointInterval',
            label: 'Shot Point Interval',
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'nominal_vessel_speed',
            id: 'nominalVesselSpeed',
            label: 'Nominal Vessel Speed',
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'group_interval',
            id: 'groupInterval',
            label: 'Group Interval',
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: false,
                validate: validateDecimals(0, 2),
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'shot_points',
            id: 'shotPoints',
            label: 'Total Shot Points',
            dataType: FormFieldDataTypes.INTEGER,
            onChange: adjustShotPointPercentages,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'shallow_water_points',
            id: 'shallowWaterPoints',
            label: 'Shallow Shot Points',
            dataType: FormFieldDataTypes.INTEGER,
            onChange: adjustShotPointPercentages,
            className: 'shot-point-percent',
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'medium_water_points',
            id: 'mediumWaterPoints',
            label: 'Medium Shot Points',
            dataType: FormFieldDataTypes.INTEGER,
            onChange: adjustShotPointPercentages,
            className: 'shot-point-percent',
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'deep_water_points',
            id: 'deepWaterPoints',
            label: 'Deep Shot Points',
            dataType: FormFieldDataTypes.INTEGER,
            onChange: adjustShotPointPercentages,
            className: 'shot-point-percent',
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: NUMBER,
            name: 'record_length',
            id: 'recordLength',
            label: 'Record Length',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'fanning_percentage',
            id: 'fanningPercentage',
            label: 'Fanning Percentage',
            dataType: FormFieldDataTypes.INTEGER,
            validation: {
                required: false,
                validate: validateInteger,
                minValue: 0
            }
        },
        {
            type: SUBMIT,
            id: 'formSubmit',
            value: id ? 'Save survey' : 'Create survey',
            disabled: submitted && !errors && !message
        }
    ];

    fields.forEach(f => {
        if (Object.hasOwn(dynamicFieldValueGetter, f.name)) {
            f.value = dynamicFieldValueGetter[f.name];
        }
        f.disabled = notMarineAcquisition && marineOnlyNames.includes(f.name) ? true : f.disabled;
    });

    const coverInputWithShotPointPercent = (id, shotPercent) => {
        const input = document.getElementById(id);
        if (input.parentElement.children.length < 3) {
            const width = input.clientWidth;
            const height = input.clientHeight;
            const div = document.createElement('div');
            div.id = `${id}-display`;
            div.style.width = `${width-7}px`;
            div.style.height = `${height-7}px`;
            div.textContent = `${shotPercent}%`;
            input.parentElement.appendChild(div);
        }
    };

    useEffect(() => {
        fields.forEach(f => {
            if (Object.hasOwn(dynamicFieldValueSetter, f.name)) {
                dynamicFieldValueSetter[f.name](f.disabled ? null : values[f.name]);
            }
            if (!f.disabled && Object.hasOwn(shotPercents, f.name)) {
                coverInputWithShotPointPercent(f.id, shotPercents[f.name]);
            }
        });
    }, [fields]);

    (async() => {
        await import('../../../scss/components/form/_acquisition-form.scss');
    })();

    return (
        isModeratorOrAdministrator ?
            <Form
                fields={showLoading ? [<Loader key="loader"/>] : fields}
                onSubmit={onSubmit}
                errors={errors}
                message={message}>
            </Form>
            : <Error error={wording.accessDenied}/>
    );
};

FormAcquisition.propTypes = {
    ...WithWordingProps,
    storedData: PropTypes.shape({
        acquisition_project_name: PropTypes.string,
        id: PropTypes.number
    })
};



export default withWording('accessDenied')(FormAcquisition);
