import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormFieldTypes} from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector, projectSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import {closeModal} from '../../redux/route/routeActions';
import {stagesSelector} from '../../redux/lookups/lookupsSelectors';
import {saveStageData} from '../../redux/projects/projectsActions';

const {SUBMIT, CHECKBOX} = FormFieldTypes;

/**
 * This is for the context menu demo
 *
 * @param demoText
 * @returns {Element}
 * @constructor
 */

const valueReducer = (result, item) => {
    result[item.field_name] = item.values[0].value;
    return result;
};

const FormPostImagingProcessing = () => {
    const {errors} = useSelector(errorsSelector);
    const {message} = useSelector(errorMessageSelector);

    const {project: {id: projectId, stages: projectStages = []}} = useSelector(projectSelectorFromRouteId);
    const {stages} = useSelector(stagesSelector);


    const thisStage = stages.find(s => s.description === 'Post Imaging Processing'); //this one
    const projectStageData = projectStages.find(s => s.stage_id === thisStage.id);
    const values = projectStageData ? projectStageData.fields.reduce(valueReducer, {}) : {};

    const dispatch = useDispatch();
    const closeMe = () => dispatch(closeModal());
    const saveMe = data => dispatch(saveStageData(data, closeMe));

    const {
        imagingMerge, imagingDenoise, demultiple, stackMerge, stackDenoise
    } = values;

    const onSubmit = (e, data) => {
        e.preventDefault();
        const payload = {'fields': Object.entries(data).filter(e => e[1] === true)
            .map(([ key, value ]) =>
                ({'field_name': key, 'values': [{'value': value}]})
            )};
        saveMe({projectId, stageId: thisStage.id, ...payload});
    };

    const fields = [
        {
            type: CHECKBOX,
            name: 'imagingMerge',
            id: 'pim',
            label: 'Post Imaging Merge',
            size: 'full condensed',
            checked: imagingMerge,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'imagingDenoise',
            id: 'piDenoise',
            label: 'Post Imaging Denoise',
            size: 'full condensed',
            checked: imagingDenoise,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'demultiple',
            id: 'piDemultiple',
            label: 'Seabed SRME',
            size: 'full condensed',
            checked: demultiple,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'stackMerge',
            id: 'psm',
            label: 'Post Stack Merge',
            size: 'full condensed',
            checked: stackMerge,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'stackDenoise',
            id: 'psd',
            label: 'Post Stack Denoise',
            size: 'full condensed',
            checked: stackDenoise,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: SUBMIT,
            value: 'Edit stage'
        }
    ];

    return (
        <Form fields={fields} onSubmit={onSubmit} errors={errors} message={message}/>
    );
};

export default FormPostImagingProcessing;
