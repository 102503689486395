import '../../scss/components/table/_table.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Link from './router/Link';

import PlusIcon from '../../img/plus.svg';
import Ellipsis from './Ellipsis';
import FormField, {FormFieldShape, FormFieldTypes, ensureDateIsLocale} from './form/FormField';

const cellContent = (cell, {
    prefix='',
    suffix = '',
    ellipsis,
    transform
}) => {

    if (typeof transform === 'function')

        cell = transform(cell);
    let content;
    if (cell === null) {
        content = cell;
    }
    else {
        content = ensureDateIsLocale(cell);
    }
    if ( prefix ) return prefix+content;
    if ( suffix ) return content+suffix;
    if (ellipsis && content) return <Ellipsis text={content} max={ellipsis} />;
    return content;
};


const defaultSort = (a,b, column, order) => {
    if (a[column].content > b[column].content) return order > 0 ? -1 : 1;
    if (b[column].content > a[column].content) return order < 0 ? -1 : 1;
    return 0;
};

const computeRows = (rows, data = []) =>
    data.map(
        obj => ({
            cells: rows.map(key => ({
                content: obj[key]
            })),
            data: obj
        })
    );

const cellAlignmentClassFromColumn = ({ align }) => {
    switch(align) {
        case 'right':
            return 'align-right';
        case 'center':
            return 'align-center';
        default:
            return null;
    }
};

class Table extends React.Component {

    constructor(props) {
        super(props);

        let sortedColumn =props.defaultSortedRow ?
            props.rows.indexOf(props.defaultSortedRow) :
            0;
        if (sortedColumn < 0) sortedColumn = 0;

        this.state = {
            sortedColumn,
            order: 1,
            expanded: [],
            inlineEditing: []
        };
    }

    render() {
        const { addRow, columns, className } = this.props;
        return <div className={ `table ${className || ''}` }>
            {this.props.isProjectSummary ? null : (addRow && <button className="add-row" onClick={ addRow }><PlusIcon /></button>)}
            <table>
                <thead>
                    <tr>
                        { columns.map((col,i) =>
                            !col.hidden ?
                                <th key={i}>
                                    <button onClick={() => this.sortColumn(i)}>
                                        {col.name}
                                    </button>
                                </th> : null)}
                    </tr>
                </thead>
                { this.renderRows() }
            </table>
        </div>;
    }

    renderRows() {
        const { onRowClick } = this.props;
        const computedRows = this.getSorted();
        return <tbody>
            { computedRows.map(({ cells, data }, i) =>
                [
                    <tr key={i} className={ (this.isSelected(data, i) ? 'is-selected ' : '') + ( i%2 ? 'dark' : '') }
                        onClick={ onRowClick ? () => onRowClick(data, i) : null}>
                        { cells.map(({ content }, j) => this.renderCell(content, i, j, data, cells, computedRows)) }
                    </tr>,
                    data.tableExpandableContent
                        ? <tr key={`${i}-expandable`} className="table-expandable-row"
                            hidden={this.state.expanded.indexOf(data) < 0}>
                            <td className="table-expandable-cell" colSpan={this.props.columns.length}>
                                {
                                    typeof data.tableExpandableContent === 'function'
                                        ? data.tableExpandableContent(data)
                                        : data.tableExpandableContent
                                }
                            </td>
                        </tr>
                        : null
                ]
            )}
        </tbody>;
    }

    renderCell(content, i, j, data, cells, computedRows) {
        //if(this.props.columns[j].editable && content === '') content = ' '; //1.0.6 - Alternate Solution
        const { contextMenu } = this.props;
        const isExpanded = data.tableExpandableContent && this.isExpanded(data);

        return !this.props.columns[j].hidden ? <td key={j} className={cellAlignmentClassFromColumn(this.props.columns[j])}
            //className={ this.props.columns[j].editable && !this.props.isProjectSummary ? 'editable' : null }
            //onClick={ this.props.columns[j].editable && !this.props.isProjectSummary ? () => this.editCell(data, j, content) : null}
        >
            {
                j === 0 && data.tableExpandableContent &&
                <button className="expand-button" onClick={() => this.expand(data, !isExpanded)}>
                    { isExpanded ? '<'  : '>' }
                </button>
            }

            {
                this.isEditing(j, data)
                    ? this.renderEditable(content, i, j, data)
                    : this.renderCellContent(content, i, j, data)
            }

            { contextMenu && j === cells.length-1 && contextMenu(data, computedRows, this.props) }

        </td> : null;
    }

    isEditing(j, data) {
        // console.log(Object.keys(data));

        return this.props.columns[j].editable &&
            this.props.columns[j].editable.isEditing &&
            this.props.columns[j].editable.isEditing(data);
    }

    renderEditable(content, i, j, data) {
        const valueKey = this.props.columns[j].editable.valueKey;
        const val = valueKey ? data[valueKey] : content;

        if (this.props.columns[j].editable || this.props.columns[j].editable.type === FormFieldTypes.CHECKBOX || this.props.columns[j].editable.type === 'checkbox') {
            // console.log(JSON.stringify(this.props.columns[j]));
            if (content) {
                if (val) {
                    this.props.columns[j].editable.checked = true;
                } else {
                    this.props.columns[j].editable.checked = false;
                }
            }
        }

        return <FormField
            { ...this.props.columns[j].editable }
            autoFocus
            onChange={(e, value) => {
                if (this.props.columns[j].editable.type === 'select') {
                    const textValue = Array.from(e.target).filter(option => option.selected).pop().label;
                    return this.props.columns[j].editable.edit(data, value, textValue);
                }
                return this.props.columns[j].editable.edit(data, value);
            }}
            onBlur={(e, value) => value === val ? this.props.columns[j].editable.edit(data, undefined) : null}
            value={val} />;
    }

    renderCellContent(content, i, j, data) {
        const {rowLink} = this.props;
        if (rowLink && this.props.columns[j]?.hasLink) {
            const theLink = rowLink(data);
            const linkProps = typeof theLink === 'function' ?
                {onClick: theLink} : {href: theLink};
            return <Link {...linkProps}>
                {cellContent(content, this.props.columns[j])}
            </Link>;
        }

        return <span
            className={this.props.columns[j].editable && !this.props.isProjectSummary ? 'editable' : null}
            onClick={this.props.columns[j].editable && !this.props.isProjectSummary ? () => this.editCell(data, j, content) : null}
            title={content instanceof Date ? content.toDateString() : null}>
            {cellContent(content, this.props.columns[j])}
        </span>;
    }

    editCell(data, j, content) {
        const valueKey = this.props.columns[j].editable.valueKey;
        const value = valueKey ? data[valueKey] : content;

        if (this.props.columns[j].editable.type === 'select') {
            const textKey = this.props.columns[j].editable.textKey;
            const textValue = textKey ? data[textKey] : content;

            return this.props.columns[j].editable.edit(data, value, textValue);
        }

        return this.props.columns[j].editable.edit(data, value);

        //console.log("In editCell " + value + " " + Object.keys(data) + j + content + "|"+Object.values(this.props.columns[j].editable));
    }

    isExpanded(obj) {
        return this.state.expanded.indexOf(obj) > -1;
    }

    expand(obj, shouldExpand) {
        const expanded = this.state.expanded.concat();
        if (shouldExpand) expanded.push(obj);
        else expanded.splice(expanded.indexOf(obj), 1);
        this.setState({ expanded });
    }

    getSorted() {
        return computeRows(this.props.rows, this.props.data).sort( (a,b) =>
            this.props.columns[this.state.sortedColumn].sort
                ? this.props.columns[this.state.sortedColumn].sort(a.cells, b.cells, this.state.sortedColumn, this.state.order)
                : defaultSort(a.cells, b.cells, this.state.sortedColumn, this.state.order)
        );
    }

    sortColumn(i) {
        this.setState({
            sortedColumn: i,
            order: i === this.state.sortedColumn ? -this.state.order : 1
        });
    }

    isSelected(data, i) {
        if (!this.props.selectedRow) return false;
        return this.props.selectedRow(data, i);
    }
}

const editableShape = PropTypes.shape({
    ...FormFieldShape,
    isEditing: PropTypes.func,
    type: PropTypes.string,
    valueKey: PropTypes.string,
    textKey: PropTypes.string,
    checked: PropTypes.bool,
    edit: PropTypes.func
});


const columnShape = PropTypes.shape({
    name: PropTypes.string,
    sort: PropTypes.func,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    transform: PropTypes.func,
    editable: editableShape,
    hidden: PropTypes.bool,
    hasLink: PropTypes.bool
});

columnShape.defaultProps = {
    hidden: false
};

Table.propTypes = {
    addRow: PropTypes.func,
    className: PropTypes.string,
    isProjectSummary: PropTypes.bool,
    columns: PropTypes.arrayOf(columnShape),
    contextMenu: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object),
    onRowClick: PropTypes.func,
    selectedRow: PropTypes.func,
    rowLink: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.string),
    defaultSortedRow: PropTypes.string
};

export default Table;
