import '../../../scss/components/_filters.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import { omit } from 'lodash/fp';
import { filtersSelector } from '../../redux/route/routeSelectors';
import Form from '../form/Form';
import { setFilters } from '../../redux/route/routeActions';
import FormField, { FormFieldTypes } from '../form/FormField';
import CrossIcon from '../../../img/cross.svg';

class FiltersComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="filters" hidden={!this.props.isOpen}>

            <Form
                fields={[
                    {
                        id:'filters-add-key',
                        name:'key',
                        type: FormFieldTypes.SELECT,
                        options:[
                            { label: 'Select filter', value: ''},
                            ...this.props.filterables.map(({ label, value}) => ({ label, value }))
                        ]
                    },
                    {
                        type: FormFieldTypes.SUBMIT,
                        value:'Add Filter'
                    }
                ]}
                onSubmit={this.onSubmit.bind(this)} />

            <ul>
                { Object.keys(this.props.filters).map((f,i) =>
                    <li key={i}>
                        { this.props.filterList.find(({ value }) => value === f).label }:
                        <FormField
                            id={`filters-f-${i}`}
                            name={f}
                            type={FormFieldTypes.TEXT}
                            value={this.props.filters[f]}
                            onInput={this.onInput.bind(this)}/>
                        <button onClick={() => this.props.removeFilter(f)}><CrossIcon /></button>
                    </li>
                )}
            </ul>

        </div>;
    }

    onSubmit(e, { key }) {
        e.preventDefault();
        if (key) this.setFilter(key, '');
    }

    onInput(e) {
        this.setFilter(e.target.name, e.target.value);
    }

    setFilter(key, value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.props.setFilter(key, value), 500);
    }
}

FiltersComponent.propTypes = {
    filterables: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    filterList: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    filters: PropTypes.object,
    setFilter: PropTypes.func,
    removeFilter: PropTypes.func,
    isOpen: PropTypes.bool
};

const Filters = props => {
    const dispatch = useDispatch();
    const {filters} = useSelector(filtersSelector);

    const mappedProps = (({
        filterables,
        isOpen,
        setFilters
    }) => ({
        isOpen,
        filters,
        filterList: filterables,
        filterables: filterables.filter(({ value }) => typeof filters[value] === 'undefined'),
        setFilter: (key, value) => setFilters({ ...filters, [key]: value }),
        removeFilter: key => setFilters(omit(key)(filters))
    })
    )({
        ...props,
        setFilters: filters => dispatch(setFilters(filters))
    });

    return (
        <FiltersComponent {...mappedProps}/>
    );
};

export default Filters;
