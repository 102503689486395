import '../../../scss/components/_toolbar.scss';
import React from 'react';
import PropTypes from 'prop-types';

import Filters from './Filters';
import FilterIcon from '../../../img/filter.svg';
import withWording from '../HOC/withWording';

class Toolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filtersOpen: false
        };

        this.toggleFilters = this.toggleFilters.bind(this);
    }

    render() {
        return <div className="toolbar">

            <div className="toolbar-buttons">

                { this.props.title && <h1>{this.props.title}</h1>}

                <button className={`filters-button ${this.state.filtersOpen ? 'is-active' : ''}`} onClick={this.toggleFilters}>
                    { this.props.wording.filter } <FilterIcon />
                </button>
            </div>


            <Filters filterables={this.props.filters} isOpen={this.state.filtersOpen}/>

        </div>;
    }

    toggleFilters() {
        this.setState({
            filtersOpen: !this.state.filtersOpen
        });
    }

}
Toolbar.propTypes = {
    add: PropTypes.func,
    addWording: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    wording: PropTypes.object,
    title: PropTypes.string
};

export default withWording('filter')(Toolbar);
