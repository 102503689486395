import React from 'react';
import ContextMenu from './ContextMenu';

import PencilIcon from '../../../img/pencil.svg';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/route/routeActions';
import { MODALS } from '../modal/Modal';
import TrashIcon from '../../../img/trash.svg';
import PropTypes from 'prop-types';


const computePocTotal = (columns, rows, computedRows) => {
    let pocTotal = 0;
    computedRows.map(
        ({data}) => rows.map(key => {
            if (key === 'percent')
                pocTotal += data[key];
        }
        )
    );

    return pocTotal;
};

const PercentsMenu = ({taskId, percents, columns, rows, computedRows}) => {
    const dispatch = useDispatch();
    const editPercent = (columns, rows, computedRows) => {
        dispatch(openModal(MODALS.EDIT_PERCENTS, null,{ task: taskId , percents, milestoneTaskPocTotal: computePocTotal(columns, rows, computedRows)}));
    };
    const deletePercent = () => dispatch(openModal(MODALS.CONFIRM_DELETION, null, { percents_id: percents }));

    const buttons = [
        {
            IconComponent: PencilIcon,
            label: 'Edit',
            onClick: () => editPercent(columns, rows, computedRows)
        },
        {
            IconComponent: TrashIcon,
            label: 'Delete',
            onClick: deletePercent
        }
    ];

    return (
        <ContextMenu buttons={buttons} className="percent-menu"/>
    );
};

PercentsMenu.propTypes = {
    taskId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    percents: PropTypes.number,
    columns: PropTypes.array,
    rows: PropTypes.array,
    computedRows: PropTypes.array
};

export default PercentsMenu;
