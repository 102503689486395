import React from 'react';
import FormProjectPreprocessing from '../form/FormProjectPreprocessing';
import {useSelector} from 'react-redux';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalEditProjectPreprocessing = () => {
    const {project} = useSelector(projectSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Pre-Processing Stage for {project.name}</h2>
            <FormProjectPreprocessing />
        </div>
    );
};

export default ModalEditProjectPreprocessing;
