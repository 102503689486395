import React from 'react';
import PropTypes from 'prop-types';
import FormTask from '../form/FormTask';
import withRoute from '../HOC/withRoute';
import { taskSelectorFromId } from '../../redux/projects/projectsSelectors';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';

function getTaskCopy(src) {
    let target = {};
    if (src && Object.keys(src).length !== 0) {
        let prop;
        for (prop in src) {
            if (prop !== 'id') {
                if (Object.prototype.hasOwnProperty.call(src, prop)) {
                    target[prop] = src[prop];
                }
            }
        }
        target.isClone = true;
        return target;
    }
}

const ModalCloneTask = ({
    task,
    projectId
}) => <div>
    <h2>Clone task</h2>
    <FormTask projectId={projectId} values={getTaskCopy(task)}/>
</div>;

ModalCloneTask.propTypes = {
    task: PropTypes.any,
    projectId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

export default compose(
    withRoute,
    connect(
        (s, { queryParams: { task } }) => taskSelectorFromId(parseInt(task)),
        null,
        ({ task }, dispatchProps, { params }) =>  ({
            projectId: params.id,
            task
        })
    )
)(ModalCloneTask);
