import {
    addProject,
    addMilestone,
    addTask,
    addPercents,
    editProject,
    editMilestone,
    editTask,
    editPercents,
    cloneTask
} from './projects/projectsActions';
import { fetch } from './lookups/lookupsActions';
import { routeSelector } from './route/routeSelectors';
import { centerIdSelector, centersSelector } from './lookups/lookupsSelectors';
import { closeModal } from './route/routeActions';

// export const initialFetch = () => fetchLookups(null, dispatch => dispatch(fetchProjects()));
export const initialFetch = () => fetch();

export const addProjectAndCloseModal = data => addProject(data, dispatch => dispatch(closeModal()));
export const editProjectAndCloseModal = data => editProject(data, dispatch => dispatch(closeModal()));
export const addMilestoneAndCloseModal = data => addMilestone(data, dispatch => dispatch(closeModal()));
export const editMilestoneAndCloseModal = data => editMilestone(data, dispatch => dispatch(closeModal()));
export const addTaskAndCloseModal = data => addTask(data, dispatch => dispatch(closeModal()));
export const editTaskAndCloseModal = data => editTask(data, dispatch => dispatch(closeModal()));
export const addPercentsAndCloseModal = data => addPercents(data, dispatch => dispatch(closeModal()));
export const editPercentsAndCloseModal = data => editPercents(data, dispatch => dispatch(closeModal()));
export const cloneTaskAndCloseModal = data => cloneTask(data, dispatch => dispatch(closeModal()));

export const centerSelector = state => {
    let route = routeSelector(state);
    if (typeof(route.params) == 'undefined') {
        return {center: null};
    }
    let { center } = route.params;
    if (center) return {center: center.toString().replace(/#$/, '')};
    center = centersSelector(state).centers;
    return {center: center && center.length && center[0].description};
};

export const centerIdFromRoute = state => {
    let centerId = 0;
    let { center } = centerSelector(state);
    if (center) {
        centerId = centerIdSelector(center)(state).centerId;
    }

    return {centerId: centerId};
};
