import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isConnectedSelector, lastActiveTimeSelector} from '../../redux/user/userSelectors';
import {logout, setLastActiveTime} from '../../redux/user/userActions';
import config from '../../config';
import {useIdleTimer} from 'react-idle-timer';
import ModalIdleTimeout from '../modal/ModalIdleTimeout';

const IdleTimer = () => {
    const dispatch = useDispatch();
    const {isConnected} = useSelector(isConnectedSelector);
    const {lastActiveTime} = useSelector(lastActiveTimeSelector);

    const timeout = config.IDLE_TIMEOUT;
    const sessionTimeout = config.SESSION_TIMEOUT;
    const initialSecondsRemaining = 30;
    const promptTimeout = initialSecondsRemaining * 1000; // milliseconds
    const [ secondsRemaining, setSecondsRemaining ] = React.useState(initialSecondsRemaining);
    const [ , setIsIdle ] = React.useState(false);
    const [ showModal, setShowModal ] = React.useState(false);

    const handleOnActive = () => {
        if (!isConnected) return;

        setIsIdle(false);
        setSecondsRemaining(initialSecondsRemaining);
        updateLastActiveTime();
    };

    const handleOnIdle = () => {
        if (!isConnected) return;

        setIsIdle(true);
        if (showModal) handleLogout();
    };

    const handleOnPrompt = () => {
        // never show the timeout modal when we are not logged in
        setShowModal(isConnected);
    };

    const {reset} = useIdleTimer({
        timeout,
        promptTimeout: promptTimeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
        onPrompt: handleOnPrompt
    });

    const handleClose = () => {
        setShowModal(false);
        setSecondsRemaining(initialSecondsRemaining);
        reset();
    };

    const handleLogout = () => {
        setShowModal(false);
        reset();
        setSecondsRemaining(initialSecondsRemaining);
        logoutD();
    };

    const updateLastActiveTime = () => dispatch(setLastActiveTime(Date.now()));
    const logoutD = () => dispatch(logout(dispatch));
    const logoutIfTimedOut = timeoutInterval => {
        let currentTime = Date.now();
        if (isConnected && lastActiveTime > 0 &&
            currentTime > lastActiveTime + timeoutInterval)

            logoutD();
    };

    React.useEffect(() => {
        if (showModal) {
            const interval = setInterval(() => {
                setSecondsRemaining(secondsRemaining - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    });

    React.useEffect(() => logoutIfTimedOut(sessionTimeout), [lastActiveTime]);

    return (
        <ModalIdleTimeout showModal={showModal} handleClose={handleClose} handleLogout={handleLogout}
            secondsRemaining={secondsRemaining}/>
    );
};

export default IdleTimer;
