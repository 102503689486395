import React from 'react';
import PropTypes from 'prop-types';

const WithClickOutside = WrappedComponent => {
    const ComponentWrappedWithClickOutside = props => {
        const wrapperRef = React.useRef(null);
        const callback = React.useRef(null);

        const setRef = node => {
            wrapperRef.current = node;
        };

        const registerCallback = cb => {
            callback.current = cb;
        };

        const handleClickOutside = e => {
            const classNameCheck = e.target.parentElement?.className.toString() ||
                e.target.parentElement?.firstElementChild?.className.toString() || '';
            if (!classNameCheck.startsWith('ant-select') && !classNameCheck.startsWith('rc-virtual-list') &&
                wrapperRef.current && !wrapperRef.current.contains(e.target)) {

                if (typeof callback.current === 'function') callback.current();
                else {
                    /*eslint-disable */
                    console.warn('withClickOutside requires a callback');
                    /*eslint-enable */
                }
            }
        };

        React.useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);

            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, []);

        return (
            <WrappedComponent {...props} clickOutsideRef={setRef} onClickOutside={registerCallback} />
        );
    };

    return ComponentWrappedWithClickOutside;
};

WithClickOutside.propTypes = {
    WrappedComponent: PropTypes.func
};

export default WithClickOutside;

