import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormProjectAcquisitionChooser from '../form/FormProjectAcquisitionChooser';
import { acquisitionsSelector } from '../../redux/acquisitions/acquisitionsSelectors';
import { fetch as fetchAcquisitions } from '../../redux/acquisitions/acquisitionsActions';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalManageProjectAcquisitions = () => {
    const dispatch = useDispatch();
    const {project} = useSelector(projectSelectorFromRouteId);
    
    useEffect(() => {
        dispatch(fetchAcquisitions());
    }, [dispatch]);

    const acquisitions = useSelector(acquisitionsSelector);

    return (
        <div>
            <h2>Manage Surveys for {project.name}</h2>
            {acquisitions ? <FormProjectAcquisitionChooser project={project} data={acquisitions} /> : <p>Loading data...</p>}
        </div>
    );
};

export default ModalManageProjectAcquisitions;
