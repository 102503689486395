import React from 'react';
import {useDispatch} from 'react-redux';

import ContextMenu from './ContextMenu';

import PencilIcon from '../../../img/pencil.svg';
import FileDownloadIcon from '../../../img/file-download.svg';
import RightArrowIcon from '../../../img/right-arrow.svg';
import polygonIcon from '../../../img/survey-polygon.png';
import downloadRowsAsCsv from '../../helpers/downloadRowsAsCsv';
import {MODALS} from '../modal/Modal';
import {openModal} from '../../redux/route/routeActions';
import PropTypes from 'prop-types';

const PolygonIcon = () => <img src={polygonIcon} alt="polygon" width="24" height="24"/>;

const exportToCsv = (center, columns, rows, computedRows) => {
    const fileName = `projects-${center}`;
    const table = [columns.map(({name}) => name)];
    const tableRows = computedRows.map(({data}) =>
        rows.map(key =>
            data[key] instanceof Date
                ? data[key].toLocaleDateString()
                : data[key]
        )
    );

    table.push(...tableRows);

    return downloadRowsAsCsv(table, fileName);
};

const ProjectMenu = ({project, center, columns, rows, computedRows}) => {
    const dispatch = useDispatch();
    const editProject = () =>
        dispatch(openModal(MODALS.EDIT_PROJECT, null, {project}));
    const editPreProcessing = () =>
        dispatch(
            openModal(MODALS.EDIT_PROJECT_PREPROCESSING, null, {project})
        );
    const editDemultiple = () =>
        dispatch(openModal(MODALS.EDIT_DEMULTIPLE, null, {project}));
    const editRegularization = () =>
        dispatch(openModal(MODALS.EDIT_REGULARIZATION, null, {project}));
    const editPostImaging = () =>
        dispatch(openModal(MODALS.EDIT_POST_IMAGING, null, {project}));
    const editModelBuilding = () =>
        dispatch(openModal(MODALS.EDIT_MODEL_BUILDING, null, {project}));
    const editImaging = () => dispatch(openModal(MODALS.EDIT_IMAGING, null, {project}));
    const acquisitions = () => dispatch(openModal(MODALS.MANAGE_PROJECT_ACQUISITIONS, null, {project}));

    const buttons = [
        {
            IconComponent: PencilIcon,
            label: 'Edit',
            onClick: editProject
        },
        {
            IconComponent: FileDownloadIcon,
            label: 'Export to CSV',
            onClick: () => exportToCsv(center, columns, rows, computedRows)
        },
        {
            IconComponent: PolygonIcon,
            label: 'Surveys',
            onClick: acquisitions
        },
        {
            IconComponent: RightArrowIcon,
            label: 'Project stages',
            onClick: editDemultiple,
            children: [
                {
                    IconComponent: RightArrowIcon,
                    label: 'Pre-Processing stage',
                    onClick: editPreProcessing
                },
                {
                    IconComponent: RightArrowIcon,
                    label: 'Demultiple stage',
                    onClick: editDemultiple
                },
                {
                    IconComponent: RightArrowIcon,
                    label: 'Regularization stage',
                    onClick: editRegularization
                },
                {
                    IconComponent: RightArrowIcon,
                    label: 'Model Building',
                    onClick: editModelBuilding
                },
                {
                    IconComponent: RightArrowIcon,
                    label: 'Imaging',
                    onClick: editImaging
                },
                {
                    IconComponent: RightArrowIcon,
                    label: 'Post-Processing stage',
                    onClick: editPostImaging
                }
            ]
        }
    ];

    return <ContextMenu buttons={buttons} className="project-menu"/>;
};

ProjectMenu.propTypes = {
    project: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    center: PropTypes.string,
    columns: PropTypes.array,
    rows: PropTypes.array,
    computedRows: PropTypes.array
};

export default ProjectMenu;
