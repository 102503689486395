import React from 'react';
import PropTypes from 'prop-types';
import Rows from '../../../img/rows.svg';

const AccordionMenu = ({ accordionButtons, toggleMenu, accordianTitle, isContextMenuOpen }) => {
    const [ accordionOpen, setAccordionOpen ] = React.useState(false);

    React.useEffect(() => {
        if (!isContextMenuOpen) {
            setAccordionOpen(false);
        }
    }, [isContextMenuOpen]);

    const toggleAccordion = () => setAccordionOpen(!accordionOpen);

    return (
        <>
            {accordionButtons?.length > 0 && (
                <li className="accordion">
                    <button onClick={toggleAccordion} className="accordion-toggle">
                        <Rows /> <span>{accordianTitle || 'Accordion Menu'} </span>
                    </button>
                    {accordionOpen && (
                        <div className="accordion-content">
                            {accordionButtons.map(({ onClick, label }, i) => (
                                <div key={i} className="accordion-item">
                                    <button className="accordion-button" onClick={() => { onClick(); toggleMenu(false); }}>
                                        <span>{label}</span>
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </li>
            )}
        </>
    );
};

AccordionMenu.propTypes = {
    accordionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            IconComponent: PropTypes.func,
            label: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    accordianTitle: PropTypes.string,
    toggleMenu: PropTypes.func.isRequired,
    isContextMenuOpen: PropTypes.bool.isRequired // New prop
};

export default AccordionMenu;
