import React from 'react';
import {FormFieldDataTypes, FormFieldTypes, validateInteger} from './FormField';
import { useSelector, useDispatch } from 'react-redux';
import { isUndefined } from 'lodash/fp';
import Form from './Form';
import {
    areasSelector,
    bidsSelector,
    centersSelector,
    projectTypesSelector,
    usersSelector,
    unitTypesSelector,
    surveyTypesSelector,
    projectGroupsSelector,
    clientsSelector,
    acquisitionTypesSelector
} from '../../redux/lookups/lookupsSelectors';
import { addProjectAndCloseModal, editProjectAndCloseModal } from '../../redux/common';
import {errorMessageSelector, errorsSelector} from '../../redux/projects/projectsSelectors';
import {byLabelCaseInsensitive} from '../../helpers/sort';

const { CHECKBOX, HIDDEN, NUMBER, SELECT, SUBMIT, TEXT, TEXTAREA } = FormFieldTypes;

const FormProject = values => {
    // Why do I have to do this? ¬
    values = values.values ? values.values : values;
    const dispatch = useDispatch();

    const {areas} = useSelector(areasSelector);
    const {bids} = useSelector(bidsSelector);
    const {centers} = useSelector(centersSelector);
    const {projectTypes} = useSelector(projectTypesSelector);
    const {users} = useSelector(usersSelector);
    const {unitTypes} = useSelector(unitTypesSelector);
    const {surveyTypes} = useSelector(surveyTypesSelector);
    const {projectGroups} = useSelector(projectGroupsSelector);
    const {clients} = useSelector(clientsSelector);
    const {acquisitionTypes} = useSelector(acquisitionTypesSelector);

    const {errors} = useSelector(errorsSelector);
    const {message} = useSelector(errorMessageSelector);

    const addProject = data => dispatch(addProjectAndCloseModal(data));
    const editProject = data => dispatch(editProjectAndCloseModal(data));
    const onSubmit = (e, data) => {
        e.preventDefault();
        values.name ? editProject(data) : addProject(data);
    };

    const fields = [
        values ? {
            type: HIDDEN,
            name: 'id',
            id: 'project-id',
            value: values.id,
            dataType: FormFieldDataTypes.NUMBER
        } : null,
        {
            type: TEXT,
            name: 'project_name',
            id: 'project-name',
            label: 'Project name',
            size: 'half',
            className: 'upper-case',
            value: values.name && values.name.toUpperCase(),
            validation: {
                required: true
            }
        },
        {
            type: SELECT,
            name: 'project_group_id',
            id: 'project-group',
            label: 'Project group',
            size: 'half',
            value: values.projectGroupId,
            labelAsDefault: true,
            options: projectGroups.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: TEXTAREA,
            name: 'description',
            id: 'project-description',
            label: 'Description',
            size: 'full',
            value: values.description,
            break: true,
            validation: {
                required: true
            }
        },
        {
            type: SELECT,
            name: 'project_type_id',
            id: 'project-type',
            label: 'Project type',
            labelAsDefault: true,
            value: values.projectTypeId,
            options: projectTypes.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'area_id',
            id: 'project-area',
            label: 'Project area',
            labelAsDefault: true,
            value: values.areaId,
            options: areas.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'bid_id',
            id: 'project-bid',
            label: 'Project bid',
            labelAsDefault: true,
            value: values.bidId,
            options: bids.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'center_id',
            id: 'project-center',
            label: 'Project center',
            labelAsDefault: true,
            value: values.centerId,
            options: centers.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'client_id',
            id: 'project-client',
            label: 'Client',
            size: 'half',
            value: values.clientId,
            labelAsDefault: true,
            options: clients.map(({ id, description }) => ({
                label: description,
                value: id
            })).sort(byLabelCaseInsensitive),
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'supervisor_id',
            id: 'milestone-supervisor-id',
            label: 'Supervisor',
            labelAsDefault: true,
            value: values.supervisorId,
            options: users.map(({ description, id }) => ({
                label: description,
                value: id
            })),
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'survey_size',
            id: 'project-survey-size',
            label: 'Survey size',
            value: values.surveySize,
            validation: {
                required: true,
                validate: validateInteger
            },
            dataType: FormFieldDataTypes.NUMBER,
            break: true
        },
        {
            type: SELECT,
            name: 'unit_type_id',
            id: 'project-unit-type',
            label: 'Unit type',
            labelAsDefault: true,
            value: values.unitTypeId,
            options: unitTypes.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'input_size',
            id: 'project-input-size',
            label: 'Input dataset size in TB',
            value: values.inputSize,
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: true,
                validate: validateInteger
            },
            break: true
        },
        {
            type: NUMBER,
            name: 'azimuths',
            id: 'project-azimuths',
            label: 'Azimuths',
            value: isUndefined(values.azimuths) ? 1 : values.azimuths,
            validation: {
                required: true,
                validate: validateInteger
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'survey_type_id',
            id: 'project-survey-type',
            label: 'Survey type',
            labelAsDefault: true,
            value: values.surveyTypeId,
            options: surveyTypes.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            size: 'half',
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'acquisition_type_id',
            id: 'project-acquisition-type',
            label: 'Acquisition type',
            size: 'half',
            value: values.acquisitionTypeId,
            labelAsDefault: true,
            options: acquisitionTypes.map(({ id, description }) => ({
                label: description,
                value: id
            })),
            validation: {
                required: true
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: CHECKBOX,
            name: 'active',
            id: 'project-active',
            label: 'Active',
            checked: isUndefined(values.active) ? true : values.active,
            dataType: FormFieldDataTypes.BOOL,
            break: true
        },
        {
            type: SUBMIT,
            value: values.name ? 'Save project' : 'Create project'
        }
    ];

    return (
        <Form fields={fields} onSubmit={onSubmit} errors={errors} message={message}/>
    );
};

export default FormProject;
