import '../../../scss/components/projects/_cards.scss';
import React from 'react';
import PropTypes from 'prop-types';

import ProjectCard from './ProjectCard';
import MilestoneCard from './milestones/MilestoneCard';
import TaskCard from './tasks/TaskCard';
import PercentCard from './percents/PercentCard';

const renderCard = (type, data = {}, onClick, i, selectedId) => {
    let card;
    const props = {
        ...data,
        selectedId,
        onClick,
        isSelected: typeof data.id !== 'undefined' && data.id === selectedId
    };

    switch(type) {
        case 'project':
            card = <ProjectCard {...props} />;
            break;
        case 'milestone':
            card = <MilestoneCard {...props} />;
            break;
        case 'task':
            card = <TaskCard {...props}/>;
            break;
        case 'percent':
            card = <PercentCard {...props}/>;
            break;
    }

    return <li key={i}>
        {card}
    </li>;
};

const Cards = ({ list, type, onClick, selectedId }) =>
    <ul className="cards">
        { list.map((data,i) =>  renderCard(type, data, onClick, i, selectedId))}
    </ul>;

Cards.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
    onClick: PropTypes.func,
    selectedId: PropTypes.number
};

export default Cards;
