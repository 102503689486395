import asyncAction, {asyncActionQueue} from '../../helpers/asyncAction';
import config from '../../config';

export const PROJECTS = 'PROJECTS';
export const FETCH = `${PROJECTS}/FETCH`;
export const FETCH_SINGLE = `${PROJECTS}/FETCH_SINGLE`;
export const SET_FILTER = `${PROJECTS}/SET_FILTER`;
export const ADD_PROJECT = `${PROJECTS}/ADD_PROJECT`;
export const EDIT_PROJECT = `${PROJECTS}/EDIT_PROJECT`;
export const DELETE_PROJECT = `${PROJECTS}/DELETE_PROJECT`;
export const ADD_MILESTONE = `${PROJECTS}/ADD_MILESTONE`;
export const EDIT_MILESTONE = `${PROJECTS}/EDIT_MILESTONE`;
export const DELETE_MILESTONE = `${PROJECTS}/DELETE_MILESTONE`;
export const ADD_TASK = `${PROJECTS}/ADD_TASK`;
export const EDIT_TASK = `${PROJECTS}/EDIT_TASK`;
export const CLONE_TASK = `${PROJECTS}/ADD_TASK`; //Reuse ADD_TASK as action
export const DELETE_TASK = `${PROJECTS}/DELETE_TASK`;
export const ADD_PERCENTS = `${PROJECTS}/ADD_PERCENTS`;
export const EDIT_PERCENTS = `${PROJECTS}/EDIT_PERCENTS`;
export const DELETE_PERCENTS = `${PROJECTS}/DELETE_PERCENTS`;
export const INLINE_EDIT_PROJECT = `${PROJECTS}/INLINE_EDIT_PROJECT`;
export const INLINE_EDIT_MILESTONE = `${PROJECTS}/INLINE_EDIT_MILESTONE`;
export const INLINE_EDIT_TASK = `${PROJECTS}/INLINE_EDIT_TASK`;
export const INLINE_EDIT_PERCENTS = `${PROJECTS}/INLINE_EDIT_PERCENTS`;
export const VALIDATE_INLINE_EDITS = `${PROJECTS}/VALIDATE_INLINE_EDITS`;
export const CANCEL_INLINE_EDITS = `${PROJECTS}/CANCEL_INLINE_EDITS`;
export const SAVE_STAGE_DATA = `${PROJECTS}/SAVE_STAGE_DATA`;
export const STORE_LINKED_ACQUISITION = `${PROJECTS}/STORE_LINKED_ACQUISITION`;
export const STORE_UNLINKED_ACQUISITION = `${PROJECTS}/STORE_UNLINKED_ACQUISITION`;

export const saveStageData = ({projectId, stageId, ...data}, cb) => asyncAction(SAVE_STAGE_DATA, {
    method: 'PUT',
    url: config.WEBSERVICES.PROJECT + projectId + '/stage/' + stageId
})({projectId, stageId, ...data}, cb);

export const storeLinkedAcquisition = (projectId, acquisition) => ({
    type: STORE_LINKED_ACQUISITION,
    projectId,
    acquisition
});

export const storeUnlinkedAcquisition = (projectId, acquisitionId) => ({
    type: STORE_UNLINKED_ACQUISITION,
    projectId,
    acquisitionId
});

export const fetch = (centerId, cb) => asyncAction(FETCH, {
    url: config.WEBSERVICES.PROJECT_COMPLEX + ((centerId) ? '/center_id/' + centerId : '')
})({ centerId }, cb);

export const fetchSingle = ({
    id,
    ...data
} = {}, cb) => asyncAction(FETCH_SINGLE, {
    url: config.WEBSERVICES.PROJECT_COMPLEX + '/id/' +id
})({ id, ...data }, cb);

export const addProject = asyncAction(ADD_PROJECT, {
    method: 'POST',
    url: config.WEBSERVICES.PROJECT
});

export const editProject = ({
    id,
    ...data
} = {}, cb) => asyncAction(EDIT_PROJECT, {
    method: 'PUT',
    url: config.WEBSERVICES.PROJECT+id
})({ id, ...data }, cb);

export const deleteProject = (id, cb) => asyncAction(DELETE_PROJECT, {
    method: 'DELETE',
    url: config.WEBSERVICES.PROJECT+id
})({ id }, cb);

export const addTask = asyncAction(ADD_TASK, {
    method: 'POST',
    url: config.WEBSERVICES.TASK
});

export const cloneTask = asyncAction(CLONE_TASK, {
    method: 'POST',
    url: config.WEBSERVICES.TASK

});

export const editTask = ({
    id,
    ...data
} = {}, cb) => asyncAction(EDIT_TASK, {
    method: 'PUT',
    url: config.WEBSERVICES.TASK+id
})({ id, ...data }, cb);

export const deleteTask = (id, cb) => asyncAction(DELETE_TASK, {
    method: 'DELETE',
    url: config.WEBSERVICES.TASK+id
})({ id }, cb);

export const addMilestone = asyncAction(ADD_MILESTONE, {
    method: 'POST',
    url: config.WEBSERVICES.MILESTONE
});

export const editMilestone = ({
    id,
    ...data
} = {}, cb) => asyncAction(EDIT_MILESTONE, {
    method: 'PUT',
    url: config.WEBSERVICES.MILESTONE+id
})({ id, ...data }, cb);

export const deleteMilestone = (id, cb) => asyncAction(DELETE_MILESTONE, {
    method: 'DELETE',
    url: config.WEBSERVICES.MILESTONE+id
})({ id }, cb);

export const addPercents = asyncAction(ADD_PERCENTS, {
    method: 'POST',
    url: config.WEBSERVICES.PERCENTS
});

export const editPercents = ({
    id,
    ...data
} = {}, cb) => asyncAction(EDIT_PERCENTS, {
    method: 'PUT',
    url: config.WEBSERVICES.PERCENTS+id
})({ id, ...data }, cb);

export const deletePercents = (id, cb) => asyncAction(DELETE_PERCENTS, {
    method: 'DELETE',
    url: config.WEBSERVICES.PERCENTS+id
})({ id }, cb);

export const setFilter = filter => ({
    type: SET_FILTER,
    filter
});

export const inlineEditProject = (data, value, key) => ({
    type: INLINE_EDIT_PROJECT,
    data,
    value,
    key
});

export const inlineEditMilestone = (data, value, key) => ({
    type: INLINE_EDIT_MILESTONE,
    data,
    value,
    key
});

export const inlineEditTask = (data, value, key) => ({
    type: INLINE_EDIT_TASK,
    data,
    value,
    key
});

export const inlineEditPercents = (data, value, key) => ({
    type: INLINE_EDIT_PERCENTS,
    data,
    value,
    key
});

export const validateInlineEdits = state => {
    const queue = [];

    const projects = Object.keys(state.PROJECTS.inlineEdits.projects);
    if (projects.length) {
        projects.forEach(
            id => queue.push({
                action: editProject,
                payload: {
                    id: parseInt(id),
                    ...state.PROJECTS.inlineEdits.projects[id]
                }
            })
        );
    }

    const milestones = Object.keys(state.PROJECTS.inlineEdits.milestones);
    if (milestones.length) {
        milestones.forEach(
            id => queue.push({
                action: editMilestone,
                payload: {
                    id: parseInt(id),
                    ...state.PROJECTS.inlineEdits.milestones[id]
                }
            })
        );
    }

    const tasks = Object.keys(state.PROJECTS.inlineEdits.tasks);
    if (tasks.length) {
        tasks.forEach(
            id => queue.push({
                action: editTask,
                payload: {
                    id: parseInt(id),
                    ...state.PROJECTS.inlineEdits.tasks[id]
                }
            })
        );
    }

    const percents = Object.keys(state.PROJECTS.inlineEdits.percents);
    if (percents.length) {
        percents.forEach(
            id => queue.push({
                action: editPercents,
                payload: {
                    id: parseInt(id),
                    ...state.PROJECTS.inlineEdits.percents[id]
                }
            })
        );
    }

    return asyncActionQueue(VALIDATE_INLINE_EDITS, queue);

};


export const cancelInlineEdits = () => ({
    type: CANCEL_INLINE_EDITS
});
