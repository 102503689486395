import React from 'react';
import PropTypes from 'prop-types';
import Button from '../base/Button';

const ModalIdleTimeout = ({showModal, handleClose, handleLogout, secondsRemaining}) => (showModal) ?
    <div className="modal-wrapper">
        <div className="modal">
            <div>
                <h2>You Have Been Idle!</h2>
                <p>You will be automatically logged out in {secondsRemaining} seconds. Would you like to stay?</p>
                <p>&nbsp;</p>

                <div className="button-row">
                    <Button variant="danger" onClick={handleLogout}>
                Logout
                    </Button>
                    <Button className="is-white" variant="primary" onClick={handleClose}>
                Stay
                    </Button>
                </div>
            </div>
        </div>
    </div> : null;

ModalIdleTimeout.propTypes = {
    showModal: PropTypes.bool,
    handleClose: PropTypes.func,
    handleLogout: PropTypes.func,
    secondsRemaining: PropTypes.number
};

export default ModalIdleTimeout;
