import '../../../scss/views/_project-detail.scss';
import '../../../scss/components/modal/_modal.scss';
import '../../../scss/components/form/_form-field.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Projects from '../../components/projects/Projects';
import Milestones from '../../components/projects/milestones/Milestones';
import Tasks from '../../components/projects/tasks/Tasks';
import Percents from '../../components/projects/percents/Percents';
import ProjectsSideMenu from '../../components/sideMenu/ProjectsSideMenu';
import { routeShape } from '../../components/router/Router';
import { projectShape } from '../../components/projects/propTypes';
import {  XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalRectSeries,
    Highlight
} from 'react-vis';
import {getTaskColumns, getTaskRows} from '../../helpers/taskRows';
import {isModeratorOrAdministratorSelector} from '../../redux/user/userSelectors';
import downloadRowsAsCsv from '../../helpers/downloadRowsAsCsv';
import {useSelector} from 'react-redux';
import {isLoadedSelector} from '../../redux/projects/projectsSelectors';
import Loader from '../../components/base/Loader';


const usePreviousPersistent = value => {
    // initialise the ref with previous and current values
    const ref = React.useRef({
        value: value,
        prev: []
    });

    const current = ref.current.value;

    // if the value passed into hook doesn't match what we store as "current"
    // move the "current" to the "previous"
    // and store the passed value as "current"
    if (value !== current) {
        ref.current = {
            value: value,
            prev: current
        };
    }

    // return the previous value only
    return ref.current.prev;
};

const computeRows = (rows, taskData, milestoneName) => {
    let rowValues = [];

    for (let j = 1; j < rows.length; j++) {
        const key = rows[j];
        if (key === 'milestone') {
            rowValues.push(milestoneName);
        } else if (key === 'resource') {
            rowValues.push(taskData['computeResource']);
        } else {
            const keyValue = taskData[key];
            keyValue instanceof Date ? rowValues.push(keyValue.toLocaleDateString()) : rowValues.push(keyValue);
        }
    }

    return rowValues;
};

function invokeTaskExport(myMilestones) {

    const params = window.location.href.split('/');
    const pos = params.indexOf('project');
    let center='undefined';
    let project='undefined';
    if(pos === 5) {
        center = params[pos - 1];
        project = params[pos + 1];
    }

    const fileName = `milestone-task-export-${project}-${center}`;

    //Get column
    const rows = getTaskRows(isModeratorOrAdministratorSelector);
    //exportRows.shift(); //Remove first i.e. 'status' column

    const columns = getTaskColumns(isModeratorOrAdministratorSelector, [], [], [], false, false);
    //exportColumns.shift(); //Remove first i.e. 'status' column

    /*
    const table = [
        columns.map(({name}) => name)
    ];
     */

    let colValues = [];
    for (let j = 1; j < columns.length; j++) {
        colValues.push(columns[j].name);
    }

    const table = [colValues];

    //Map values
    let tableRows = [];
    for (let i = 0; i < myMilestones.length; i++) {
        const mName = myMilestones[i].name;
        for(let j = 0; j < myMilestones[i].tasks.length; j++) {
            tableRows.push(computeRows(rows, myMilestones[i].tasks[j], mName));
        }
    }

    table.push(...tableRows);
    downloadRowsAsCsv(table, fileName);
}

const ProjectDetail = ({project, milestones, tasks, route, lastAddedTask}) => {
    const [ selectedTaskId, setSelectedTaskId ] = React.useState(null);
    const [ selectedMilestoneId, setSelectedMilestoneId ] = React.useState(null);
    const [ lastDrawLocation, setLastDrawLocation ] = React.useState(null);

    const prevMilestones = usePreviousPersistent(milestones);
    const prevTasks = usePreviousPersistent(tasks);

    const {isLoaded} = useSelector(isLoadedSelector);

    const selectTask = index => setSelectedTaskId(index);

    const selectMilestone = index => {
        const milestone = project.milestones.find(({id}) => id === index);

        selectTask(milestone.tasks.length ? milestone.tasks[0].id : -1);
        setSelectedMilestoneId(index);
    };

    var dataForCharts;
    var withColor;
    const selectedMilestone = milestones.find(({id}) => id === selectedMilestoneId);
    const selectedTask = tasks.find(({id}) => id === selectedTaskId);
    const ONEDAY = 86400000;

    const milestoneLevelSeries = (typeof project !== 'undefined') && (project.milestones.map(function(i) {
        return {
            x0: i.milestoneStartDate,
            x: i.milestoneEndDate,
            y: i.milestoneProjectedComputeHours / ((i.milestoneEndDate - i.milestoneStartDate) / ONEDAY + 1),
            name: i.name
        };
    })) || [];

    const taskLevelSeries = (typeof selectedMilestone !== 'undefined') && (selectedMilestone.tasks.map(function(j) {
        return {
            x0: j.projStartDate,
            x: j.projEndDate,
            y: j.projectedComputeHours / ((j.projEndDate - j.projStartDate) / ONEDAY + 1),
            name: j.name
        };
    })) || [];

    const reloadPage = () => {
        window.location.reload();
    };

    React.useEffect(() => {
        if (milestones.length) {
            if (milestones.length !== prevMilestones.length || selectedMilestoneId === -1) {
                setSelectedMilestoneId(milestones[0].id);
            }
        } else {
            setSelectedMilestoneId(-1);
        }

        if (tasks.length > prevTasks.length) {
            setSelectedTaskId(lastAddedTask ? lastAddedTask : tasks[0].id);
        } else if (tasks.length < prevTasks.length) {
            setSelectedTaskId(-1);
        }
    }, [ milestones, tasks ]);

    if (selectedTask) {
        dataForCharts = taskLevelSeries;
        withColor = dataForCharts.map(d => ({...d, color: Number(d.name !== selectedTask.name)}));
    } else if (selectedMilestone) {
        dataForCharts = taskLevelSeries;
        withColor = dataForCharts.map(d => ({...d, color: Number(d.name !== selectedMilestone.name)}));
    } else {
        dataForCharts = milestoneLevelSeries;
        withColor = milestoneLevelSeries;
    }
    return isLoaded ? <div className="main" id="view-project-detail">
        <div id="project-detail-content">
            <ProjectsSideMenu/>
            <main>
                <div>
                    <div>
                        <h1>Project detail</h1>
                    </div>
                    <div className="project-nav-div">
                        <button className="export-button" type="button"
                            title="Export all tasks belonging to project"
                            onClick={() => {
                                invokeTaskExport(milestones);
                            }}>
                            Export Tasks
                        </button>
                        <div className="divider"/>
                        <button className=
                            "reload-button" type="button" title="Reload Project Data"
                        onClick={reloadPage}>
                            Reload Project Data
                        </button>
                    </div>
                </div>
                <Projects
                    list={[project]}
                    center={route.params.center.toUpperCase()}
                    noMilestones={true}
                    isProjectSummary={false}/>

                <Milestones
                    list={milestones}
                    onClick={selectMilestone}
                    selectedMilestoneId={selectedMilestoneId}
                    projectId={project.id}/>

                {selectedMilestone && <h2>Tasks for {selectedMilestone.name}</h2>}
                <Tasks
                    onClick={selectTask}
                    selectedMilestoneId={selectedMilestoneId}
                    selectedTaskId={selectedTaskId}
                    list={tasks.filter(({milestoneId}) => milestoneId === selectedMilestoneId)}/>

                {selectedTask && <h2>PoC for {selectedTask.name}</h2>}
                <Percents task={selectedTask}/>
                <hr/>
                {tasks.length > 0 && (<h3 style={{marginTop: '20px'}}>Chart for Project Compute Hours</h3>)}
                {tasks.length > 0 && (<XYPlot
                    xType="time"
                    width={1100}
                    height={550}
                    colorRange={[ 'rgba(85,22,136,0.25)', 'rgba(200,200,200,0.25)' ]}
                    animation
                    xDomain={
                        lastDrawLocation && [
                            lastDrawLocation.left,
                            lastDrawLocation.right
                        ]
                    }
                    yDomain={
                        lastDrawLocation && [
                            lastDrawLocation.bottom,
                            lastDrawLocation.top
                        ]
                    }
                >
                    <VerticalGridLines/>
                    <HorizontalGridLines/>
                    <XAxis style={{fontSize: '8px'}}/>
                    <YAxis width={100} style={{fontSize: '10px'}}/>
                    <VerticalRectSeries data={withColor}/>
                    <Highlight
                        onBrushEnd={area => setLastDrawLocation(area)}
                        onDrag={area => {
                            setLastDrawLocation({
                                bottom: lastDrawLocation.bottom + (area.top - area.bottom),
                                left: lastDrawLocation.left - (area.right - area.left),
                                right: lastDrawLocation.right - (area.right - area.left),
                                top: lastDrawLocation.top + (area.top - area.bottom)
                            });
                        }}
                    />
                </XYPlot>)}
                {lastDrawLocation && (
                    <button
                        style={{float: 'right', border: '1px solid #f2f2f2'}}
                        onClick={() => setLastDrawLocation(null)}>
                        Reset Zoom
                    </button>
                )}
            </main>
        </div>
    </div> : <Loader/>;

};

ProjectDetail.propTypes = {
    route: routeShape,
    project: projectShape,
    tasks: PropTypes.any,
    milestones: PropTypes.any,
    lastAddedTask: PropTypes.number
};
export default ProjectDetail;
