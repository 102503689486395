const acquisitionColumns = () => [
    { name: '' },
    { name: 'Acquisition Name', hasLink: true },
    { name: 'Acquisition Type' },
    { name: 'PGS Acquisition Code'},
    { name: 'Start Date' },
    { name: 'End Date' }
];

export const getAcquisitionColumns = () => acquisitionColumns();

const acquisitionRows = [
    '',
    'acquisition_project_name',
    'acquisition_category',
    'pgs_acquisition_code',
    'start_date',
    'end_date'
];

export const getAcquisitionRows = () => acquisitionRows;

