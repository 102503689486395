import '../../../scss/components/projects/_card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Ellipsis from '../Ellipsis';


const pocClass = poc => {
    if (poc > 60) return 'is-green';
    if (poc < 40) return 'is-red';
    return '';
};

const Card = ({
    title,
    description,
    onClick,
    soldRevenue,
    supervisor,
    percentCompleted,
    surveySize,
    isSelected
}) =>
    <div className={`card ${isSelected ? 'is-selected' : ''}`}>
        <div onClick={onClick} role="button">
            <header>
                <h3>
                    <span>{ title && <Ellipsis text={title} max={26}/> }</span>
                    <span>{ description && <Ellipsis text={description} max={26}/> }</span>
                </h3>
            </header>
            <main>
                { soldRevenue && <div>
                    Sold revenue<br/> { soldRevenue }
                </div> }
            </main>
            <footer>
                { supervisor && <div>{supervisor}</div> }
                { surveySize && <div>{surveySize}</div> }
                <div className={ `percents-of-completion ${pocClass(percentCompleted)}`}>PoC {percentCompleted}</div>
            </footer>
        </div>
    </div>;

Card.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    soldRevenue: PropTypes.number,
    surveySize: PropTypes.number,
    supervisor: PropTypes.string,
    percentCompleted: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default Card;
