import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { set } from '../../redux/route/routeActions';
import { routeSelector } from '../../redux/route/routeSelectors';


const isActive = (activeClassName, href, route, isRoot, activeIfChildRoute) =>
    activeClassName &&
    (
        (activeIfChildRoute ? route.indexOf(href) > -1 : route === href) ||
        (isRoot && route === '/'))
        ? activeClassName
        : '';

const Link = ({ activeClassName, activeIfChildRoute, children, className, href, isRoot, onClick, route }) =>
    <a
        className={ `${isActive(activeClassName, href, route, isRoot, activeIfChildRoute)} ${className ? className : ''}` }
        href={ href }
        onClick={ onClick }>
        { children }
    </a>;

Link.propTypes = {
    activeClassName: PropTypes.string,
    activeIfChildRoute: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    isRoot: PropTypes.bool,
    onClick: PropTypes.func,
    route: PropTypes.string
};

export default connect(
    routeSelector,
    (dispatch, { href, onClick }) => ({
        onClick: e => {
            e.preventDefault();
            onClick ? onClick() : dispatch(set(href));
        }
    })
)(Link);
