import React from 'react';
import PropTypes from 'prop-types';

import Link from '../router/Link';

const HeaderNavigation = ({ links }) =>
    <nav>
        <ul>
            { links.map(
                ({ href, label}, i) =>
                    <li key={i}>
                        <Link
                            activeClassName="is-active"
                            activeIfChildRoute={ true }
                            isRoot={ i === 0}
                            href={href}>
                            {label}
                        </Link>
                    </li>
            ) }
        </ul>
    </nav>;

HeaderNavigation.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string
    }))
};

export default HeaderNavigation;
