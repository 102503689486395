import { ROUTE } from './routeActions.js';
import { compose, property } from 'lodash/fp';
import { deserialize } from '../../helpers/serialize';

const stateSelector = compose(
    property(ROUTE)
);

export const routeSelector = compose(
    ({
        configRoute,
        route = '',
        params,
        queryParams
    } = {}) => ({
        configRoute,
        route,
        params,
        queryParams
    }),
    stateSelector
);

export const routeAndStateSelector = state => ({
    route: routeSelector(state),
    state
});

export const modalPayloadSelector = compose(
    modalPayload => ({ modalPayload }),
    property('modalPayload'),
    stateSelector
);

export const modalSelector = compose(
    modal => ({ modal }),
    property('queryParams.modal'),
    stateSelector
);

export const filtersSelector = compose(
    filters => ({ filters: filters || {} }),
    f => f ? compose(
        deserialize,
        decodeURIComponent,
    )(f) : null,
    property('queryParams.filters'),
    routeSelector
);
