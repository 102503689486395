import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import { projectDetailLink } from '../../routes';
import Table from '../Table';
import { MODALS } from '../modal/Modal';
import { openModal } from '../../redux/route/routeActions';
import ProjectMenu from '../contextMenu/ProjectMenu';
import MilestoneRows from './milestones/MilestoneRows';
import { usersSelector } from '../../redux/lookups/lookupsSelectors';
import { isModeratorOrAdministratorSelector } from '../../redux/user/userSelectors';
import { getProjectColumns, getProjectRows } from '../../helpers/projectRows';
import { inlineEditProject } from '../../redux/projects/projectsActions';
import { inlineEditsSelector } from '../../redux/projects/projectsSelectors';

const contextMenu = ({id}, computedRows, {columns, rows}, center) =>
    <ProjectMenu project={ id } { ...{
        computedRows,
        columns: columns.slice(1, columns.length),
        center,
        rows: rows.slice(1, rows.length)
    } } />;

contextMenu.propTypes = {
    id: PropTypes.number
};

const isEditing = ({ id }, key, inlineEdits) =>
    inlineEdits.projects[id] &&
    typeof inlineEdits.projects[id][key] !== 'undefined';

const ProjectRows = props => {
    const dispatch = useDispatch();

    const mappedProps = (({
        addRow,
        center,
        list,
        isProjectSummary,
        small,
        selectedId,
        noMilestones,
        noCog,
        users,
        isModeratorOrAdministrator,
        inlineEditProject,
        inlineEdits
    }) => ({
        columns: getProjectColumns(
            small,
            isModeratorOrAdministrator,
            isProjectSummary,
            users,
            inlineEditProject,
            (data, key) => isEditing(data, key, inlineEdits)
        ),
        contextMenu: !noCog && isModeratorOrAdministrator
            ? (data, computedRows, props) =>
                contextMenu(data, computedRows, props, center)
            : null,
        data: list.map(({
            id,
            milestones,
            supervisorId,
            ...rest
        }) => ({
            ...rest,
            supervisor: (users.find(({ id }) => id === supervisorId) != null) ? users.find(({ id }) => id === supervisorId).description : null,
            supervisorId,
            center: center,
            id,
            tableExpandableContent: !noMilestones && milestones.length ? <MilestoneRows list={ milestones } isProjectSummary = {isProjectSummary}/> : null
        })),
        rows: getProjectRows(small, isModeratorOrAdministrator),
        defaultSortedRow: 'name',
        addRow: !window.location.href.includes('/project/') && isModeratorOrAdministrator ? addRow: null,
        className: 'project-table',
        rowLink: ({id}) => projectDetailLink(center, id),
        selectedRow: ({id}) => id === selectedId
    })
    )({
        ...props,
        ...useSelector(usersSelector),
        ...useSelector(isModeratorOrAdministratorSelector),
        ...useSelector(inlineEditsSelector),
        addRow: () => dispatch(openModal(MODALS.ADD_PROJECT)),
        inlineEditProject: (data, value, key) => dispatch(inlineEditProject(data, value, key))
    });

    return (
        <Table {...mappedProps}/>
    );
};

export default ProjectRows;
