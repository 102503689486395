import React from 'react';
import FormPostImagingProcessing from '../form/FormPostImagingProcessing';
import {useSelector} from 'react-redux';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalPostImagingProcessing = () => {
    const {project} = useSelector(projectSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Post-Processing Stage for {project.name}</h2>
            <FormPostImagingProcessing />
        </div>
    );
};

export default ModalPostImagingProcessing;
