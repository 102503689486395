import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormFieldTypes} from './FormField';
import Form from './Form';
import {
    errorMessageSelector,
    errorsSelector, projectSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import {closeModal} from '../../redux/route/routeActions';
import {stagesSelector} from '../../redux/lookups/lookupsSelectors';
import {saveStageData} from '../../redux/projects/projectsActions';



const {SUBMIT, CHECKBOX} = FormFieldTypes;

const valueReducer = (result, item) => {
    result[item.field_name] = item.values[0].value;
    return result;
};

const FormDemultiple = () => {
    const {errors} = useSelector(errorsSelector);
    const {message} = useSelector(errorMessageSelector);

    const {project: {id: projectId, stages: projectStages = []}} = useSelector(projectSelectorFromRouteId);
    const {stages} = useSelector(stagesSelector);

    const thisStage = stages.find(s => s.description === 'Demultiple');
    const projectStageData = projectStages.find(s => s.stage_id === thisStage.id);
    const values = projectStageData ? projectStageData.fields.reduce(valueReducer, {}) : {};

    const dispatch = useDispatch();
    const closeMe = () => dispatch(closeModal());
    const saveMe = data => dispatch(saveStageData(data, closeMe));

    const {
        shallowWater, threeD, seabed, wavefield, twoD, tauP, ime
    } = values;

    const onSubmit = (e, data) => {
        e.preventDefault();
        const payload = {'fields': Object.entries(data).filter(e => e[1] === true)
            .map(([ key, value ]) =>
                ({'field_name': key, 'values': [{'value': value}]})
            )};
        saveMe({projectId, stageId: thisStage.id, ...payload});
    };

    const fields = [
        {
            type: CHECKBOX,
            name: 'shallowWater',
            id: 'smd',
            label: 'Shallow Water Demultiple (includes muted SRME)',
            size: 'full condensed',
            checked: shallowWater,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'threeD',
            id: 'threeD',
            label: '3DSRME',
            size: 'full condensed',
            checked: threeD,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'seabed',
            id: 'ml-seabed',
            label: 'Seabed SRME',
            size: 'full condensed',
            checked: seabed,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'wavefield',
            id: 'wavefield',
            label: 'Wavefield SRME (2D/3D)',
            size: 'full condensed',
            checked: wavefield,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'twoD',
            id: 'twoD',
            label: '2D SRME',
            size: 'full condensed',
            checked: twoD,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'tauP',
            id: 'tauP',
            label: 'Tau-p Deconvolution (as a demultiple)',
            size: 'full condensed',
            checked: tauP,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: CHECKBOX,
            name: 'ime',
            id: 'ime',
            label: 'IME (Interbed Multiple Elimination)',
            size: 'full condensed',
            checked: ime,
            dataType: FormFieldTypes.BOOL
        },
        {
            type: SUBMIT,
            value: 'Edit stage'
        }
    ];

    return (
        <Form fields={fields} onSubmit={onSubmit} errors={errors} message={message}/>
    );
};

export default FormDemultiple;
