import { compose, find, pick, property, reduce } from 'lodash/fp';
import { PROJECTS } from './projectsActions';
import { centerIdSelector } from '../lookups/lookupsSelectors';
import { centerSelector } from '../common';
import { routeAndStateSelector } from '../route/routeSelectors';
import { computeProject, computeProjects } from './projectsCompute';

export const stateSelector = property(PROJECTS);

export const projectsSelectorForCenter = compose(
    projects => ({ projects }),
    computeProjects,
    ({ id, list, filter }) => {
        let filtered = list.filter(({ centerId }) => centerId === id);
        if (filter) {
            filtered = filtered.filter(({ description }) => description.toLowerCase().indexOf(filter.toLowerCase()) > -1);
        }
        return filtered;
    },
    s => ({ id: centerIdSelector(centerSelector(s).center)(s).centerId, ...stateSelector(s) })
);

export const projectSelectorFromRouteId = compose(
    ({ project = {} }) => ({ project: computeProject(project) }),
    ({ state, route }) => {
        let obj = projectSelectorFromId(route.params.id || route.queryParams.project)(state);
        if (typeof(obj.project) === 'undefined') obj = {'project': {id: 0}};
        return obj;
    },
    routeAndStateSelector
);

export const projectSelectorFromMilestoneId = _id => compose(
    project => ({ project }),
    find(({milestones}) => milestones.find(({id}) => id === parseInt(_id))),
    property('list'),
    stateSelector
);

export const projectSelectorFromId = _id => compose(
    project => ({ project }),
    find(({ id }) => id === parseInt(_id)),
    property('list'),
    stateSelector
);

export const tasksSelectorFromRouteId = compose(
    tasks => ({ tasks }),
    reduce(
        (combinedTasks, { id, tasks }) =>
            ([
                ...combinedTasks,
                ...tasks.map(task => ({ milestoneId: id, ...task }))
            ]),
        []
    ),
    property('milestones'),
    property('project'),
    projectSelectorFromRouteId
);

export const percentsSelectorFromIds = (taskId, _id) => compose(
    (percents = {}) => ({ percents }),
    find(({ id }) => id === _id),
    property('task.pctOfCmps'),
    taskSelectorFromId(taskId)
);

export const taskSelectorFromId = _id => compose(
    task => ({ task }),
    find(({ id }) => id === _id),
    property('tasks'),
    tasksSelectorFromRouteId
);

export const milestoneSelectorFromId = _id => compose(
    milestone => ({ milestone }),
    find(({ id }) => id === _id),
    property('milestones'),
    milestonesSelectorFromRouteId
);

export const milestoneSelectorFromRouteId = compose(
    milestone => ({ milestone }),
    ({state, projectId, milestoneId}) => {
        if (!projectId) {
            const {PROJECTS: {list = []}} = state;
            let milestone;
            list.find(p => {
                const _milestone = p.milestones.find(m => m.id === parseInt(milestoneId));
                milestone = _milestone ? _milestone : milestone;
                return _milestone;
            });
            return milestone;
        }
        const {project: {milestones = []}} = projectSelectorFromId(projectId)(state);
        return milestones.find(m => m.id === parseInt(milestoneId));
    },
    ({ state, route }) => {
        const {params: {id: projectId}, queryParams: {milestone: milestoneId}} = route;
        return {projectId, milestoneId, state};
    },
    routeAndStateSelector
);

export const milestonesSelectorFromRouteId = compose(
    (milestones = []) => ({ milestones }),
    property('milestones'),
    property('project'),
    projectSelectorFromRouteId
);

export const milestonesSelectorFromProjectId = _id => compose(
    ({ milestones }) => ({ milestones }),
    property('project'),
    projectSelectorFromId(_id)
);

export const isLoadedSelector = compose(
    ({ isLoaded }) => ({ isLoaded }),
    stateSelector
);

export const projectEditSelectorFromId = _id => compose(
    projectEdit => ({ projectEdit }),
    ({
        id,
        name,
        description,
        projectTypeId,
        areaId,
        bidId,
        centerId,
        supervisorId,
        surveySize,
        unitType,
        inputSize,
        azimuths,
        surveyTypeId,
        active,
        acquisitionType
    }) => ({
        id,
        project_name: name,
        description,
        projectTypeId,
        area_id: areaId,
        bid_id: bidId,
        center_id: centerId,
        supervisor_id: supervisorId,
        survey_size: surveySize,
        unit_type: unitType,
        input_size: inputSize,
        azimuths,
        survey_type_id: surveyTypeId,
        active,
        acquisition_type: acquisitionType
    }),
    property('project'),
    projectSelectorFromId(_id),
);

export const indexFromProjectId = projectId => state => {
    projectId = parseInt(projectId);
    for (let i = 0, iLength = state.list.length; i < iLength; i++) {
        if (state.list[i].id === parseInt(projectId)) return i;
    }
};


export const indexesFromMilestoneId = milestoneId => state => {
    for (let i = 0, iLength = state.list.length; i < iLength; i++) {
        for (let j = 0, jLength = state.list[i].milestones.length; j < jLength; j++) {
            if (state.list[i].milestones[j].id === parseInt(milestoneId))
                return {
                    projectIndex: i,
                    milestoneIndex: j
                };
        }
    }
};
export const indexesFromTaskId = taskId => state => {
    for (let i = 0, iLength = state.list.length; i < iLength; i++) {
        for (let j = 0, jLength = state.list[i].milestones.length; j < jLength; j++) {
            for (let k = 0, kLength = state.list[i].milestones[j].tasks.length; k < kLength; k++) {
                if (state.list[i].milestones[j].tasks[k].id === parseInt(taskId)) {
                    return {
                        projectIndex: i,
                        milestoneIndex: j,
                        taskIndex: k
                    };
                }
            }
        }
    }
};


export const indexesFromPercentId = percentId => state => {
    for (let i = 0, iLength = state.list.length; i < iLength; i++) {
        for (let j = 0, jLength = state.list[i].milestones.length; j < jLength; j++) {
            for (let k = 0, kLength = state.list[i].milestones[j].tasks.length; k < kLength; k++) {
                for (let l = 0, lLength = state.list[i].milestones[j].tasks[k].pctOfCmps.length; l < lLength; l++) {
                    if (state.list[i].milestones[j].tasks[k].pctOfCmps[l].id === parseInt(percentId))
                        return {
                            projectIndex: i,
                            milestoneIndex: j,
                            taskIndex: k,
                            percentIndex: l
                        };
                }
            }
        }
    }
};

export const inlineEditsSelector = compose(
    pick('inlineEdits'),
    stateSelector
);

export const hasInlineEditsSelector = compose(
    hasInlineEdits => ({ hasInlineEdits }),
    ({ projects, milestones, tasks }) =>
        Object.keys(projects).length > 0 ||
        Object.keys(tasks).length > 0 ||
        Object.keys(milestones).length > 0,
    property('inlineEdits'),
    inlineEditsSelector
);

export const lastAddedTaskSelector = compose(
    pick('lastAddedTask'),
    stateSelector
);

export const errorsSelector = compose(
    pick('errors'),
    stateSelector
);

export const errorMessageSelector = compose(
    pick('message'),
    stateSelector
);
