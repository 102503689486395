import asyncAction, {asyncActionQueue} from '../../helpers/asyncAction';
import config from '../../config';

export const LOOKUPS = 'LOOKUPS';
export const FETCH_LOOKUPS = `${LOOKUPS}/FETCH/LOOKUPS`;
export const FETCH_VALIDATORS = `${LOOKUPS}/FETCH/VALIDATORS`;
export const FETCH_COMPUTE_RESOURCE = `${LOOKUPS}/FETCH/COMPUTE_RESOURCE`;
export const FETCH = `${LOOKUPS}/FETCH`;

export const fetchLookups = asyncAction(FETCH_LOOKUPS, {
    url: config.WEBSERVICES.LOOKUPS
});

export const fetchValidators = asyncAction(FETCH_VALIDATORS, {
    url: config.WEBSERVICES.VALIDATORS
});

export const fetchComputeResource = asyncAction(FETCH_COMPUTE_RESOURCE, {
    url: config.WEBSERVICES.COMPUTE_RESOURCE
});

export const fetch = () => asyncActionQueue(FETCH, [
    {action: fetchLookups, payload: null},
    {action: fetchValidators, payload: null},
    {action: fetchComputeResource, payload: null}
]);

