import React from 'react';
import FormTask from '../form/FormTask';
import { useSelector } from 'react-redux';
import { taskSelectorFromId } from '../../redux/projects/projectsSelectors';
import withRoute, {WithRouteProps} from '../HOC/withRoute';

const ModalEditTask = ({queryParams: {task: taskId}, params: {id}}) => {
    const projectId = parseInt(id);
    const {task} = useSelector(taskSelectorFromId(parseInt(taskId)));

    return (
        <div>
            <h2>Edit task</h2>
            <FormTask values={task} projectId={projectId} />
        </div>
    );
};

ModalEditTask.propTypes = WithRouteProps;

export default withRoute(ModalEditTask);
