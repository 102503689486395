import '../../../scss/views/_projects-summary.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Projects from '../../components/projects/Projects';
import Toolbar  from '../../components/toolbar/Toolbar';
import {useSelector} from 'react-redux';
import {isLoadedSelector} from '../../redux/projects/projectsSelectors';
import Loader from '../../components/base/Loader';


const ProjectsSummary = ({
    center,
    addProject,
    projects,
    projectWording

}) => {
    const {isLoaded} = useSelector(isLoadedSelector);

    return (
        isLoaded ?
            center ?
                <main className="main" id="view-projects-summary">
                    <header>
                        <Toolbar
                            add={ addProject }
                            title={ projectWording.summaryTitle }
                            addWording={ projectWording.add }
                            filters={[
                                {
                                    label: 'Supervisor',
                                    value: 'supervisor'
                                },
                                {
                                    label: 'Project',
                                    value: 'name'
                                }
                            ]}
                        />
                    </header>

                    <Projects
                        center={center}
                        list={projects}
                        isProjectSummary = {true}/>
                </main> :
                null
            : <Loader/>
    );
};


ProjectsSummary.propTypes = {
    center: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    projects: PropTypes.arrayOf(PropTypes.any),
    addProject: PropTypes.func,
    projectWording: PropTypes.object
};

export default ProjectsSummary;
