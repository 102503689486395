import { compose, find, map, property } from 'lodash/fp';
import { LOOKUPS } from './lookupsActions';
import { projectSummaryLink } from '../../routes';

export const stateSelector = property(LOOKUPS);

export const headerLinksSelector = compose(
    links => ({ links }),
    map(({ description }) => ({
        href: projectSummaryLink(description.toLowerCase()),
        label: description
    })),
    property('center'),
    stateSelector
);

export const centerIdSelector = name => compose(
    ({ id } = {}) => ({ centerId: id || 0 }),
    find(({ description }) => name && description.toLowerCase() === name.toLowerCase()),
    property('center'),
    stateSelector,
);

export const centersSelector = compose(
    (centers = []) => ({ centers }),
    property('center'),
    stateSelector
);

export const algorithmsSelector = compose(
    (algorithms = []) => ({ algorithms }),
    property('algorithm'),
    stateSelector
);

export const resourcesSelector = compose(
    (resources = []) => ({ resources }),
    property('compute_resource'),
    stateSelector
);

export const validatorsSelector = compose(
    (validators = []) => ({ validators }),
    property('milestone_task_validator'),
    stateSelector
);

export const positionsSelector = compose(
    (positions = []) => ({ positions }),
    property('position'),
    stateSelector
);

export const areasSelector = compose(
    (areas = []) => ({ areas }),
    property('area'),
    stateSelector
);

export const bidsSelector = compose(
    (bids = []) => ({ bids }),
    property('bid'),
    stateSelector
);

export const projectTypesSelector = compose(
    (projectTypes = []) => ({ projectTypes }),
    property('project_type'),
    stateSelector
);

export const stagesSelector = compose(
    (stages = []) => ({ stages }),
    property('stage'),
    stateSelector
);

export const usersSelector = compose(
    (users = []) => ({ users }),
    property('user'),
    stateSelector
);

export const unitTypesSelector = compose(
    (unitTypes = []) => ({ unitTypes }),
    property('unit_type'),
    stateSelector
);

export const surveyTypesSelector = compose(
    (surveyTypes = []) => ({ surveyTypes }),
    property('survey_type'),
    stateSelector
);

export const clientsSelector = compose(
    (clients = []) => ({ clients }),
    property('client'),
    stateSelector
);

export const projectGroupsSelector = compose(
    (projectGroups = []) => ({ projectGroups }),
    property('project_group'),
    stateSelector
);

export const acquisitionTypesSelector = compose(
    (acquisitionTypes = []) => ({ acquisitionTypes }),
    property('acquisition_type'),
    stateSelector
);

export const marineStreamerTypeSelector = compose(
    (marineStreamerTypes = []) => ({marineStreamerTypes}),
    property('marine_streamer_type'),
    stateSelector
);

export const acquisitionCategorySelector = compose(
    (acquisitionCategories = []) => ({acquisitionCategories}),
    property('acquisition_category'),
    stateSelector
);

export const towingMethodSelector = compose(
    (towingMethods = []) => ({towingMethods}),
    property('towing_method'),
    stateSelector
);

export const acquisitionComponentsSelector = compose(
    (acquisitionComponents = []) => ({acquisitionComponents}),
    property('acquisition_components'),
    stateSelector
);

export const streamerLengthSelector = compose(
    (streamerLengths = []) => ({streamerLengths}),
    property('streamer_length'),
    stateSelector
);

export const sampleIntervalSelector = compose(
    (sampleIntervals = []) => ({sampleIntervals}),
    property('sample_interval'),
    stateSelector
);

export const acquisitionContractorSelector = compose(
    (acquisitionContractors = []) => ({acquisitionContractors}),
    property('acquisition_contractor'),
    stateSelector
);
