import '../scss/app.scss';
import React from 'react';
import {useSelector, useDispatch, Provider} from 'react-redux';
import PropTypes from 'prop-types';
import {initialFetch} from './redux/common';
import IrisBanner from './components/header/IrisBanner';
import Header from './components/header/Header';
import Router from './components/router/Router';
import Modal from './components/modal/Modal';
import config from './config';
import { store, persistor } from './redux/store.js';
import { PersistGate } from 'redux-persist/integration/react';
import {PROJECT_SUMMARY, PROJECT_DETAILS, ACQUISITIONS_SUMMARY} from './routes';
import ProjectDetail from './views/projectDetail/ProjectDetailContainer';
import ProjectsSummary from './views/projectsSummary/ProjectsSummaryContainer';
import AcquisitionsSummary from './views/acquisitionsSummary/AcquisitionsSummaryContainer';
import {hasInlineEditsSelector, isLoadedSelector} from './redux/projects/projectsSelectors';
import {isConnectedSelector, isAdministratorSelector} from './redux/user/userSelectors';
import {asyncActionSuccess} from './helpers/asyncAction';
import {LOGIN} from './redux/user/userActions';
import ValidateInlineEdits from './components/projects/ValidateInlineEdits';
import Loader from './components/base/Loader';
import IdleTimer from './components/base/IdleTimer';
import { message } from 'antd';

const setBypassLogin = () => {
    if (window.location.search.indexOf('bypassLogin') > -1) {
        const params = window.location.search.split('&');
        const pair = params.find(v => v.indexOf('bypassLogin') > -1).split('=');
        localStorage.setItem('bypassLogin', pair.length > 1 ? pair[1] : 'User');
    }
};

const App = ({hasInlineEdits, isLoaded, isConnected, isAdministrator}) => <div id="app-wrapper">
    <IrisBanner isConnected={isConnected} isAdministrator={isAdministrator}/>
    {isConnected && <Header/>}
    <Router isConnected={isConnected} isLoaded={isLoaded} baseUrl={config.BASE_URL} routes={{
        [ACQUISITIONS_SUMMARY]: AcquisitionsSummary,
        [PROJECT_SUMMARY]: ProjectsSummary,
        [PROJECT_DETAILS]: ProjectDetail
    }}/>
    {hasInlineEdits && <ValidateInlineEdits/>}
    <Modal/>
</div>;

App.propTypes = {
    isConnected: PropTypes.bool,
    isAdministrator: PropTypes.bool,
    isLoaded: PropTypes.bool,
    hasInlineEdits: PropTypes.bool
};

const AppContainer = () => {
    const dispatch = useDispatch();
    const doInitialFetch = () => dispatch(initialFetch());
    const setLoginSuccess = user_type => dispatch({type: asyncActionSuccess(LOGIN), response: {user_type}});
    const {isConnected} = useSelector(isConnectedSelector);
    const {isAdministrator} = useSelector(isAdministratorSelector);
    const {isLoaded} = useSelector(isLoadedSelector);
    const {hasInlineEdits} = useSelector(hasInlineEditsSelector);

    React.useEffect(() => {
        message.info('To get the latest update, please clear your browser cache (Ctrl + F5)' +
            ' if you have visited Iris Online before.').then();
        doInitialFetch();
        setBypassLogin();
        if (localStorage.getItem('bypassLogin')) {
            setLoginSuccess(localStorage.getItem('bypassLogin'));
        }
    }, []);

    return (
        <div>
            <App isAdministrator={isAdministrator} isConnected={isConnected} isLoaded={isLoaded}
                hasInlineEdits={hasInlineEdits}/>
            <IdleTimer/>
        </div>
    );
};

if (module.hot) {
    module.hot.accept(function() {
        location.reload();
    });
}

const ReduxProvider = () =>
    <Provider store={store}>
        <PersistGate loading={<Loader/>} persistor={persistor}>
            <AppContainer/>
        </PersistGate>
    </Provider>;

export default ReduxProvider;
