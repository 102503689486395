import update from 'immutability-helper';
import { mapTask } from '../map';
import {
    indexesFromMilestoneId,
    indexesFromTaskId
} from '../projectsSelectors';

export const addTaskToState = (state, response) => {

    const {
        projectIndex,
        milestoneIndex
    } = indexesFromMilestoneId(response.milestone_id)(state);
    return update(state, {
        lastAddedTask: { $set: response.id },
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            $push: [mapTask(response)]
                        }
                    }
                }
            }
        }
    });

};

export const cloneTaskToState = (state, response) => {

    const {
        projectIndex,
        milestoneIndex
    } = indexesFromMilestoneId(response.milestone_id)(state);
    return update(state, {
        lastAddedTask: { $set: response.id },
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            $push: [mapTask(response)]
                        }
                    }
                }
            }
        }
    });

};

export const editTaskInState = (state, body) => {

    const {
        projectIndex,
        milestoneIndex,
        taskIndex
    } = indexesFromTaskId(body.id)(state);
    const task = state.list[projectIndex].milestones[milestoneIndex].tasks[taskIndex] || {};

    //if inline-editing milestone_id will be undefined
    const newMilestoneIndex = body.milestone_id
        ? indexesFromMilestoneId(body.milestone_id)(state).milestoneIndex
        : milestoneIndex;



    let _update = {};
    const _task = {
        ...mapTask(body, task)
    };

    if (newMilestoneIndex !== milestoneIndex) {
        _update = {
            [milestoneIndex]: {
                tasks: {
                    $splice: [[ taskIndex, 1 ]]
                }
            },
            [newMilestoneIndex]: {
                tasks: {
                    $push: [_task]
                }
            }
        };
    } else {

        _update = {
            [milestoneIndex]: {
                tasks: {
                    [taskIndex]: {
                        $set: _task
                    }
                }
            }
        };
    }

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: _update
            }
        }
    });

};

export const deleteTaskInState = (state, body) => {

    const {
        projectIndex,
        milestoneIndex,
        taskIndex
    } = indexesFromTaskId(body.id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            $splice: [[ taskIndex, 1 ]]
                        }
                    }
                }
            }
        }
    });
};
