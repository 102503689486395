import serialize from './serialize';

const xhr = options =>
    new Promise( (success, error) => {

        let request = new XMLHttpRequest();

        if (options.xhrFields) {
            let key;
            for (key in options.xhrFields) {
                if (Object.prototype.hasOwnProperty.call(options.xhrFields, key) &&
                    Object.prototype.hasOwnProperty.call(request, key)) {
                    request[key] = options.xhrFields[key];
                }
            }
        }

        request.onreadystatechange = () => {

            if (options.loading && request.readyState === 3) {
                options.loading(request);
            }

            if (request.readyState === 4) {
                let contentType = request.getResponseHeader('Content-Type');
                let response = contentType && contentType.indexOf('application/json') > -1 && request.responseText ? JSON.parse(request.responseText) : request.responseText;
                if (!response && request.status === 0) {
                    response = 'Connection failed';
                }

                if (/2[0-9]{1,2}/.test(request.status) && !response.errorCode) {
                    if (typeof options.success === 'function') {

                        if (options.formatter && options.formatter.AjaxFormat && options.formatter.AjaxFormat.Response) {
                            response = JSON.parse( options.formatter.AjaxFormat.Response(response) );
                        }
                        options.success(response, request);
                        success(response,request);
                    }
                } else if (typeof options.error === 'function') {
                    options.error(response, request);
                } else {
                    error(response, request);
                }
            }
        };

        if (!options.contentType && !options.formData) options.contentType = 'application/x-www-form-urlencoded';


        if ( options.formatter ) {
            if (options.formatter.AjaxFormat && options.formatter.AjaxFormat.Request) {
                options.data = options.formatter.AjaxFormat.Request(options.data);
            }
        }

        if (!options.method) options.method = 'GET';

        if (typeof options.data === 'object' && !options.formData) {
            if(options.contentType !== 'application/json' || options.method === 'GET') {
                options.data = serialize(options.data);
            } else {
                options.data = JSON.stringify(options.data);
            }
        }


        let urlSuffix = '';
        if (options.method === 'GET' && options.data ) {
            urlSuffix = '?'+options.data;
        }

        if (options.noCache && (options.method === 'GET' || options.method === 'HEAD') ) {
            urlSuffix+= urlSuffix.indexOf('?') > -1 ? '&' : '?';
            urlSuffix+='noCache='+new Date().getDate();
        }

        if (options.method === 'GET' && options.data )
            request.open(options.method, options.url+urlSuffix, true);
        else
            request.open(options.method, options.url+urlSuffix, true);

        if (options.contentType) request.setRequestHeader('Content-type', options.contentType);

        if (options.headers) {
            let key;
            for (key in options.headers) {
                if (Object.prototype.hasOwnProperty.call(options.headers, key)) {
                    request.setRequestHeader(key, options.headers[key]);
                }
            }
        }

        if ( options.noCache ) request.setRequestHeader('Cache-Control','no-cache');
        if ( !options.crossDomain ) request.setRequestHeader('X-Requested-With','XMLHttpRequest');

        if (typeof options.onUploadStart === 'function')
            request.upload.addEventListener('loadstart', options.onUploadStart);

        if (typeof options.onUploadProgress === 'function')
            request.upload.addEventListener('progress', options.onUploadProgress);

        if (typeof options.onUploadDone === 'function')
            request.upload.addEventListener('load', options.onUploadDone);

        request.send( options.data );
    });

export default xhr;
