export const setCheckedFieldState = setter => e => {
    setter(e.target.checked);
};

export const setValueFieldState = setter => e => {
    setter(e.target.value);
};

export const setSelectFieldState = setter => e => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setter({
        value: selectedOption.value,
        label: selectedOption.text
    });
};

export const setCheckedGroupExclusiveState = group => e => {
    const { name, checked } = e.target;
    group.forEach(f => f.setter(f.name === name ? checked : false));
};

export const isExclusiveGroupChecked = group => group.filter(f => f.getter === true).length > 0;
