import React from 'react';
import { useDispatch } from 'react-redux';

import ContextMenu from './ContextMenu';

import PencilIcon from '../../../img/pencil.svg';
import { MODALS } from '../modal/Modal';
import { openModal } from '../../redux/route/routeActions';
import PropTypes from 'prop-types';
import TrashIcon from '../../../img/trash.svg';

const AcquisitionMenu = ({ acquisition_id }) => {
    const dispatch = useDispatch();
    const editProject = () =>
        dispatch(openModal(MODALS.EDIT_ACQUISITION, null, { id: acquisition_id }));
    const deleteAcquisition = () => dispatch(openModal(MODALS.CONFIRM_DELETION, null, { acquisition_id }));


    let buttons = [
        {
            IconComponent: PencilIcon,
            label: 'Edit',
            onClick: editProject
        },
        {
            IconComponent: TrashIcon,
            label: 'Delete',
            onClick: deleteAcquisition
        }
    ];

    return <ContextMenu buttons={buttons} className="acquisition-menu" />;
};

AcquisitionMenu.propTypes = {
    acquisition_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    columns: PropTypes.array,
    rows: PropTypes.array,
    computedRows: PropTypes.array
};

export default AcquisitionMenu;
