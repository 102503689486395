import React from 'react';
import FormDemultiple from '../form/FormDemultiple';
import {useSelector} from 'react-redux';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalDemultiple = () =>  {
    const {project} = useSelector(projectSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Demultiple Stage for {project.name}</h2>
            <FormDemultiple />
        </div>
    );
};

export default ModalDemultiple;
