/*
const formatRows = rows => encodeURI(
    rows.map(
        (row, index) =>
            index === 0 ? 'data:text/csv;charset=utf-8,' + row.join(',') : row.join(',')
    ).join('\n')
);
*/

const downloadRowsAsCsv =  (rows, fileName) => {
    const link = document.createElement('a');

    //1.0.18 - Reference - https://stackoverflow.com/questions/23816005/anchor-tag-download-attribute-not-working-bug-in-chrome-35-0-1916-114
    const blob = new Blob([rows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(blob);

    link.setAttribute('href', csvUrl); //formatRows(rows)
    //link.setAttribute('href', formatRows(rows));
    link.setAttribute('download', `${fileName}.csv`);

    document.body.appendChild(link); // Required for FF
    link.click();
    link.parentNode.removeChild(link);
};

export default downloadRowsAsCsv;
