import React from 'react';
import ContextMenu from './ContextMenu';

import PencilIcon from '../../../img/pencil.svg';
import TrashIcon from '../../../img/trash.svg';
import {useDispatch} from 'react-redux';
import { openModal } from '../../redux/route/routeActions';
import { MODALS } from '../modal/Modal';
import PropTypes from 'prop-types';

const MilestoneMenu = ({milestone}) => {
    const dispatch = useDispatch();
    const editMilestone = () => dispatch(openModal(MODALS.EDIT_MILESTONE, null,{ milestone }));
    const deleteMilestone = () => dispatch(openModal(MODALS.CONFIRM_DELETION, null, { milestone_id: milestone }));

    const buttons = [
        {
            IconComponent: PencilIcon,
            label: 'Edit',
            onClick: editMilestone
        },
        {
            IconComponent: TrashIcon,
            label: 'Delete',
            onClick: deleteMilestone
        }
    ];

    return (
        <ContextMenu buttons={buttons} className="milestone-menu"/>
    );
};

MilestoneMenu.propTypes = {
    milestone: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

export default MilestoneMenu;
