import React from 'react';
import FormMilestone from '../form/FormMilestone';
import {useSelector} from 'react-redux';
import {routeSelector} from '../../redux/route/routeSelectors';

const ModalAddMilestone = () => {
    const {params} = useSelector(routeSelector);

    return (
        <div>
            <h2>Add milestone</h2>
            <FormMilestone params={params} />
        </div>
    );
};

export default ModalAddMilestone;
