import _package from '../../package.json';

export const PORT = '5050';

const BACKEND_HOST = (typeof IRIS_BACKEND_HOST !== 'undefined' ? IRIS_BACKEND_HOST : (process.env.IRIS_BACKEND_HOST ? process.env.IRIS_BACKEND_HOST : '')).replace(/https:\/\/|http:\/\//,'');
const BACKEND_PORT = typeof IRIS_BACKEND_PORT !== 'undefined' ? IRIS_BACKEND_PORT : process.env.IRIS_BACKEND_PORT;

const PROTOCOL = `${window.location.protocol}//`;
const SERVER = BACKEND_HOST && BACKEND_PORT ? `${PROTOCOL}${BACKEND_HOST}:${BACKEND_PORT}` :
    window.location.origin + window.location.origin.endsWith('/') ? '' : '/';
const API = 'api/iris';

console.log(`iris-frontend v${_package.version}`);
console.log(`iris-backend ${SERVER}`);
console.log(`Protocol ${PROTOCOL}`);

export default {
    VERSION: _package.version,
    APP_ID: 'react-app',
    BASE_URL: window.location.origin,
    IDLE_TIMEOUT: 5 * 60 * 1000, // 5 minutes as milliseconds
    SESSION_TIMEOUT: 30 * 60 * 1000, // half an hour
    WEBSERVICES: {
        ACQUISITION: `${SERVER}/${API}/acquisition/`,
        ACQUISITION_COMPLEX: `${SERVER}/${API}/acquisition/complex`,
        LOOKUPS: `${SERVER}/${API}/lookups/all`,
        VALIDATORS: `${SERVER}/${API}/jira/validators`,
        MILESTONE: `${SERVER}/${API}/milestone/`,
        PROJECT: `${SERVER}/${API}/project/`,
        PROJECT_COMPLEX: `${SERVER}/${API}/project/complex`,
        TASK: `${SERVER}/${API}/milestoneTask/`,
        PERCENTS: `${SERVER}/${API}/pctOfCmp/`,
        LOGIN: `${SERVER}/${API}/user/login`,
        USER_INFO: `${SERVER}/${API}/user/me`,
        UPDATE_REPORTS: `${SERVER}/${API}/cron/databaseBulkLoad/project/`,
        COMPUTE_RESOURCE: `${SERVER}/${API}/computeResource/`
    }
};
