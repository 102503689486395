import '../../../scss/components/header/_iris-banner.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import _package from '../../../../package.json';
import {logout} from '../../redux/user/userActions';
import Msal from '../sso/msalProvider';
import {SsoTokenProvider} from '../sso/jwtSsoContext';
import MsalLogin from '../sso/msalLogin';

const APP_VERSION = 'V' + _package.version;
const IS_TESTING = false;

function PeteBanner(props) {
    const isTesting = props.isTesting;
    if (isTesting) {
        return <span>you are viewing this in testing mode</span>;
    } else {
        return <span>Welcome back!</span>;
    }
}

function expandMenu() {
    let navWrapper = document.querySelector('.nav-wrapper');
    navWrapper.classList.toggle('active');
}

const IrisBanner = ({ isConnected, isAdministrator, logout }) => <div>
    <div className="test test-card peteBanner">
        <a href="https://pgsgeo.atlassian.net/wiki/spaces/ISWD/" id="logo-pgs-pete">
            <h4 className="sub_logo_top">ISWD</h4>
            <div className="sub_logo_bottom">IRIS<p className="version">{APP_VERSION}</p></div>
        </a>
        <div className="test-text test">
            <h2>IMAGING REPORTING AND INFORMATION SYSTEM</h2>
            <PeteBanner isTesting={IS_TESTING}/>
            <div className="bg-transparent left test">
            </div>
            <div className="bg-transparent center test">
            </div>
            <div className="bg-transparent right test">
            </div>
        </div>
        <div className="login">
            <Msal>
                <SsoTokenProvider>
                    <MsalLogin />
                </SsoTokenProvider>
            </Msal>
        </div>
    </div>
    <div className="nav-wrapper">
        <nav>
            <div className="navigation">
                <ul className="nav-items">
                    <li><a href="https://pgsgeo.atlassian.net/wiki/spaces/ISWD/" target="_blank"
                        rel="noopener noreferrer">Imaging Software Documentation</a></li>
                    <li className="divider"/>
                    <li><a href="https://lpete01.lon.compute.pgs.com/" target="_blank"
                        rel="noopener noreferrer">Pete</a></li>
                    <li className="divider"/>
                    <li><a href="https://superset-iris.prod.shared.pgsapps.io/" target="_blank"
                        rel="noopener noreferrer">Reports (Superset)</a></li>
                    {isConnected && isAdministrator &&
                        <>
                            <li className="divider"/>
                            <li><a href="/admin">Admin</a></li>
                        </>
                    }
                    {isConnected &&
                        <>
                            <li className="divider"/>
                            <li><a href="#" onClick={logout}>Log Out</a></li>
                        </>
                    }
                </ul>
                <div className="nav-toggler" onClick={expandMenu}></div>
            </div>
        </nav>
    </div>
    <p>&nbsp;</p>
</div>;

IrisBanner.propTypes = {
    isConnected: PropTypes.bool,
    isAdministrator: PropTypes.bool,
    logout: PropTypes.func
};

PeteBanner.propTypes = {
    isTesting: PropTypes.bool
};

export default connect(
    null,
    dispatch => ({
        logout: () => dispatch(logout(dispatch))
    })
)(IrisBanner);
