import React from 'react';
import {useSelector} from 'react-redux';
import Card from '../Card';
import { milestonesSelectorFromRouteId } from '../../../redux/projects/projectsSelectors';
import PropTypes from 'prop-types';

const TaskCard = ({id, name, milestoneId, soldRevenue, taskPercentCompleted, supervisor, surveySize, onClick}) => {
    const myOnClick = () => onClick(id);
    const {milestones} = useSelector(milestonesSelectorFromRouteId);
    const description = milestones.find(({ id}) => id === milestoneId).name;

    return (
        <Card title={name} description={description} soldRevenue={soldRevenue}
            percentCompleted={taskPercentCompleted} supervisor={supervisor} surveySize={surveySize}
            onClick={myOnClick} />
    );
};

TaskCard.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    milestoneId: PropTypes.number,
    description: PropTypes.string,
    soldRevenue: PropTypes.number,
    surveySize: PropTypes.number,
    supervisor: PropTypes.string,
    taskPercentCompleted: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    onClick: PropTypes.func
};

export default TaskCard;
