function serializeParam(param) {

    if (typeof param !== 'string') {
        return JSON.stringify(param);
    }
    return param;

}

export default function serialize(obj) {

    let params = [];
    let key;
    for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] instanceof Array) {
                obj[key].forEach(function(p) {
                    params.push(key+'='+serializeParam(p));
                });
            } else {
                params.push(key+'='+serializeParam(obj[key]));
            }
        }
    }

    return params.join('&');

}

export const deserialize = str =>
    str.split('&').reduce(
        (acc, pair) => {
            pair = pair.split('=');
            acc[pair[0]] = pair[1];
            return acc;
        },
        {}
    );
