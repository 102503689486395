import '../../../scss/components/base/_error-message.scss';
import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error }) =>
    <div className="error-message">
        { error }
    </div>;

ErrorMessage.propTypes = {
    error: PropTypes.string
};

export default ErrorMessage;
