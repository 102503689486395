import '../../../scss/components/modal/_modal.scss';
import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/route/routeActions';
import {
    modalSelector,
    modalPayloadSelector
} from '../../redux/route/routeSelectors';
import ModalAddProject from './ModalAddProject';
import ModalEditProject from './ModalEditProject';
import ModalAddTask from './ModalAddTask';
import ModalEditTask from './ModalEditTask';
import ModalAddMilestone from './ModalAddMilestone';
import ModalEditMilestone from './ModalEditMilestone';
import ModalAddPercents from './ModalAddPercents';
import ModalEditPercents from './ModalEditPercents';
import ModalConfirmDeletion from './ModalConfirmDeletion';
import CrossIcon from '../../../img/cross.svg';
import withClickOutside from '../HOC/withClickOutside';
import ModalCloneTask from './ModalCloneTask';
import ModalEditProjectPreprocessing from './ModalEditProjectPreprocessing';
import ModalDemultiple from './ModalDemultiple';
import ModalRegularization from './ModalRegularization';
import ModalPostImagingProcessing from './ModalPostImagingProcessing';
import ModalModelBuilding from './ModalModelBuilding';
import ModalImaging from './ModalImaging';
import ModalAddAcquisition from './ModalAddAcquisition';
import ModalEditAcquisition from './ModalEditAcquisition';
import ModalManageProjectAcquisitions from './ModalManageProjectAcquisitions';

export const MODALS = {
    ADD_PROJECT: 'add-project',
    EDIT_PROJECT: 'edit-project',
    EDIT_PROJECT_PREPROCESSING: 'edit-project-preprocessing',
    ADD_TASK: 'add-task',
    EDIT_TASK: 'edit-task',
    ADD_MILESTONE: 'add-milestone',
    EDIT_MILESTONE: 'edit-milestone',
    ADD_PERCENTS: 'add-percents',
    EDIT_PERCENTS: 'edit-percents',
    CONFIRM_DELETION: 'confirm-deletion',
    CLONE_TASK: 'clone-task',
    EDIT_DEMULTIPLE: 'modal-demultiple',
    EDIT_REGULARIZATION: 'modal-regularization',
    EDIT_POST_IMAGING: 'modal-post-imaging',
    EDIT_MODEL_BUILDING: 'modal-model-building',
    EDIT_IMAGING: 'modal-imaging',
    EDIT_ACQUISITION: 'modal-edit-acquisition',
    ADD_ACQUISITION: 'modal-add-acquisition',
    MANAGE_PROJECT_ACQUISITIONS: 'modal-manage-acquisitions'
};

const modals = {
    [MODALS.ADD_PROJECT]: ModalAddProject,
    [MODALS.EDIT_PROJECT]: ModalEditProject,
    [MODALS.EDIT_PROJECT_PREPROCESSING]: ModalEditProjectPreprocessing,
    [MODALS.ADD_TASK]: ModalAddTask,
    [MODALS.EDIT_TASK]: ModalEditTask,
    [MODALS.ADD_MILESTONE]: ModalAddMilestone,
    [MODALS.EDIT_MILESTONE]: ModalEditMilestone,
    [MODALS.ADD_PERCENTS]: ModalAddPercents,
    [MODALS.EDIT_PERCENTS]: ModalEditPercents,
    [MODALS.CONFIRM_DELETION]: ModalConfirmDeletion,
    [MODALS.CLONE_TASK]: ModalCloneTask,
    [MODALS.EDIT_DEMULTIPLE]: ModalDemultiple,
    [MODALS.EDIT_REGULARIZATION]: ModalRegularization,
    [MODALS.EDIT_POST_IMAGING]: ModalPostImagingProcessing,
    [MODALS.EDIT_MODEL_BUILDING]: ModalModelBuilding,
    [MODALS.EDIT_IMAGING]: ModalImaging,
    [MODALS.ADD_ACQUISITION]: ModalAddAcquisition,
    [MODALS.EDIT_ACQUISITION]: ModalEditAcquisition,
    [MODALS.MANAGE_PROJECT_ACQUISITIONS]: ModalManageProjectAcquisitions
};

const Modal = ({ clickOutsideRef, onClickOutside }) => {
    const dispatch = useDispatch();
    const { modal } = useSelector(modalSelector);
    const { modalPayload } = useSelector(modalPayloadSelector);

    const close = () => dispatch(closeModal());
    onClickOutside(close);

    let children = null;
    if (modal && modals[modal]) {
        const Comp = modals[modal];
        children = <Comp modalPayload={modalPayload} />;
    }

    return children ? (
        <div className="modal-wrapper">
            <div className={`modal ${modal}`} ref={clickOutsideRef}>
                <button onClick={close}>
                    <CrossIcon />
                </button>
                {children}
            </div>
        </div>
    ) : null;
};

Modal.propTypes = {
    clickOutsideRef: PropTypes.func,
    onClickOutside: PropTypes.func
};

export default withClickOutside(Modal);
