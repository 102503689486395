import React from 'react';
import {usersSelector} from '../../redux/lookups/lookupsSelectors';
import filter from '../../helpers/filter';
import { MODALS } from '../../components/modal/Modal';
import { centerSelector, centerIdFromRoute } from '../../redux/common';
import withWording, {WithWordingProps} from '../../components/HOC/withWording';
import { projectsSelectorForCenter } from '../../redux/projects/projectsSelectors';
import { filtersSelector, routeSelector } from '../../redux/route/routeSelectors';
import { openModal } from '../../redux/route/routeActions';
import {useSelector, useDispatch} from 'react-redux';
import ProjectsSummary from './ProjectsSummary';
import {fetch as fetchProjects} from '../../redux/projects/projectsActions';
import { PROJECT_SUMMARY } from '../../routes';
import withFetchOnLoad from '../../components/HOC/withFetchOnLoad';

const ProjectSummaryContainer = ({wording}) => {
    const dispatch = useDispatch();
    const {center} = useSelector(centerSelector);
    const {filters} = useSelector(filtersSelector);
    const {projects} = useSelector(projectsSelectorForCenter);
    const {users} = useSelector(usersSelector);
    const projectsFiltered = filter(projects, users, filters);

    const addProject = () => dispatch(openModal(MODALS.ADD_PROJECT));

    return (
        <ProjectsSummary addProject={addProject} projectWording={wording.project} center={center}
            projects={projectsFiltered}/>
    );
};

ProjectSummaryContainer.propTypes = {
    ...WithWordingProps
};

export default withWording('project')(withFetchOnLoad(
    ({
        centerId = useSelector(centerIdFromRoute).centerId,
        configRoute = useSelector(routeSelector).configRoute
    }) => ({
        centerId: centerId,
        fetch: ({centerId}) => fetchProjects((centerId) ? centerId : null),
        fetchCondition: configRoute === PROJECT_SUMMARY,
        fetchArgs: [centerId]
    })
)(ProjectSummaryContainer));
