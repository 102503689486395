import {
    ADD_ACQUISITION, EDIT_ACQUISITION, DELETE_ACQUISITION, FETCH, FETCH_SINGLE, LINK_ACQUISITION
} from './acquisitionsActions';
import { asyncActionSuccess, asyncActionError } from '../../helpers/asyncAction';
import update from 'immutability-helper';
import {indexFromAcquisitionId} from './acquisitionsSelectors';

const initialState = {
    list: [],
    filter: '',
    isLoaded: false
};

const mapAcquisition = response => response;
const mapAcquisitions = (acquisitions = []) => acquisitions.map(mapAcquisition);


export const addAcquisitionToState = (state, response) =>
    update(state, {
        list: {
            $push: [mapAcquisition(response)]
        }
    });

export const editAcquisitionInState = (state, body) => {

    const acquisitionIndex = indexFromAcquisitionId(body.id)(state);
    const acquisition = state.list[acquisitionIndex];
    return update(state, {
        list: {
            [acquisitionIndex]: {
                $set: {
                    ...mapAcquisition(body, acquisition),
                    projects: acquisition.projects
                }
            }
        }
    });
};

export const deleteAcquisitionInState = (state, body) => {
    const acquisitionIndex = indexFromAcquisitionId(body)(state);

    return update(state, {
        list: {
            $splice: [[ acquisitionIndex, 1 ]]
        }
    });
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH:
            return {
                ...state,
                isLoaded: false,
                list: []
            };

        case FETCH_SINGLE:
            return {
                ...state,
                isLoaded: false,
                acquisition: {}
            };

        case asyncActionSuccess(FETCH):
            return {
                ...state,
                isLoaded: true,
                list: mapAcquisitions(action.response)
            };

        case asyncActionSuccess(FETCH_SINGLE): {
            const acquisition = mapAcquisition(action.response);
            return {
                ...state,
                isLoaded: true,
                list: [acquisition],
                acquisition: acquisition
            };
        }

        case asyncActionSuccess(DELETE_ACQUISITION):
            return deleteAcquisitionInState(state, action.body);

        case asyncActionSuccess(ADD_ACQUISITION):
            return addAcquisitionToState(state, action.response);

        case asyncActionSuccess(EDIT_ACQUISITION):
            return editAcquisitionInState(state, action.body);

        case asyncActionSuccess(LINK_ACQUISITION):
            return {
                ...state
            };

        case asyncActionError(FETCH_SINGLE):
            return {
                ...state,
                acquisition: false,
                isLoaded: true
            };

        case asyncActionError(ADD_ACQUISITION):
        case asyncActionError(EDIT_ACQUISITION):
        case asyncActionError(DELETE_ACQUISITION): {
            const response = typeof action.response === 'object' && action.response.errors ?
                action.response :
                {
                    errors: null,
                    message: action.response?.message ? action.response.message : 'An unhandled server error occurred.'
                };
            return {
                ...state,
                ...response
            };
        }

        default:
            return {
                ...state,
                errors: null,
                message: null
            };
    }
};
