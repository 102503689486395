import { compose, pick, property } from 'lodash/fp';
import { USER } from './userActions';
import { RANKS } from './constants';

const stateSelector = property(USER);

export const isConnectedSelector = compose(
    pick('isConnected'),
    stateSelector
);

export const logoutIntendedSelector = compose(
    pick('logoutIntended'),
    stateSelector
);

export const lastActiveTimeSelector = compose(
    pick('lastActiveTime'),
    stateSelector  
);

export const isModeratorOrAdministratorSelector = compose(
    isModeratorOrAdministrator => ({ isModeratorOrAdministrator }),
    rank => rank === RANKS.MODERATOR || rank === RANKS.FINANCE || rank === RANKS.ADMINISTRATOR,
    property('rank'),
    stateSelector
);

export const rankSelector = compose(
    pick('rank'),
    stateSelector
);

export const isAdministratorSelector = compose(
    isAdministrator => ({ isAdministrator }),
    rank => rank === RANKS.ADMINISTRATOR,
    property('rank'),
    stateSelector
);

export const centerSelector = compose(
    pick('center'),
    stateSelector
);

export const errorSelector = compose(
    pick('error'),
    stateSelector
);

export const errorMessageSelector = compose(
    pick('message'),
    stateSelector
);

export const backendErrorSelector = compose(
    pick('backendError'),
    stateSelector
);
