import React from 'react';
import SideMenu from './SideMenu';
import ProjectRows from '../projects/ProjectRows';
import Link from '../router/Link';
import { projectsSelectorForCenter } from '../../redux/projects/projectsSelectors';
import withRoute from '../HOC/withRoute';
import { centerSelector } from '../../redux/common';
import { projectSummaryLink } from '../../routes';
import withWording from '../HOC/withWording';
import {useSelector} from 'react-redux';

const ProjectsSideMenu = props => {
    const {projects} = useSelector(projectsSelectorForCenter);
    const {center} = useSelector(centerSelector);

    const {selectedProject, wordingProjectSummaryTitle} = (({
        params,
        wording
    }) => ({
        selectedProject: parseInt(params.id),
        wordingProjectSummaryTitle: wording.project.summaryTitle
    }))(props);

    return (
        <SideMenu>

            <Link className="return-button" href={projectSummaryLink(center)}>{wordingProjectSummaryTitle}</Link>

            <ProjectRows
                center={center}
                list={projects}
                selectedId={selectedProject}
                noMilestones={true}
                noCog={true}
                small={true}/>
        </SideMenu>
    );
};

export default withWording('project')(withRoute(ProjectsSideMenu));
