import { compose, property } from 'lodash/fp';
import { CONFIG } from './configActions';
import { DISPLAY_CARDS } from './constants';

export const stateSelector = compose(
    property(CONFIG)
);

export const displaySelector = compose(
    display => ({ display }),
    property('display'),
    stateSelector
);

export const displayCardsSelector = compose(
    display => ({ displayCards: display === DISPLAY_CARDS }),
    property('display'),
    stateSelector
);

export const wordingSelector = compose(
    property('wording'),
    stateSelector
);

export const wordingKeysSelector = keys => compose(
    wording => ({ wording }),
    wording => keys.reduce(( acc, key ) => ({
        ...acc,
        [key]: property(key)(wording)
    }), {}),
    wordingSelector
);
