import React from 'react';
import { routeSelector } from '../../redux/route/routeSelectors';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const withRoute = WrappedComponent => {
    const ComponentWrappedWithWording = props => {
        const route = useSelector(routeSelector);
        return (
            <WrappedComponent {...props} {...route}/>
        );
    };

    return ComponentWrappedWithWording;
};

export const WithRouteProps = {
    queryParams: PropTypes.object,
    params: PropTypes.object
};

withRoute.propTypes = WithRouteProps;

export default withRoute;
