import React from 'react';
import {FormFieldDataTypes, FormFieldTypes} from './FormField';
import Form from './Form';
import {useDispatch, useSelector} from 'react-redux';
import { usersSelector, centersSelector } from '../../redux/lookups/lookupsSelectors';
import { addMilestoneAndCloseModal, editMilestoneAndCloseModal } from '../../redux/common';
import firstNotUndefined from '../../helpers/firstNotUndefined';
import PropTypes from 'prop-types';
import {errorMessageSelector, errorsSelector, projectSelectorFromMilestoneId}
    from '../../redux/projects/projectsSelectors';

const { CHECKBOX, HIDDEN, NUMBER, SELECT, SUBMIT, TEXT, TEXTAREA } = FormFieldTypes;

const FormMilestone = ({params: {id} = {}, values: {
    id: milestoneId,
    name,
    description,
    notes,
    supervisorId,
    siteId,
    datasetsDuring,
    datasetsAfter,
    includeInProjections
} = {}}) => {

    const dispatch = useDispatch();
    const {users} = useSelector(usersSelector);
    const {centers} = useSelector(centersSelector);
    const {project = {id: 0}} = useSelector(projectSelectorFromMilestoneId(milestoneId));
    const {errors} = useSelector(errorsSelector);
    const {message} = useSelector(errorMessageSelector);

    const addMilestone = data => dispatch(addMilestoneAndCloseModal(data));
    const editMilestone = data => dispatch(editMilestoneAndCloseModal(data));

    const onSubmit = (e, data) => {
        e.preventDefault();
        name ? editMilestone(data) : addMilestone(data);
    };

    const fields = [
        milestoneId ? {
            type: HIDDEN,
            name: 'id',
            id: 'milestone-id',
            value: milestoneId,
            dataType: FormFieldDataTypes.NUMBER
        } : null,
        {
            type: HIDDEN,
            name: 'project_id',
            id: 'milestone-project-id',
            value: project.id || id,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: TEXT,
            name: 'milestone_name',
            id: 'milestone-name',
            label: 'Name',
            value: name,
            validation: {
                required: true
            }
        },
        {
            type: TEXT,
            name: 'description',
            id: 'milestone-description',
            label: 'Milestone description',
            value: description
        },
        {
            type: TEXTAREA,
            name: 'notes',
            id: 'milestone-notes',
            label: 'Notes',
            size: 'full',
            value: notes,
            break: true
        },
        {
            type: SELECT,
            name: 'supervisor_id',
            id: 'milestone-supervisor-id',
            label: 'Supervisor',
            labelAsDefault: true,
            options: users.map(({description, id}) => ({
                label: description,
                value: id
            })),
            validation: {
                required: true
            },
            value: supervisorId,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'site_id',
            id: 'milestone-site-id',
            label: 'Site',
            labelAsDefault: true,
            options: centers.map(({description, id}) => ({
                label: description,
                value: id
            })),
            validation: {
                required: true
            },
            value: siteId,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'datasets_during',
            id: 'milestone-datasets-during',
            label: 'Input datasets during',
            value: firstNotUndefined(datasetsDuring, 1),
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: true
            }
        },
        {
            type: NUMBER,
            name: 'datasets_after',
            id: 'milestone-datasets-after',
            label: 'Input datasets after',
            value: firstNotUndefined(datasetsAfter, 1),
            dataType: FormFieldDataTypes.NUMBER,
            validation: {
                required: true
            }
        },
        {
            type: CHECKBOX,
            name: 'include_in_projections',
            id: 'task-in-projects',
            label: 'Include in projections',
            checked: firstNotUndefined(includeInProjections, true),
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: SUBMIT,
            value: name ? 'Save milestone' : 'Add milestone'
        }
    ];

    return (
        <Form fields={fields} onSubmit={onSubmit} errors={errors} message={message}/>
    );
};

FormMilestone.propTypes = {
    values: PropTypes.object,
    params: PropTypes.shape({
        id: PropTypes.number
    })
};

export default FormMilestone;
