export default function(obj) {

    let ol = 0;
    let ot = 0;

    if (obj.offsetParent) {

        /*eslint-disable */
        do {
            ol += obj.offsetLeft;
            ot += obj.offsetTop;
        } while (obj = obj.offsetParent);
        /*eslint-enable */
    }

    return {left : ol, top : ot};

}
