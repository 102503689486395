import '../../../scss/components/header/_display-picker.scss';
import React from 'react';
import PropTypes from 'prop-types';

import { DISPLAY_ROWS, DISPLAY_CARDS } from '../../redux/config/constants';
import GridIcon from '../../../img/grid.svg';
import RowIcon from '../../../img/rows.svg';

const DisplayPicker = ({ display, onClickRows, onClickGrid }) =>
    <div className="display-picker">

        <button
            className={ display === DISPLAY_CARDS ? 'is-active' : ''}
            title="Card View"
            onClick={ onClickGrid }>
            <GridIcon />
        </button>

        <button
            className={ display === DISPLAY_ROWS ? 'is-active' : ''}
            title="Table View"
            onClick={ onClickRows }>
            <RowIcon />
        </button>

    </div>;

DisplayPicker.propTypes = {
    display: PropTypes.oneOf([ DISPLAY_ROWS, DISPLAY_CARDS ]).isRequired,
    onClickRows: PropTypes.func.isRequired,
    onClickGrid: PropTypes.func.isRequired
};

export default DisplayPicker;
