import React from 'react';
import asyncAction from '../../helpers/asyncAction';
import config from '../../config';
import {closeModal} from '@/src/js/redux/route/routeActions';

export const USER = 'USER';
export const LOGIN = `${USER}/LOGIN`;
export const INFO = `${USER}/INFO`;
export const LOGOUT = `${USER}/LOGOUT`;
export const SET_LAST_ACTIVE_TIME = `${USER}/SET_LAST_ACTIVE_TIME`;

export const login = asyncAction(LOGIN, {
    method: 'POST',
    url: config.WEBSERVICES.LOGIN
});

export const info = asyncAction(INFO, {
    method: 'GET',
    url: config.WEBSERVICES.USER_INFO
});

export const logout = d => {
    d && d(closeModal());
    React.lazy(() => import('../store').then(m => m.persistor.purge()).finally());
    return ({
        type: LOGOUT
    });
};

export const setLastActiveTime = timestamp => ({
    type: SET_LAST_ACTIVE_TIME,
    lastActiveTime: timestamp
});
