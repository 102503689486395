'use client';

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SsoTokenContextType {
    token: string | null;
    setToken: (token: string | null) => void;
}

const SsoTokenContext = createContext<SsoTokenContextType | null>(null);

export const useSsoToken = () => useContext(SsoTokenContext);

type SsoTokenProviderProps = {
    children: ReactNode;
};

export const SsoTokenProvider = function({ children }: SsoTokenProviderProps) {
    const [ token, setToken ] = useState<string | null>(null);

    const contextValue: SsoTokenContextType = {
        token,
        setToken
    };

    return (
        <SsoTokenContext.Provider value={contextValue}>
            {children}
        </SsoTokenContext.Provider>
    );
};
