import React from 'react';
import PropTypes from 'prop-types';
import { percentsSelectorFromIds } from '../../redux/projects/projectsSelectors';
import withRoute from '../HOC/withRoute';
import FormPercents from '../form/FormPercents';
import {useSelector} from 'react-redux';

const ModalEditPercents = ({
    queryParams: {percents, task, taskPocTotal: milestoneTaskPocTotal},
    params: {project_id: projectId}
}) => {
    const {percents: percentsFromSelector} = useSelector(percentsSelectorFromIds(parseInt(task), parseInt(percents)));

    return (
        <div>
            <h2>Edit percent</h2>
            <FormPercents values={percentsFromSelector} milestoneTaskId={percentsFromSelector.taskId}
                projectId={projectId} milestoneTaskPocTotal={milestoneTaskPocTotal } />
        </div>
    );
};

ModalEditPercents.propTypes = {
    queryParams: PropTypes.shape({
        percents: PropTypes.any,
        task: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        taskPocTotal: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    }),
    params: PropTypes.shape({
        project_id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    })
};

export default withRoute(ModalEditPercents);
