import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { routeSelector } from '../../redux/route/routeSelectors';
import { set, setRoutes } from '../../redux/route/routeActions';
import Loader from '../base/Loader';
import {logoutIntendedSelector, errorMessageSelector} from '../../redux/user/userSelectors';

const Router = ({baseUrl, isLoaded, isConnected, routes}) => {
    const dispatch = useDispatch();
    const {route, configRoute, params, queryParams} = useSelector(routeSelector);

    const {message} = useSelector(errorMessageSelector);
    const {logoutIntended} = useSelector(logoutIntendedSelector);

    const setRoute = (route, silent) => dispatch(set(route, silent));
    const setRoutesD = current => {
        const _routes = [];
        let key;
        for (key in routes) {
            _routes.push(key);
        }

        dispatch(setRoutes(_routes, baseUrl, current));
    };

    React.useEffect(() => {
        setRoutesD(window.location.href);
        window.addEventListener('popstate', popState);

        return () => {
            window.removeEventListener('popstate', popState);
        };
    }, [window.location.href]);

    const popState = () => {
        setRoute(window.location.href, true);
    };

    if (isConnected) {
        if (configRoute) {
            const Comp = routes[configRoute];
            return <Comp route={ {
                route: route,
                params: params,
                queryParams: queryParams
            } } isLoaded={isLoaded} />;
        }
        return <Loader/>;
    } else {
        return (
            <>
                {message &&
                    <div className="error-message">{message}</div>
                }
                <div className="loggedOut">
                    <h1>You are signed out</h1>
                    {logoutIntended ?
                        <p>Thank you. You may sign in again by reloading the page.</p> :
                        <p>Your account may not have been granted access to IRIS. Please sign in with another account at the top right.
                            If you feel this is incorrect, please contact service desk and include any error messages that may be visible.</p>
                    }
                </div>
            </>
        );
    }
};

export const routeShape = PropTypes.shape({
    params: PropTypes.object
});

Router.propTypes = {
    baseUrl: PropTypes.string,
    routes: PropTypes.object,
    isLoaded: PropTypes.bool,
    isConnected: PropTypes.bool
};

export default Router;
