"use client";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSsoToken } from "./jwtSsoContext";
import Button from "./button";
import { AccountInfo } from "@azure/msal-common";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useMsalAuthentication,
} from "@azure/msal-react";
import {
    InteractionType,
    SilentRequest,
    InteractionRequiredAuthError,
    EventType,
} from "@azure/msal-browser";
import {useDispatch, useSelector} from "react-redux";
import {info} from "@/src/js/redux/user/userActions";
import {set as setRoute} from "@/src/js/redux/route/routeActions";
import {projectSummaryLink} from "@/src/js/routes";
import {routeSelector} from "@/src/js/redux/route/routeSelectors";
import {isConnectedSelector} from "@/src/js/redux/user/userSelectors";

type MsalLoginProps = {
    requestSilent?: SilentRequest;
    roles?: Array<string>;
};

const MsalLogin = function ({ roles = ["iris.manager"] }: MsalLoginProps) {
    const dispatch = useDispatch();
    const {route} = useSelector(routeSelector);
    const {isConnected} = useSelector(isConnectedSelector);

    const tokenContext = useSsoToken();
    const requestSilent = useRef<SilentRequest>({scopes: ["User.Read"]});
    const requestPrompt = {
        scopes: ["User.Read"],
        prompt: "select_account",
        loginHint: "",
    };

    const {login, error} = useMsalAuthentication(
        InteractionType.Silent,
        requestSilent.current
    );

    const {instance} = useMsal();
    const [isIrisUser, setIsIrisUser] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const currentAccount = useRef<AccountInfo>();

    useEffect(() => {
        if (sessionStorage) {
            const account = sessionStorage.getItem("iris.currentAccount");
            if (account) {
                currentAccount.current = JSON.parse(account);
                acquireToken();
            }
        }
    });

    const isUserAuthorized = useCallback(() => {
        let authorized = false;
        if (
            currentAccount.current &&
            currentAccount.current.idTokenClaims &&
            currentAccount.current.idTokenClaims["roles"]
        ) {
            const accountRoles = currentAccount.current.idTokenClaims["roles"];
            const intersection = roles.filter((role: string) =>
                accountRoles.includes(role)
            );

            if (intersection.length > 0) {
                authorized = true;
            }
        }
        return authorized;
    }, [currentAccount, roles]);

    const acquireToken = useCallback(() => {
        if (typeof currentAccount != 'undefined' && currentAccount.current) {
            instance
                .acquireTokenSilent({
                    scopes: ["api://c04f40b8-4443-4a55-9d74-98e13efd4fb1/user_impersonation"], //Scope need to be the API exposed by the app in AAD
                    account: currentAccount.current,
                })
                .then((response) => {
                    if (response) {
                        if (tokenContext) {
                            tokenContext.setToken(response.accessToken);
                            setAccessToken(response.accessToken);
                        }
                    }
                })
                .catch((error) => {
                    console.log("Could not generate a token:" + error.message)
                });
        }
    }, [instance, tokenContext]);

    const handleAccountSelection = useCallback(
        (account: AccountInfo) => {
            instance.setActiveAccount(account);
            currentAccount.current = account;
            sessionStorage.setItem("iris.currentAccount", JSON.stringify(account));
            acquireToken();
        },
        [instance, isUserAuthorized, acquireToken]
    );

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, requestSilent.current).then(() => null);
        }
        const callbackId = instance.addEventCallback((event: object) => {
            // Set active account on initial silent auth
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const {interactionType, eventType, payload} = event;
            if (
                interactionType === InteractionType.Silent &&
                (eventType === EventType.LOGIN_SUCCESS ||
                    eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                    eventType === EventType.SSO_SILENT_SUCCESS) &&
                payload && payload.account && !currentAccount.current
            ) {
                handleAccountSelection(payload.account);
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [
        login,
        error,
        requestSilent,
        instance,
        handleAccountSelection,
        currentAccount,
    ]);

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: object) => {
            // set active account after auth redirect
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const {interactionType, eventType, payload} = event;
            if (
                interactionType === InteractionType.Redirect &&
                (eventType === EventType.LOGIN_SUCCESS || eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                payload && payload.account
            ) {
                handleAccountSelection(payload.account);
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, roles, handleAccountSelection]);

    useEffect(() => {
        if (accessToken) {
            const callback = (d, response) => {
                setIsIrisUser(true);
                // Redirect to the user's center if blank route.
                if (!route.startsWith('/app/') && response.center)
                    d(setRoute(projectSummaryLink(response.center.toLowerCase())));
            };
            dispatch(info(null, callback, {'Authorization': `Bearer ${accessToken}`}));
        }
    }, [accessToken]);

    function handleSwithUser() {
        instance.loginRedirect(requestPrompt).then(() => null);
    }

    const notSignedIn = () => <>
        <h2>Not signed in</h2>
        <p>Please sign in to access IRIS.</p>
    </>;

    return (
        <>
            <AuthenticatedTemplate>
                {isIrisUser && isConnected ?
                <div>
                    <h2>Signed in as: </h2>
                    {currentAccount.current ? (
                        currentAccount.current.name ? (
                            <div>
                                <ul>
                                    <li>Name: {currentAccount.current.name}</li>
                                    <li>Email: {currentAccount.current.username}</li>
                                </ul>
                            </div>
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                </div> : notSignedIn()}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {notSignedIn()}>
            </UnauthenticatedTemplate>
            <br/>
            <Button buttonText="Sign in new user" onClick={handleSwithUser}/>
        </>
    );
};

export default MsalLogin;
