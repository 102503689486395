import update from 'immutability-helper';
import { mapPctOfCmps } from '../map';
import { indexesFromPercentId, indexesFromTaskId } from '../projectsSelectors';


export const addPercentsToState = (state, response) => {

    const {
        projectIndex,
        milestoneIndex,
        taskIndex
    } = indexesFromTaskId(response.milestone_task_id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            [taskIndex]: {
                                pctOfCmps: {
                                    $push: [mapPctOfCmps(response)]
                                }
                            }
                        }
                    }
                }
            }
        }
    });

};

/*
export const editPercentsInState = (state, body) => {
    const {
        projectIndex,
        milestoneIndex,
        taskIndex,
        percentIndex
    } = indexesFromPercentId(body.id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            [taskIndex]: {
                                pctOfCmps: {
                                    [percentIndex]: {
                                        $merge: {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    });
};
*/

export const editPercentsInState = (state, body) => {
    const {
        projectIndex,
        milestoneIndex,
        taskIndex,
        percentIndex
    } = indexesFromPercentId(body.id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            [taskIndex]: {
                                pctOfCmps: {
                                    [percentIndex]: {
                                        $set: mapPctOfCmps(body)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    });
};

export const deletePercentsInState = (state, body) => {
    const {
        projectIndex,
        milestoneIndex,
        taskIndex,
        percentIndex
    } = indexesFromPercentId(body.id)(state);

    return update(state, {
        list: {
            [projectIndex]: {
                milestones: {
                    [milestoneIndex]: {
                        tasks: {
                            [taskIndex]: {
                                pctOfCmps: {
                                    $splice: [
                                        [ percentIndex, 1 ]
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        }
    });
};
