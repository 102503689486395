import '../../../scss/views/_projects-summary.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Acquisitions from '../../components/acquisitions/Acquisitions';
import Toolbar from '../../components/toolbar/Toolbar';


const AcquisitionsSummary = ({addAcquisition, acquisitionWording, acquisitions = []}) =>
    <main className="main" id="view-acquisitions-summary">
        <header>
            <Toolbar
                add={addAcquisition}
                title={acquisitionWording?.summaryTitle}
                addWording={acquisitionWording?.add}
                filters={[
                    {
                        label: 'Project code',
                        value: 'pgs_acquisition_code'
                    },
                    {
                        label: 'Project name',
                        value: 'acquisition_project_name'
                    },
                    {
                        label: 'Vessel name',
                        value: 'vessel_name'
                    }
                ]}
            />
        </header>
        <Acquisitions list={acquisitions}/>
    </main>;


AcquisitionsSummary.propTypes = {
    acquisitions: PropTypes.arrayOf(PropTypes.any),
    addAcquisition: PropTypes.func,
    acquisitionWording: PropTypes.object
};

export default AcquisitionsSummary;
