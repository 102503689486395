import App from './js/App';
import React from 'react';
import ReactDOM from 'react-dom';
import config from './js/config';
ReactDOM.render(
    <React.Fragment>
        <App/>
    </React.Fragment>,
    document.getElementById(config.APP_ID)
);
