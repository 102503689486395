export const msalConfig = {
    auth: {
        clientId: 'c04f40b8-4443-4a55-9d74-98e13efd4fb1',
        authority:
            'https://login.microsoftonline.com/51d05d61-47e9-480b-93b2-98dc84f1ed06', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.location.origin + '/'
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [ 'openid', 'profile' ]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com'
};
