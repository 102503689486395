import React from 'react';
import {useSelector} from 'react-redux';

import { displayCardsSelector } from '../../../redux/config/configSelectors';
import Cards from '../Cards';
import PercentRows from './PercentRows';
import PropTypes from 'prop-types';

const Percents = props  => {
    const {displayCards} = useSelector(displayCardsSelector);
    const {task = {pctOfCmps: []}} = props;
    const {pctOfCmps} = task;


    return (
        displayCards ?
            <Cards list={pctOfCmps} type="percent"/>
            : <PercentRows {...props}/>
    );
};

Percents.propTypes = {
    task: PropTypes.shape({
        pctOfCmps: PropTypes.array
    })
};

export default Percents;
