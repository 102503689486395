import '../../../scss/components/base/_button.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Button = ({children, className, onClick }) =>
    <button onClick={onClick} className={`button ${ className || ''}`}>
        {children}
    </button>;

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default Button;
