import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {openModal} from '../../../redux/route/routeActions';
import {MODALS} from '../../modal/Modal';
import Table from '../../Table';
import MilestoneMenu from '../../contextMenu/MilestoneMenu';
import { usersSelector } from '../../../redux/lookups/lookupsSelectors';
import { getMilestoneColumns, getMilestoneRows } from '../../../helpers/milestoneRows';
import { isModeratorOrAdministratorSelector } from '../../../redux/user/userSelectors';
import { inlineEditMilestone } from '../../../redux/projects/projectsActions';
import { inlineEditsSelector } from '../../../redux/projects/projectsSelectors';


const contextMenu = ({ id }) => <MilestoneMenu milestone={id} />;

const isEditing = ({ id }, key, inlineEdits) =>
    inlineEdits.milestones[id] &&
    typeof inlineEdits.milestones[id][key] !== 'undefined';

const MilestoneRows = props => {
    const dispatch = useDispatch();

    const mappedProps = (({
        addRow,
        isProjectSummary,
        list = [],
        selectedMilestoneId,
        onClick,
        users,
        isModeratorOrAdministrator,
        inlineEditMilestone,
        inlineEdits
    }) => ({
        columns: getMilestoneColumns(
            isModeratorOrAdministrator,
            users,
            inlineEditMilestone,
            (data, key) => isEditing(data, key, inlineEdits)
        ),
        rows: getMilestoneRows(isModeratorOrAdministrator),
        defaultSortedRow: 'milestoneStartDate',
        data: list.map(({
            supervisorId,
            ...rest
        }) => ({
            ...rest,
            supervisorId,
            supervisor: (users.find(({ id }) => id === supervisorId) != null) ? users.find(({ id }) => id === supervisorId).description : null
        })),
        contextMenu: isModeratorOrAdministrator ? contextMenu: null,
        isProjectSummary: isProjectSummary,
        addRow: isModeratorOrAdministrator  ? addRow: null,
        className: 'milestone-rows',
        selectedRow: milestone => selectedMilestoneId === milestone.id,
        onRowClick: onClick ? data => onClick(data.id) : null
    })
    )({...props,
        ...useSelector(usersSelector),
        ...useSelector(isModeratorOrAdministratorSelector),
        ...useSelector(inlineEditsSelector),
        addRow: () => dispatch(openModal(MODALS.ADD_MILESTONE)),
        inlineEditMilestone: (data, value, key) => dispatch(inlineEditMilestone(data, value, key))
    });

    return (
        <Table {...mappedProps}/>
    );
};

export default MilestoneRows;
