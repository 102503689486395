const percent = (part, whole) => isNaN(part) || isNaN(whole) || whole === 0 ? 0 : Math.round(part / whole * 100);

export const STATUS = {
    GREEN: 2,
    YELLOW: 1,
    RED: 0
};

const statusFromData = (percentCompleted, startDate, endDate) => {
    if (!endDate || !startDate) {
        return STATUS.GREEN;
    }
    const overall = endDate.getTime() - startDate.getTime();
    const done = Math.max(0, new Date().getTime() - startDate.getTime());
    const perfectPercentDone = Math.min( 100, overall / 100 * done) || 100;
    const diff = perfectPercentDone - percentCompleted;
    if (diff > 0 && overall === 0) return STATUS.YELLOW;
    return diff < 20 ? STATUS.GREEN : diff > 20 ? STATUS.RED : STATUS.YELLOW;
};

const computeTask = ({
    pctOfCmps = [],
    projStartDate,
    projEndDate,
    soldRevenue,
    ...rest
}) =>  {
    const percentCompleted = pctOfCmps.reduce((acc, {percent}) => acc + percent, 0);
    return {
        percentCompleted,
        pctOfCmps,
        status: statusFromData(percentCompleted, projStartDate, projEndDate),
        projStartDate,
        projEndDate,
        perDone: soldRevenue * percentCompleted / 100,
        soldRevenue,
        ...rest
    };

};

const computeMilestone = ({
    tasks = [],
    ...rest
}) => {
    const computedTask = tasks.map(computeTask);
    const milestone = computedTask
        .reduce(({
            milestoneSoldRevenue,
            milestoneSoldComputeHours,
            milestoneProjectedComputeHours,
            milestoneSoldComputeRate,
            milestoneProjectedComputeRate,
            milestoneSoldGeoMonths,
            milestoneProjectedGeoMonths,
            milestoneSoldGeoRate,
            milestoneProjectedGeoRate,
            milestoneStartDate,
            milestoneEndDate,
            milestonePerDone
        }, {
            soldRevenue,
            soldComputeHours,
            projectedComputeHours,
            soldComputeRate,
            projectedComputeRate,
            soldGeoMonths,
            projectedGeoMonths,
            soldGeoRate,
            projectedGeoRate,
            projStartDate,
            projEndDate,
            perDone
        }) => ({
            milestoneSoldRevenue: milestoneSoldRevenue + soldRevenue,
            milestoneSoldComputeHours: milestoneSoldComputeHours + soldComputeHours,
            milestoneProjectedComputeHours: milestoneProjectedComputeHours + projectedComputeHours,
            milestoneSoldComputeRate: milestoneSoldComputeRate + soldComputeRate,
            milestoneProjectedComputeRate: milestoneProjectedComputeRate + projectedComputeRate,
            milestoneSoldGeoMonths: milestoneSoldGeoMonths + soldGeoMonths,
            milestoneProjectedGeoMonths: milestoneProjectedGeoMonths + projectedGeoMonths,
            milestoneSoldGeoRate: milestoneSoldGeoRate + soldGeoRate,
            milestoneProjectedGeoRate: milestoneProjectedGeoRate + projectedGeoRate,
            milestoneStartDate: milestoneStartDate &&
            projStartDate.getTime() > milestoneStartDate.getTime()
                ? milestoneStartDate
                : projStartDate,
            milestoneEndDate: milestoneEndDate &&
            projEndDate.getTime() < milestoneEndDate.getTime()
                ? milestoneEndDate
                : projEndDate,
            milestonePerDone: milestonePerDone + perDone
        }), {
            milestoneSoldRevenue: 0,
            milestoneSoldComputeHours: 0,
            milestoneProjectedComputeHours: 0,
            milestoneSoldComputeRate: 0,
            milestoneProjectedComputeRate: 0,
            milestoneSoldGeoMonths: 0,
            milestoneProjectedGeoMonths: 0,
            milestoneSoldGeoRate: 0,
            milestoneProjectedGeoRate: 0,
            milestonePerDone: 0
        });
    milestone.milestoneGeoMonthsDiff = milestone.milestoneSoldGeoMonths - milestone.milestoneProjectedGeoMonths;
    milestone.milestoneComputeHoursDiff = milestone.milestoneSoldComputeHours - milestone.milestoneProjectedComputeHours;
    milestone.milestoneComputeHoursVar = percent(milestone.milestoneComputeHoursDiff, milestone.milestoneSoldComputeHours);
    milestone.milestoneGeoMonthsVar = percent(milestone.milestoneGeoMonthsDiff, milestone.milestoneSoldGeoMonths);
    milestone.milestonePercentCompleted = percent( milestone.milestonePerDone, milestone.milestoneSoldRevenue );

    if (!milestone.milestoneEndDate) milestone.milestoneEndDate = new Date();
    if (!milestone.milestoneStartDate) milestone.milestoneStartDate = new Date();

    return {
        ...milestone,
        tasks: computedTask,
        status: statusFromData(milestone.milestonePercentCompleted, milestone.milestoneStartDate, milestone.milestoneEndDate),
        ...rest
    };
};


export const computeProject = ({milestones = [], stages = [], ...rest}) => {

    const computedMilestones = milestones.map(computeMilestone);
    const project = computedMilestones.reduce(({
        projectSoldRevenue,
        projectGeoMonthsDiff,
        projectSoldGeoMonths,
        projectComputeHoursDiff,
        projectSoldComputeHours,
        projectStartDate,
        projectEndDate,
        projectPerDone
    }, {
        milestoneSoldRevenue,
        milestoneGeoMonthsDiff,
        milestoneSoldGeoMonths,
        milestoneComputeHoursDiff,
        milestoneSoldComputeHours,
        milestoneStartDate,
        milestoneEndDate,
        milestonePerDone
    }) => ({
        projectSoldRevenue: projectSoldRevenue + milestoneSoldRevenue,
        projectGeoMonthsDiff: projectGeoMonthsDiff + milestoneGeoMonthsDiff,
        projectSoldGeoMonths: projectSoldGeoMonths + milestoneSoldGeoMonths,
        projectComputeHoursDiff: projectComputeHoursDiff + milestoneComputeHoursDiff,
        projectSoldComputeHours: projectSoldComputeHours + milestoneSoldComputeHours,
        projectStartDate: projectStartDate && milestoneStartDate &&
        milestoneStartDate.getTime() > projectStartDate.getTime()
            ? projectStartDate
            : milestoneStartDate,
        projectEndDate: projectEndDate && milestoneEndDate &&
        milestoneEndDate.getTime() < projectEndDate.getTime()
            ? projectEndDate
            : milestoneEndDate,
        projectPerDone: projectPerDone+ milestonePerDone
    }), {
        projectSoldRevenue: 0,
        projectGeoMonthsDiff: 0,
        projectSoldGeoMonths: 0,
        projectComputeHoursDiff: 0,
        projectSoldComputeHours: 0,
        projectStartDate: 0,
        projectEndDate: 0,
        projectPerDone: 0
    });
    project.projectComputeHoursVar = percent(project.projectComputeHoursDiff, project.projectSoldComputeHours);
    project.projectGeoMonthsVar = percent(project.projectGeoMonthsDiff, project.projectSoldGeoMonths);
    project.projectPercentCompleted = percent(project.projectPerDone, project.projectSoldRevenue);
    return {
        ...project,
        milestones : computedMilestones,
        stages : stages,
        status: statusFromData(project.projectPercentCompleted, project.projectStartDate, project.projectEndDate),
        ...rest
    };
};

export const computeProjects = (projects = []) => projects.map(computeProject);
