import { asyncActionError, asyncActionSuccess } from '../../helpers/asyncAction';
import { LOGIN, INFO, LOGOUT, SET_LAST_ACTIVE_TIME } from './userActions';
import { RANKS } from './constants';

const initialState = {
    isConnected: false,
    lastActiveTime: Date.now(),
    rank: RANKS.DEFAULT,
    center: null,
    error: false,
    message: null,
    logoutIntended: false,
    backendError: false
};

const CONNECTION = 'CONNECTION';

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case LOGIN:
            return  {
                ...state,
                error: false,
                logoutIntended: false,
                lastActiveTime: Date.now()
            };
        case asyncActionSuccess(LOGIN):
            return {
                ...state,
                rank: action.response && action.response.user_type || RANKS.DEFAULT,
                center: action.response && action.response.center,
                logoutIntended: false,
                isConnected: true
            };
        case asyncActionError(LOGIN):
            return {
                ...state,
                error: true,
                logoutIntended: false,
                backendError: false
            };
        case INFO:
            return {
                ...state,
                error: false,
                message: null,
                logoutIntended: false,
                lastActiveTime: Date.now()
            };
        case asyncActionSuccess(INFO):
            return {
                ...state,
                rank: action.response && action.response.user_type || RANKS.DEFAULT,
                center: action.response && action.response.center,
                logoutIntended: false,
                isConnected: true
            };
        case asyncActionError(INFO):
            return {
                ...state,
                error: true,
                message: action.response && action.response.error_message,
                backendError: false,
                isConnected: false,
                logoutIntended: false,
                center: null,
                rank: null
            };
        case asyncActionError(CONNECTION):
            return {
                ...state,
                error: true,
                backendError: true
            };
        case LOGOUT:
            localStorage.removeItem('bypassLogin');
            sessionStorage.removeItem('iris.currentAccount');
            return {
                ...state,
                isConnected: false,
                logoutIntended: true,
                error: false,
                message: null,
                lastActiveTime: 0
            };
        case SET_LAST_ACTIVE_TIME:
            return {
                ...state,
                lastActiveTime: action.lastActiveTime
            };
    }
    return state;
};
