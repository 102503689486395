import React from 'react';
import PropTypes from 'prop-types';
import FormMilestone from '../form/FormMilestone';
import { useSelector } from 'react-redux';
import { milestoneSelectorFromRouteId } from '../../redux/projects/projectsSelectors';

const ModalEditMilestone = () => {
    const {milestone} = useSelector(milestoneSelectorFromRouteId);

    return (
        <div>
            <h2>Edit milestone</h2>
            <FormMilestone values={milestone} />
        </div>
    );
};

ModalEditMilestone.propTypes = {
    milestone: PropTypes.any
};

export default ModalEditMilestone;
