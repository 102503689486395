import React from 'react';
import PropTypes from 'prop-types';
import FormPercents from '../form/FormPercents';
import withRoute from '../HOC/withRoute';

const ModalAddPercents = ({ queryParams:  {task: milestoneTaskId, taskPocTotal: milestoneTaskPocTotal} }) => <div>
    <h2>Add percents</h2>
    <FormPercents milestoneTaskId={milestoneTaskId} milestoneTaskPocTotal={milestoneTaskPocTotal} />
</div>;

ModalAddPercents.propTypes = {
    queryParams: PropTypes.shape({
        task: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
        taskPocTotal: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    })
};

export default withRoute(ModalAddPercents);
