import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';
import TaskMenu from '../../contextMenu/TaskMenu';
import {useDispatch, useSelector} from 'react-redux';
import {openModal} from '../../../redux/route/routeActions';
import {MODALS} from '../../modal/Modal';
import {
    algorithmsSelector,
    resourcesSelector,
    validatorsSelector
} from '../../../redux/lookups/lookupsSelectors';
import {isModeratorOrAdministratorSelector} from '../../../redux/user/userSelectors';
import {getTaskColumns, getTaskRows} from '../../../helpers/taskRows';
import {inlineEditsSelector, milestonesSelectorFromRouteId} from '../../../redux/projects/projectsSelectors';
import {inlineEditTask} from '../../../redux/projects/projectsActions';

const contextMenu = ({id}) => <TaskMenu task={id}/>;
const isEditing = ({id}, key, inlineEdits) =>
    inlineEdits.tasks[id] &&
    typeof inlineEdits.tasks[id][key] !== 'undefined';

contextMenu.propTypes = {
    id: PropTypes.number
};

const TaskRows = props => {
    const dispatch = useDispatch();

    const mappedProps = (({
        addRow,
        list = [],
        selectedTaskId,
        onClick,
        algorithms,
        resources,
        milestones,
        validators,
        isModeratorOrAdministrator,
        inlineEditTask,
        inlineEdits
    }) => ({
        columns: getTaskColumns(
            isModeratorOrAdministrator,
            algorithms,
            resources,
            milestones,
            validators,
            inlineEditTask,
            (data, key) => isEditing(data, key, inlineEdits)
        ),
        data: list.map(({
            algorithmId,
            computeResourceId,
            validatorId,
            deputyValidatorId,
            milestoneId,
            ...rest
        }) => ({
            ...rest,
            algorithmId,
            computeResourceId,
            validatorId,
            deputyValidatorId,
            milestoneId,
            algorithm: (algorithms.find(({id}) => id === algorithmId) || {}).description,
            resource: (resources.find(({id}) => id === computeResourceId) || {}).description,
            milestone: milestones.find(({id}) => id === milestoneId).name,
            validator: (validators.find(({id}) => id === validatorId) || {}).name,
            deputyValidator: (validators.find(({id}) => id === deputyValidatorId) || {}).name
        })),
        rows: getTaskRows(isModeratorOrAdministrator),
        defaultSortedRow: 'startDate',
        contextMenu: isModeratorOrAdministrator ? contextMenu : null,
        addRow: isModeratorOrAdministrator ? addRow : null,
        className: 'task-rows',
        selectedRow: task => selectedTaskId === task.id,
        onRowClick: onClick ? data => onClick(data.id) : null
    })
    )({
        ...props,
        ...useSelector(milestonesSelectorFromRouteId),
        ...useSelector(isModeratorOrAdministratorSelector),
        ...useSelector(inlineEditsSelector),
        ...useSelector(algorithmsSelector),
        ...useSelector(resourcesSelector),
        ...useSelector(validatorsSelector),
        addRow: (({selectedMilestoneId}) =>
            () => dispatch(openModal(MODALS.ADD_TASK, {selectedMilestoneId}))
        )(props),
        inlineEditTask: (data, value, key) => dispatch(inlineEditTask(data, value, key))
    });

    return (
        <Table {...mappedProps}/>
    );
};

export default TaskRows;
