import update from 'immutability-helper';
import { indexFromProjectId } from '../projectsSelectors';

export const editProjectStageInState = (state, body) => {
    const {projectId, stageId, ...payload} = body;
    const projectIndex = indexFromProjectId(projectId)(state);
    const project = state.list[projectIndex];
    const currentProjectStage = project.stages.find(s => s.stage_id === stageId);
    const newProjectStage = currentProjectStage ? {...currentProjectStage} : {stage_id: stageId};
    newProjectStage.fields = payload.fields;


    return update(state, {
        list: {
            [projectIndex]: {
                $set: {
                    ...project,
                    stages: project.stages.filter(s => s.stage_id !== stageId).concat([newProjectStage])
                }
            }
        }
    });
};
