import xhr from './xhr';
import {startLoading, stopLoading} from './loading';

export const asyncActionSuccess = name => `${name}/SUCCESS`;
export const asyncActionError = name => `${name}/ERROR`;

const asyncAction = (actionName, xhrOptions) =>
    (body, callback, headers={}) =>
        dispatch => {
            dispatch({ type: actionName });
            startLoading();
            xhr({
                method: xhrOptions.method,
                url: xhrOptions.url,
                data: body,
                contentType: 'application/json',
                headers: headers,
                success: response => {
                    stopLoading();
                    dispatch({ type: asyncActionSuccess(actionName), response, body });
                    if (typeof callback === 'function') {
                        callback(dispatch, response);
                    }
                },
                error: (response, request) => {
                    stopLoading();
                    let errorType = (request.status != 0) ? asyncActionError(actionName) : asyncActionError('CONNECTION');
                    dispatch({ type: errorType, response });
                }
            });
        };

export const asyncActionQueue = (actionName, queue) => dispatch =>
    handleQueue(actionName, dispatch, queue);

const handleQueue = (actionName, dispatch, queue) => {
    if (queue.length) {
        const current = queue.shift();
        return dispatch( current.action( current.payload, () => handleQueue(actionName, dispatch, queue) ) );
    } else {
        return dispatch({ type: asyncActionSuccess(actionName ) });
    }
};


export default asyncAction;
