import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

export const WithFetchOnLoadProps = {
    fetch: PropTypes.func,
    fetchArgs: PropTypes.array,
    fetchCondition: PropTypes.bool
};

const withFetchOnLoad = propsMapper => WrappedComponent => {
    const ComponentWrappedWithFetchOnLoad = props => {
        // console.log(props);
        // Refresh on initial load of this project summary for the given center
        const ownProps = propsMapper(props);
        const dispatch = useDispatch();
        const {fetchArgs, fetchCondition, fetch} = ownProps;

        fetchCondition && React.useEffect(() => {
            dispatch(fetch(ownProps));
        }, fetchArgs);

        return (
            <WrappedComponent {...props} {...ownProps}/>
        );
    };

    ComponentWrappedWithFetchOnLoad.displayName = `WithFetchOnLoad(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    ComponentWrappedWithFetchOnLoad.propTypes = WithFetchOnLoadProps;

    return ComponentWrappedWithFetchOnLoad;
};

withFetchOnLoad.propTypes = {
    WrappedComponent: PropTypes.element,
    ...WithFetchOnLoadProps
};

export default withFetchOnLoad;
