import React from 'react';
import FormRegularization from '../form/FormRegularization';
import {useSelector} from 'react-redux';
import {projectSelectorFromRouteId} from '../../redux/projects/projectsSelectors';

const ModalRegularization = () => {
    const {project} = useSelector(projectSelectorFromRouteId);

    return (
        <div>
            <h2>Edit Regularization Stage for {project.name}</h2>
            <FormRegularization />
        </div>
    );
};

export default ModalRegularization;
