import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import { isUndefined } from 'lodash/fp';
import {FormFieldDataTypes, FormFieldTypes} from './FormField';
import Form from './Form';
import { algorithmsSelector, positionsSelector, resourcesSelector, validatorsSelector } from '../../redux/lookups/lookupsSelectors';
import {
    errorMessageSelector,
    errorsSelector,
    milestonesSelectorFromRouteId
} from '../../redux/projects/projectsSelectors';
import { addTaskAndCloseModal, editTaskAndCloseModal, cloneTaskAndCloseModal } from '../../redux/common';

const { CHECKBOX, DATE, HIDDEN, NUMBER, SELECT, SUBMIT, TEXT, TEXTAREA } = FormFieldTypes;

const FormTask = ({values = {}, projectId, milestoneId: selectedMilestoneId}) => {
    const dispatch = useDispatch();
    const {algorithms} = useSelector(algorithmsSelector);
    const {positions} = useSelector(positionsSelector);
    const {resources} = useSelector(resourcesSelector);
    const {validators} = useSelector(validatorsSelector);
    const {milestones} = useSelector(milestonesSelectorFromRouteId);

    const {errors} = useSelector(errorsSelector);
    const {message} = useSelector(errorMessageSelector);

    const addTask = data => dispatch(addTaskAndCloseModal(data));
    const cloneTask = data => dispatch(cloneTaskAndCloseModal(data));
    const editTask = data => dispatch(editTaskAndCloseModal(data));

    const onSubmit = (e, data) => {
        e.preventDefault();
        values.isClone ? cloneTask(data) :
            (values.name  ? editTask(data) : addTask(data));
    };
    const fields = [
        values ? {
            type: HIDDEN,
            name: 'id',
            id: 'task-id',
            value: values.id,
            dataType: FormFieldDataTypes.NUMBER
        } : null,
        {
            type: HIDDEN,
            name: 'current_status',
            id: 'task-current-status',
            value: 0,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: HIDDEN,
            name: 'percent_completed',
            id: 'task-percent-completed',
            value: 0,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: HIDDEN,
            name: 'project_id',
            id: 'task-project-id',
            value: projectId,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: TEXT,
            name: 'milestone_task_name',
            id: 'task-name',
            label: 'Task name',
            value: values.name,
            validation: {
                required: true
            }
        },
        {
            type: NUMBER,
            name: 'sold_revenue',
            id: 'task-revenue',
            label: 'Sold revenue',
            value: values.soldRevenue,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: DATE,
            name: 'proj_start_date',
            id: 'proj-task-start',
            label: 'Projected start date',
            value: values.projStartDate,
            validation: {
                required: true
            },
            break: true
        },
        {
            type: DATE,
            name: 'proj_end_date',
            id: 'proj-task-end',
            label: 'Projected end date',
            value: values.projEndDate,
            validation: {
                required: true
            }
        },
        {
            type: DATE,
            name: 'sold_start_date',
            id: 'sold-task-start',
            label: 'Sold start date',
            value: values.soldStartDate,
            validation: {
                required: true
            },
            break: true
        },
        {
            type: DATE,
            name: 'sold_end_date',
            id: 'sold-task-end',
            label: 'Sold end date',
            value: values.soldEndDate,
            validation: {
                required: true
            }
        },
        {
            type: SELECT,
            name: 'milestone_id',
            id: 'task-milestone-id',
            label: 'Milestone',
            labelAsDefault: true,
            value: values.milestoneId || selectedMilestoneId,
            options: milestones.map(({ name, id }) => ({
                label: name,
                value: id
            })),
            validation: {
                required: true
            },
            size: 'full',
            break: true,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: TEXTAREA,
            name: 'description',
            id: 'task-description',
            label: 'Description',
            value: values.description,
            size: 'full',
            break: true
        },
        {
            type: SELECT,
            name: 'compute_resource_id',
            id: 'task-compute-resource',
            label: 'Resource',
            labelAsDefault: true,
            value: values.computeResourceId,
            options:  resources.map(({ id, description }) => ({ label: description, value: id })),
            break: true,
            dataType: FormFieldDataTypes.NUMBER,
            rawLookups: resources
        },
        {
            type: SELECT,
            name: 'algorithm_id',
            id: 'task-algorithm',
            label: 'Algorithm',
            labelAsDefault: true,
            value: values.algorithmId,
            options: algorithms.map(({ id, description }) => ({ label: description, value: id })),
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'validator_id',
            id: 'task-validator',
            label: 'Validator',
            labelAsDefault: true,
            value: values.validatorId ? values.validatorId : '',
            options: validators.map(({ id, name }) => ({ label: name, value: id })),
            break: true,
            validation: {
                required: false
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'deputy_validator_id',
            id: 'task-deputy-validator',
            label: 'Deputy Validator',
            labelAsDefault: true,
            value: values.deputyValidatorId ? values.deputyValidatorId : '',
            options: validators.map(({ id, name }) => ({ label: name, value: id })),
            validation: {
                required: false
            },
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'proj_compute_hours',
            id: 'task-projected-hours',
            label: 'Projected compute hours',
            value: values.projectedComputeHours,
            break: true,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'sold_compute_hours',
            id: 'task-sold-hours',
            label: 'Sold compute hours',
            value: values.soldComputeHours,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'proj_compute_rate',
            id: 'task-projected-compute-rate',
            label: 'Projected compute rate',
            value: values.projectedComputeRate,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'sold_compute_rate',
            id: 'task-sold-compute-rate',
            label: 'Sold compute rate',
            value: values.soldComputeRate,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: SELECT,
            name: 'position_id',
            id: 'task-position',
            label: 'Position',
            labelAsDefault: true,
            value: values.positionId,
            options: positions.map(({ id, description }) => ({ label: description, value: id })),
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'proj_geo_months',
            id: 'task-projected-geo-months',
            label: 'Projected geo months',
            value: values.projectedGeoMonths,
            break: true,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'sold_geo_months',
            id: 'task-sold-geo-months',
            label: 'Sold geo months',
            value: values.soldGeoMonths,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'proj_geo_rate',
            id: 'task-projected-geo-rate',
            label: 'Projected geo rate',
            value: values.projectedGeoRate,
            break: true,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: NUMBER,
            name: 'sold_geo_rate',
            id: 'task-sold-geo-rate',
            label: 'Sold geo rate',
            value: values.soldGeoRate,
            dataType: FormFieldDataTypes.NUMBER
        },
        {
            type: CHECKBOX,
            name: 'include_in_projections',
            id: 'task-in-projects',
            label: 'Include in projections',
            checked: isUndefined(values.includeInProjections) ? true : values.includeInProjections,
            dataType: FormFieldDataTypes.BOOL
        },
        {
            type: SUBMIT,
            value: values.isClone ? 'Clone task' :
                (values.name  ? 'Save task' : 'Create task')
        }
    ];

    const computeResourceField = fields.find(field => field.name === 'compute_resource_id');
    const [ computeResource, setComputeResource ] = React.useState(computeResourceField.value);
    const validatorField = fields.find(field => field.name === 'validator_id');
    const deputyValidatorField = fields.find(field => field.name === 'deputy_validator_id');

    const handleResourceChange = value => {
        setComputeResource(value);
    };

    const needsValidators = computeResource ? computeResourceField.rawLookups
        .find(o => o.id === parseInt(computeResource, 10)).requires_task_validation : false;
    validatorField.type = needsValidators ? SELECT : HIDDEN;
    deputyValidatorField.type = needsValidators ? SELECT : HIDDEN;
    validatorField.validation.required = deputyValidatorField.validation.required = needsValidators;
    validatorField.validation.validate = field =>
        needsValidators && field.getValue() === parseInt(deputyValidatorField.value) && 'Validator and Deputy must not be the same person';
    deputyValidatorField.validation.validate = field =>
        needsValidators && field.getValue() === parseInt(validatorField.value) && 'Validator and Deputy must not be the same person';

    computeResourceField.onChange = e => {
        const formField = e.currentTarget;
        const selectedValue = formField.options[formField.selectedIndex].value;
        handleResourceChange(selectedValue);
    };
    validatorField.onChange = e => {
        const formField = e.currentTarget;
        validatorField.value = formField.options[formField.selectedIndex].value;
    };
    deputyValidatorField.onChange = e => {
        const formField = e.currentTarget;
        deputyValidatorField.value = formField.options[formField.selectedIndex].value;
    };

    return (
        <Form fields={fields} onSubmit={onSubmit} errors={errors} message={message}/>
    );
};

FormTask.propTypes = {
    values: PropTypes.object,
    projectId: PropTypes.number,
    milestoneId: PropTypes.number
};

export default FormTask;
