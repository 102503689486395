
import {
    CLOSE_MODAL,
    OPEN_MODAL,
    SET,
    SET_FILTERS,
    SET_ROUTES
} from './routeActions.js';
import serialize from '../../helpers/serialize';

const strToQueryParams = str => {
    const queryParams = {};
    if (str.indexOf('?') > -1) {
        let query = str.split('?').pop();
        query = query.split('&');
        query.forEach(q => {
            q = q.split('=');
            queryParams[q[0]] = q[1] || null;
        });
    }
    return queryParams;
};

const queryParamsToStr = params => {
    let str = '';
    let keys = Object.keys(params);
    if (keys.length) str = '?';
    keys = keys.map(k => `${k}=${params[k]}`);
    return str+keys.join('&');
};

const getRouteMatch = (currentRouteParts, routeParts) => {
    let match = (!routeParts.length && !currentRouteParts.length) || routeParts.length;


    const params = {};

    if (match) {

        currentRouteParts = currentRouteParts.concat();
        if (currentRouteParts.length) {
            currentRouteParts[currentRouteParts.length-1] = currentRouteParts[currentRouteParts.length-1].split('?').shift();
        }

        for (let i = 0, iLength = currentRouteParts.length; i<iLength; i++) {
            if (/^:/.test(routeParts[i])) {
                match = !!currentRouteParts[i];
                params[routeParts[i].replace(/^:/,'')] = currentRouteParts[i];
            } else if(/^\?/.test(routeParts[i])) {
                match = true;
                params[routeParts[i].replace(/^\?/,'')] = currentRouteParts[i];
            } else {
                match = routeParts[i] === currentRouteParts[i];
            }
        }

    }

    return {
        match,
        params
    };
};

const findQueryParams = str =>
    str.split('&')
        .map(paramPair => paramPair.split('='))
        .reduce(
            (acc, param) =>
                ({...acc, [param[0]]: param[1] })
            , {});

const findRouteData = (routeString, routes) => {
    const parts = routeString.split('?');
    const currentRouteParts = parts[0].split('/').filter(v => v);
    const queryParams = parts[1] ? findQueryParams(parts[1]): {};
    for (let i = 0, iLength = routes.length; i<iLength; i++) {
        const { match, params} = getRouteMatch(currentRouteParts, routes[i].split('/').filter(v => v));
        if(match)
            return {
                configRoute: routes[i],
                params,
                queryParams
            };
    }

    return {
        configRoute: null,
        params: {},
        queryParams
    };

};

const set = (action, state) => {
    action.route = action.route.replace(state.baseUrl, '');
    const data = findRouteData(action.route, state.routes);
    if (action.route === state.route) return state;
    if (!action.silent) history.pushState(null, null, action.route);
    return {
        ...state,
        route: action.route,
        extraParams: action.extraParams,
        modalPayload: action.modalPayload,
        ...data
    };
};

export default (state = {}, action = {}) => {

    let data;

    switch(action.type) {

        case CLOSE_MODAL: {
            const params = strToQueryParams(window.location.href);
            delete params.modal;
            if (state.extraParams) {
                action.extraParams = undefined;
                let key;
                for (key in state.extraParams) {
                    if (Object.prototype.hasOwnProperty.call(state.extraParams, key)) delete params[key];
                }
            }
            action.route = `${window.location.origin}${window.location.pathname}${queryParamsToStr(params)}`;
            return set(action, state);
        }

        case OPEN_MODAL: {
            let params = strToQueryParams(window.location.href);
            params.modal = action.modal;
            if (action.extraParams) params = { ...params, ...action.extraParams };
            action.route = `${window.location.origin}${window.location.pathname}${queryParamsToStr(params)}`;
            return set(action, state);
        }

        case SET:
            return set(action, state);

        case SET_FILTERS: {
            let params = strToQueryParams(window.location.href);
            const serialized = serialize(action.filters);
            params.filters = serialized ? encodeURIComponent(serialized) : '';
            action.route = `${window.location.origin}${window.location.pathname}${queryParamsToStr(params)}`;
            return set(action, state);
        }
        case SET_ROUTES:
            action.current = action.current.replace(action.baseUrl, '');
            data = findRouteData(action.current, action.routes);
            return {
                ...state,
                routes: action.routes,
                baseUrl: action.baseUrl,
                route: action.current,
                ...data
            };
        default:
            return state;
    }

};
