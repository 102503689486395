import '../../scss/components/_ellipsis.scss';
import React from 'react';
import PropTypes from 'prop-types';
import offset from '../helpers/offset';
import ReactDOM from 'react-dom';

const onClick = e => {
    e.stopPropagation();
};

const Ellipsis = ({text, max}) => {
    const [ isOpen, setIsOpen ] = React.useState(false);
    const trigger = React.useRef(null);
    const timeout = React.useRef(null);

    React.useEffect(() => () => clearTimeout(timeout.current));

    const onMouseOver = () => {
        clearTimeout(timeout.current);
        setIsOpen(true);
    };

    const onMouseOut = () => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout( () => setIsOpen(false), 1500 );
    };

    const getTooltipStyle = () => {
        const {
            left,
            top
        } = offset(trigger.current);

        return {
            left: Math.max( 0, Math.min( window.innerWidth, left ) ),
            top: top+trigger.current.offsetHeight
        };
    };

    const renderTooltip = () => isOpen ? ReactDOM.createPortal(
        <p className="ellipsis-tooltip" style={getTooltipStyle()} onClick={onClick} onMouseOver={onMouseOver}>
            { text }
        </p>,
        document.getElementsByTagName('body')[0]
    ) : null;

    return text.length > max
        ? <span className="ellipsis" ref={trigger} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            { text.slice(0, max).trim() }...
            {renderTooltip()}
        </span>
        : text;
};

Ellipsis.propTypes = {
    text: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired
};


export default Ellipsis;
