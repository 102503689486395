import React from 'react';

type ButtonProps = {
    buttonText: string;
    onClick: () => void;
};

const Button = function({ buttonText, onClick }: ButtonProps) {
    return (
        <button
            onClick={onClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
            {buttonText}
        </button>
    );
};

export default Button;
