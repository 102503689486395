import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { linkAcquisitionToProject, unlinkAcquisitionFromProject } from '../../redux/acquisitions/acquisitionsActions';
import {storeLinkedAcquisition, storeUnlinkedAcquisition} from '../../redux/projects/projectsActions';

const acquisitionSearchFilter = searchTerm => acquisition =>
    acquisition.acquisition_project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    acquisition.pgs_acquisition_code && acquisition.pgs_acquisition_code.toLowerCase().includes(searchTerm.toLowerCase());


const FormProjectAcquisitionChooser = ({ data, project }) => {
    const dispatch = useDispatch();

    const [ acquisitions, setAcquisitions ] = useState([]);
    const [ selectedAcquisitions, setSelectedAcquisitions ] = useState(project.acquisitions ? project.acquisitions : []);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ selectedSearchTerm, setSelectedSearchTerm ] = useState('');

    useEffect(() => {
        const filteredAcquisitions = data.acquisitions?.filter(acquisition =>
            !selectedAcquisitions.some(selected => selected.id === acquisition.id)
        ) || [];
        setAcquisitions(filteredAcquisitions);
    }, [ data.acquisitions, selectedAcquisitions ]);

    const save = id => dispatch(linkAcquisitionToProject({ projectId: project.id, acquisitionId: id }, d => {
        const acquisition = acquisitions.find(acquisition => acquisition.id === id);
        d(storeLinkedAcquisition(project.id, acquisition));
    }));

    const remove = id => dispatch(unlinkAcquisitionFromProject({ projectId: project.id, acquisitionId: id }, d => {
        d(storeUnlinkedAcquisition(project.id, id));
    }));

    const addAcquisition = id => {
        save(id);
        const newSelected = acquisitions.find(acquisition => acquisition.id === id);
        setSelectedAcquisitions(current => [ ...current, newSelected ]);
        setAcquisitions(current => current.filter(acquisition => acquisition.id !== id));
    };

    const removeAcquisition = id => {
        remove(id);
        const newAcquisition = selectedAcquisitions.find(acquisition => acquisition.id === id);
        setAcquisitions(current => [ ...current, newAcquisition ]);
        setSelectedAcquisitions(current => current.filter(acquisition => acquisition.id !== id));
    };

    const handleSearchChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSelectedSearchChange = event => {
        setSelectedSearchTerm(event.target.value);
    };

    return (
        <div className="form-project-acquisition-chooser">
            <div className="lists-container">
                <div className="list">
                    <h3>Available Surveys</h3>
                    <div className="controls">
                        <input
                            className="search-field"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search surveys"
                        />
                        <button className="clear" onClick={() => setSearchTerm('')}>clear</button>
                    </div>
                    <ul>
                        {acquisitions.filter(acquisitionSearchFilter(searchTerm)).map(acquisition => (
                            <li key={acquisition.id}>
                                <div className="acquisition-selection-box">
                                    <div className="acquisition-selection-box-text">
                                        <span className="acquisition-name">{acquisition.acquisition_project_name}</span>
                                        <span className="acquisition-name">{acquisition.start_date || 'No date'} -- {acquisition.end_date || 'No date' }</span>
                                        <span className="acquisition-name">{acquisition.pgs_acquisition_code || 'NON-PGS'}</span>
                                    </div>
                                    <button className="add-button" onClick={() => addAcquisition(acquisition.id)}>Add</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="list">
                    <h3>Selected Surveys</h3>
                    <div className="controls">
                        <input
                            className="search-field"
                            value={selectedSearchTerm}
                            onChange={handleSelectedSearchChange}
                            placeholder="Search selected"
                        />
                        <button className="clear" onClick={() => setSelectedSearchTerm('')}>clear</button>
                    </div>
                    <ul>
                        {selectedAcquisitions.filter(acquisitionSearchFilter(selectedSearchTerm)).map(selectedAcquisition => (
                            <li key={selectedAcquisition.id}>
                                <div className="acquisition-selection-box">
                                    <div className="acquisition-selection-box-text">
                                        <span
                                            className="acquisition-name">{selectedAcquisition.acquisition_project_name}</span>
                                        <span
                                            className="acquisition-name">{selectedAcquisition.start_date || 'No date'} -- {selectedAcquisition.end_date || 'No date'}</span>
                                        <span
                                            className="acquisition-name">{selectedAcquisition.pgs_acquisition_code || 'NON-PGS'}</span>
                                    </div>
                                    <button className="remove-button"
                                        onClick={() => removeAcquisition(selectedAcquisition.id)}>Remove
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

FormProjectAcquisitionChooser.propTypes = {
    data: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired
};

export default FormProjectAcquisitionChooser;
