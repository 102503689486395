'use client';

import React, { ReactNode } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);

type MsalProps = {
    children: ReactNode;
};

const Msal = function({ children }: MsalProps) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default Msal;
